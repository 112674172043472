<template>
  <div ref="inboxRef">
    <el-table :data="tableData" style="width: 100%"
              :height="tableHeight"
              :cell-style="{ textAlign: 'center' }"
              v-loading="loading"
              :header-cell-style="{ 'text-align': 'center',background: '#f9f9f9', color: 'black',padding:'16px 0px'}">
      <el-table-column :label="$t('common.customer.typeList')" :width="400">
        <template #default="{ row, column, $index }">
          <div :ref="ref => (refMap[`${row.id}_type_day`] = ref)"
               @mouseover.stop="handleRef(refMap[`${row.id}_type_day`], row, 1)"
               v-if="getTextWidth(row.customerType)>=390"
               class="over_normal"
               :key="row.customerType">
            {{ row.customerType }}
          </div>
          <div v-else>
            {{ row.customerType }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common_customer_level')">
        <template #default="{ row, column, $index }">
          <div>
            {{ row.customerLevel }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.followup.indays')">
        <template #default="{ row, column, $index }">
          <div v-if="!row.editing">
            {{ row.alertDay }}
          </div>
          <div v-else>
            <el-input v-model.number="row.alertDay" type="number" :min="0" oninput="if(value<0)value=0"></el-input>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.cz')">
        <template #default="{ row, column, $index }">
          <div v-if="!row.editing">
            <div class="settings">
              <el-button
                  type="primary"
                  link
                  @click="handleEdit(row, column, $index)"
              >{{ $t('common.setting') }}
              </el-button
              >
            </div>
          </div>
          <div v-else>
            <div class="settings">
              <el-button
                  type="primary"
                  link
                  @click="handleBlur(row, column, $index)"
              >{{ $t('common.save') }}
              </el-button
              >
              <el-button
                  type="danger"
                  link
                  @click="handleCancel(row, column, $index)"
              >{{ $t('common.sss16') }}
              </el-button
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
      </template>
    </el-table>
  </div>
  <!--  el-popover-->
  <el-popover
      v-model:visible="visiblePopover"
      :virtual-ref="tempRef"
      virtual-triggering
      :width="'max-content'"
      trigger="hover"
      :show-after="600"
      effect="dark"
  >
    <template v-if="popoverType === 1">
      {{ activeItemElpopover.customerType }}
    </template>
  </el-popover>
</template>

<script setup>
import {ref, onMounted, computed} from "vue";
import {useStore} from "vuex";
import {ElMessage, ElTable} from "element-plus";
import {emptyUrl} from "@/utils/statusParams";
import sessionStorageUtil from "@/utils/sessionStorageUtil";
import {debouncePlus, throttle} from "@/utils/mylodash";
import {getTextWidth} from "@/utils/util";

const store = useStore();
const tableData = computed(() => store.state.cusList.tableData.filter(item => item.customerType !== "" && item.customerLevel !== ""));
const loading = ref(false)
const originalAlertDay = ref('')
const isEditing = ref(false)
const handleEdit = async (row, column, index) => {
  if (!isEditing.value) {
    isEditing.value = true
    originalAlertDay.value = row.alertDay;
    store.commit("cusList/changeEditing", {index, flag: true});
  } else {
    ElMessage.warning("您当前正处于编辑中，请保存后再进行编辑！")
  }
};
//保存
const handleBlur = async (row, column, index) => {
  isEditing.value = false
  await store.dispatch("cusList/editCustomerData", {
    ...row,
    alertDay: row.alertDay,
  });
  store.commit("cusList/changeEditing", {index, flag: false});
  await store.dispatch("cusList/getMailCustomerLevel", {isUpdate: true});
};

const tableHeight = ref("40vh");

const inboxRef = ref(null);

onMounted(async () => {
  loading.value = true
  await store.dispatch("cusList/getMailCustomerLevel");
  loading.value = false
});
// 取消
const handleCancel = async (row, column, index) => {
  console.log(row, 'row')
  isEditing.value = false;
  tableData.value[index].editing = false;
  row.alertDay = originalAlertDay.value
};
const handleDelete = (row, column, index) => {
  tableData.value.splice(index, 1);
};


// el-popover 优化
const refMap = ref({})
const tempRef = ref()
const visiblePopover = ref(false)
// -1-字句审核 0-整句审核 1-日志查看
const popoverType = ref(0)
const activeItemElpopover = ref(null)

const handleRef = throttle((ref, item, type) => {
  console.log('refMap', refMap.value)
  tempRef.value = ref
  popoverType.value = type
  activeItemElpopover.value = item;
  if (type === 1) {
    // ...业务逻辑1
  } else {
    // ...业务逻辑2、3
  }
  visiblePopover.value = true
}, 400)
</script>
<style scoped lang="less">
.settings {
  display: flex;
  justify-content: center;
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-empty__description) {
  margin-top: -20px !important;
}
</style>
