<template>
  <div class="box">
    <div class="top_box">
      <div class="top_left">
        <div class="top_title"><span class="font">{{ $t('sending.customer.search.no') }}：{{
            staticTaskMessage?.taskCode
          }}</span></div>
        <div class="content_box">
          <div class="content_tops">
            <span
                class="font_small font_small_color">{{ $t('sending.table.col.massMailType') }}：{{
                staticTaskMessage?.massMailType === 1 ? $t('sending.table.col.massMailType.way1') : $t('sending.table.col.massMailType.way2')
              }}</span>
            <span class="font_small font_small_color" id="status">
              <div style="display: inline-flex;
            align-items: center;
            justify-content: center;">
           {{ $t('sending.customer.search.taskStatus') }}：
              <el-button class="font_small btn_reset" type="info" link
                         v-if="staticTaskMessage?.taskStatus===1">
                {{ $t('sending.search.status1') }}
              </el-button>
              <el-button v-else-if="staticTaskMessage?.taskStatus===2" class="font_small btn_reset" type="primary" link>
                {{
                  $t('sending.search.status2')
                }}</el-button>
                <el-button v-else-if="staticTaskMessage?.taskStatus===3" class="font_small btn_reset" type="warning"
                           link>{{ $t('sending.search.status3') }}</el-button>
              <el-button v-else-if="staticTaskMessage?.taskStatus===4" class="font_small btn_reset" type="success" link>{{
                  $t('sending.search.status4')
                }}</el-button>

              <el-button v-else-if="staticTaskMessage?.taskStatus===5" class="font_small btn_reset" type="danger" link>{{
                  $t('sending.search.status5')
                }}</el-button>
              </div>
            </span>
          </div>
          <div class="content_bottom" style="margin-top: 8px">
            <div>
              <span class="font_small font_small_color">{{ $t('sending.table.col.createTime') }}：{{
                  staticTaskMessage?.createTime
                }}</span>
            </div>
            <div>
              <span class="font_small font_small_color">{{ $t('sending.table.col.startTime') }}：{{
                  staticTaskMessage?.startTime
                }}</span>
            </div>
            <div>
              <span class="font_small font_small_color">{{ $t('sending.table.col.createName') }}：{{
                  staticTaskMessage?.createName
                }}</span>
            </div>
            <div></div>
          </div>
          <div style="margin-top: 8px">
                          <span class="font_small font_small_color"
                                style="margin-top: 6px">{{ $t('common.sent.email') }}：{{
                              staticTaskMessage?.from
                            }}</span>
          </div>
        </div>
      </div>
      <div class="top_right">
        <div>
          <div class="font_small bottom_margin">{{ $t('sending.table.col.massMailCount.num') }}</div>
          <div style="color: #292929;" class="font_big">
            {{ staticTaskMessage?.massMailCount }}/{{ staticTaskMessage?.massMailCount }}
          </div>
        </div>
        <div>
          <div class="font_small bottom_margin">{{ $t('sending.table.col.successCount') }}</div>
          <div style="color: #439a27" class="font_big">
            <el-statistic value-style="color:#369702;font-weight:500" :value="sendSuccessValue"/>
          </div>
        </div>
        <div>
          <div class="font_small bottom_margin">{{ $t('sending.table.col.openCount') }}</div>
          <div style="color: #ee8232" class="font_big">
            <el-statistic value-style="color:#ee8232;font-weight:500" :value="openEmailValue"></el-statistic>
          </div>
        </div>
        <div>
          <div class="font_small bottom_margin">{{ $t('sending.table.col.replyCount') }}</div>
          <div style="color: #448ef7" class="font_big">
            <el-statistic value-style="color:#448ef7;font-weight:500" :value="reEmailValue"></el-statistic>
          </div>
        </div>
        <div>
          <div class="font_small bottom_margin">{{ $t('sending.table.col.failCount') }}</div>
          <div class="font_big">
            <el-statistic value-style="color:#c5210d;font-weight:500" :value="sendErrorValue"/>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div>
        <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            label-width="auto"
            class="demo-ruleForm"
            status-icon
        >
          <div>
            <el-form-item :label="$t('common.get.email')" prop="toEmail">
              <el-input v-model="ruleForm.toEmail"/>
            </el-form-item>
            <el-form-item :label="$t('common.send.state')" prop="sendStatus" style="width: 220px">
              <el-select v-model="ruleForm.sendStatus" :placeholder="$t('common.please.select')">
                <el-option :label="$t('common.send.failed')" style="color: #e47470" :value="1"/>
                <el-option :label="$t('common.email.list.sendSuccess')" style="color: #7ec050" :value="2"/>
                <el-option :label="$t('common.wait.sent')" style="color: #deaa59" :value="3"/>
                <el-option :label="$t('sending.search.status5')" style="color: #e03e2d" :value="4"/>
              </el-select>
            </el-form-item>
            <el-form-item v-if="type===1" :label="$t('common.isreading')" prop="isTrack" style="width: 200px">
              <el-select v-model="ruleForm.isTrack" :placeholder="$t('common.please.select')">
                <el-option :label="$t('common.read')" :value="1"/>
                <el-option :label="$t('common.unread')" :value="0"/>
              </el-select>
            </el-form-item>
            <el-form-item v-if="type===2" :label="$t('common.isTrack')" prop="isTrack" style="width: 200px">
              <el-select v-model="ruleForm.isTrack" :placeholder="$t('common.please.select')">
                <el-option :label="$t('common.yes')" :value="1"/>
                <el-option :label="$t('common.no')" :value="0"/>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('common.email.list.isAnswer')" prop="isAnswer"
                          style="width: 200px">
              <el-select v-model="ruleForm.isAnswer" :placeholder="$t('common.please.select')">
                <el-option :label="$t('common.email.status1')" :value="1"/>
                <el-option :label="$t('common.email.status2')" :value="0"/>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item>
              <div style="display: flex">
                <el-button @click="resetForms(ruleFormRef)" style="margin-right: 10px">{{ $t('common.reset') }}
                </el-button>
                <el-button style="margin-right: 12px" type="primary" @click="onSubmit(ruleFormRef)">
                  {{ $t('common.query') }}
                </el-button>
                <el-button type="primary" @click="groupSend">
                  {{ $t('index.table.group.send') }}
                </el-button>
              </div>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div style="display: flex;flex-direction: column;height: max-content;">
        <div style="height: max-content">
          <ElTable v-loading="loading"
                   :data="tableData"
                   @selection-change="handleSelectionChange"
                   @select="handleCheck"
                   size="small"
                   @row-dblclick="handleViewDetail"
                   ref="tableRef"
                   :row-style="{height: '30px'}"
                   :header-cell-style="headerClassName"
                   :cell-style="rowClassName"
                   @sort-change="sort_change"
                   :row-key="row => row.mailId"
                   :header-cell-class-name="cellClass"
          >
            <el-table-column type="selection" :reserve-selection="true" fixed="left"/>
            <el-table-column width="35" prop="isStress" sortable
                             class-name="sort_ps"
            >
              <template #header>
                <img style="padding-left: 10px;padding-top: 6px" src="../../../assets/email/hp.svg"/>
              </template>
              <template #default="scope">
                <img v-if="scope.row.isStress === 1" @click.stop="() => handleSettingStress(scope, 3)"
                     class="pointer flags_f"
                     src="../../../assets/email/hp.svg"/>
                <img v-else class="pointer flags_f" @click.stop="() => handleSettingStress(scope, 2)"
                     src="../../../assets/email/hp1.svg"/>
              </template>
            </el-table-column>
            <el-table-column width="35" prop="isRead" sortable class-name="sort_ps">
              <template #header>
                <img style="padding-left: 10px" src="../../../assets/email/weidu.svg"/>
              </template>
              <template #default="scope">
                <img style="position: absolute;left: 2px;height: 14px;top:7px" v-show="scope.row.urgent === 1"
                     class="pointer"
                     src="../../../assets/svg/warning.svg">
                <img style="position: absolute;left: 19px;height: 14px;top:8px" v-if="scope.row.isRead === 1"
                     @click.stop="() => handleSetRead(scope)" class="pointer"
                     src="../../../assets/email/yidu.svg"/>
                <img style="position: absolute;left: 18px;top:9px" v-else class="pointer"
                     @click.stop="() => handleSetRead(scope)"
                     src="../../../assets/email/weidu.svg"/>
              </template>
            </el-table-column>
            <el-table-column width="40" prop="fj" sortable class-name="sort_ps">
              <template #header>
                <img style="position: absolute;left: 18px;top:10px" src="../../../assets/email/fj.svg"/>
              </template>
              <template #default="scope">
                <img style="position: absolute;left: 18px;top:9px" v-if="scope.row.isExtend === 1" class="pointer"
                     src="../../../assets/email/fj.svg"
                     @click.stop="() => handleDownload(scope)"/>
              </template>
            </el-table-column>
            <el-table-column prop="toEmail" :label="$t('common.email.to')"
                             :width="flexWidth('toEmail', tableData, $t('common.email.to'))"></el-table-column>
            <el-table-column prop="subject"
                             :label="$t('common.subject')" show-overflow-tooltip>
              <template #default="scope">
                <a @click.stop="handleViewDetail(scope.row)">{{ scope.row.subject }}</a>
              </template>
            </el-table-column>
            <el-table-column prop="sendTime" sortable :label="$t('common.date')" width="160"/>
            <el-table-column prop="isTrack" :label="$t('common.isreading')"
                             :width="flexWidth('isTrack', tableData, $t('common.isreading'))-30">
              <template #default="{row}">
                <template v-if="row.isTrack===1">
                  <el-popover
                      placement="bottom"
                      :width="300"
                      trigger="hover"
                      effect="dark"
                      :show-after="500"
                  >
                    <el-tooltip placement="top" style="pointer-events: none">
                      <div slot="content" style="pointer-events: none">
                        {{
                          $t("common.email.list.isTrack", {
                            openTime: row.openTime,
                            openAddress: row.openAddress,
                            openIp: row.openIp,
                            openCount: row.openCount,
                          })
                        }}
                      </div>
                    </el-tooltip>
                    <template #reference>
                      <img style="cursor: pointer" src="@/assets/img/email/quanbuyidu.png" height="15"/>
                    </template>
                  </el-popover>
                </template>
                <template v-else-if="row.isTrack===0"></template>
              </template>
            </el-table-column>
            <el-table-column prop="groupId" :label="$t('common.send.state')"
                             :width="flexWidth('groupId', tableData, $t('common.send.state'))">
              <template #default="{row}">
                <el-button link type="danger" style="font-size: 12px !important;" class="btn_reset"
                           v-if="row.groupId==='8'">{{ $t('common.send.failed') }}
                </el-button>
                <template v-else-if="row.groupId==='2'">
                  <template v-if="row.sendTime !== null">
                    <el-button class="btn_reset" v-if="new Date(row.sendTime).getTime() > new Date().getTime()" link
                               type="warning" style="font-size: 12px !important;">{{ $t('common.wait.sent') }}
                    </el-button>
                    <template v-else>
                      <el-button class="btn_reset" link type="default" style="font-size: 12px !important;">
                        {{ $t('common.email.list.draft') }}
                      </el-button>
                    </template>
                  </template>
                  <template v-else>
                    <el-button class="btn_reset" link type="default" style="font-size: 12px !important;">
                      {{ $t('common.email.list.draft') }}
                    </el-button>
                  </template>
                </template>
                <template v-else-if="row.groupId==='7'">
                  <el-button class="btn_reset" link type="success" style="font-size: 12px !important;">
                    {{ $t('common.email.list.sendSuccess') }}
                  </el-button>
                </template>
                <template v-else-if="row.groupId==='5'">
                  <el-button class="btn_reset" link type="warning" style="font-size: 12px !important;">
                    {{ $t('common.wait.sent') }}
                  </el-button>
                </template>
                <template v-else-if="row.groupId==='6'">
                  <el-button class="btn_reset" link type="danger" style="font-size: 12px !important;">
                    {{ $t('sending.search.status5') }}
                  </el-button>
                </template>
                <template v-else>
                  {{ $t('index.table.status.unknown') }}
                </template>
              </template>
            </el-table-column>
            <!--            <el-table-column prop="contentSize" sortable :label="$t('common.size')" width="80">-->
            <!--              <template #default="{row}">-->
            <!--                {{ formatSize(row.contentSize) }}-->
            <!--              </template>-->
            <!--            </el-table-column>-->
            <el-table-column prop="isTrack" :label="$t('common.email.isOpen')"
                             :width="flexWidth('isTrack', tableData, $t('common.email.isOpen'))-30">
              <template #default="{row}">
                <template v-if="row.groupId!=='-1'">
                  <template v-if="row.isTrack===0">
                    <el-icon class="btn_reset" style="color: #e47470">
                      <CloseBold/>
                    </el-icon>
                  </template>
                  <template v-else-if="row.isTrack===1">
                    <el-icon class="btn_reset" style="color: #7ec050"><Select/></el-icon>
                  </template>
                </template>
                <template v-else></template>
              </template>
            </el-table-column>
            <el-table-column prop="isTrack" :label="$t('common.email.isReplay')"
                             :width="flexWidth('isTrack', tableData, $t('common.email.isReplay'))-30">
              <template #default="{row}">
                <template v-if="row.groupId!=='-1'">
                  <template v-if="row.isAnswer ===1">
                    <el-icon class="btn_reset" style="color: #7ec050"><Select/></el-icon>
                  </template>
                  <template v-else-if="row.isAnswer===0">
                    <el-icon class="btn_reset" style="color: #e47470">
                      <CloseBold/>
                    </el-icon>
                  </template>
                </template>
                <template v-else></template>
              </template>
            </el-table-column>
            <el-table-column fixed="right" :label="$t('common.cz')" width="80">
              <template #default="scope">
                <el-button type="primary" link size="small" @click.stop="handleResend(scope.row)">
                  {{ $t('common.resend') }}
                </el-button>
              </template>
            </el-table-column>
            <template #empty>
              <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
            </template>
          </ElTable>
        </div>
        <div style="margin: 10px 0 20px 0;display: flex;width: 100%;justify-content: space-between">
          <div id="checkoutAll" ref="checkoutAll">
            <el-checkbox v-model="isSelectAll" :label="$t('tree.select.all')" size="small" @change="selectAll">
              {{ $t('common.customer.checkout.all', {count: isSelectAll ? totals : 0}) }}
            </el-checkbox>
          </div>
          <el-pagination
              v-model:current-page="pageNo"
              v-model:page-size="pageSize"
              small="small"
              v-if="totals"
              layout="total, prev, pager, next, jumper"
              :total="totals"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, ref, watchEffect} from "vue";
import {useTransition} from '@vueuse/core'
import {emptyUrl} from "@/utils/statusParams";
import {formatSize} from "@/utils/file";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {tagMail} from "@/api";
import {ElMessage, ElTable} from "element-plus";
import {debouncePlus} from "@/utils/mylodash"
import {requestMailAccessory} from "@/api/mail";
import {flexWidth} from "@/utils/tableWidth";
import {i18n} from "@/lang";


const route = useRoute()
const router = useRouter()
const store = useStore()

const sortObj = ref({})

function sort_change({column, prop, order}) {
  console.log('ffff', column, prop, order)
  const flag = order === 'descending' ? 2 : 1;
  // 映射排序字段到相应的请求参数
  const sortMapping = {
    isStress: 'orderStress',
    isRead: 'orderRead',
    fj: 'orderExtend',
    sendTime: 'orderTime',
    contentSize: 'orderSize'
  };

  // 获取相应的请求参数
  const requestParam = sortMapping[prop];
  sortObj.value = {[requestParam]: flag};
  if (requestParam) {
    getDetailGroupMailList({[requestParam]: flag});
  }
}

/**
 * type 1 普通群发  2 EDM 群发
 */
const {type} = defineProps({
      type: {
        type: Number,
        default: 1,
      }
    }
)
const TIME_DELAY = 300;
const loading = ref(false)
const tableData = ref([])
const tableRef = ref(null)

const ruleFormRef = ref(null)
const ruleForm = ref({toEmail: '', sendStatus: '', isTrack: '', isRead: '', isAnswer: ''})

const resetForms = debouncePlus(async (formEl) => {
  ruleFormRef.value.resetFields();
  await getDetailGroupMailList()
}, TIME_DELAY)

const onSubmit = debouncePlus(async () => {
  await getDetailGroupMailList(ruleForm.value)
}, TIME_DELAY)

// 静态消息
const staticTaskMessage = computed(() => store.state.groupSend.staticTaskMessage);


const DURATION_TIME = 500;
/**
 * 失败数量
 * @type {Ref<UnwrapRef<number>>}
 */
const sendError = ref(0)
const sendErrorValue = useTransition(sendError, {
  duration: DURATION_TIME,
})

/**
 * 成功数量
 */
const sendSuccess = ref(0)
const sendSuccessValue = useTransition(sendSuccess, {
  duration: DURATION_TIME,
})


/**
 * 打开数量
 */
const openEmail = ref(0)
const openEmailValue = useTransition(openEmail, {
  duration: DURATION_TIME,
})


/**
 * 回复数量
 */

const reEmail = ref(0)
const reEmailValue = useTransition(reEmail, {
  duration: DURATION_TIME,
});


watchEffect(() => {
  const {successCount, openCount, replyCount, failCount} = staticTaskMessage?.value || {}
  sendError.value = failCount ? failCount : 0;
  sendSuccess.value = successCount ? successCount : 0;
  openEmail.value = openCount ? openCount : 0;
  reEmail.value = replyCount ? replyCount : 0
})


//获取任务详情list
const pageNo = ref(1);
const pageSize = ref(20);
const totals = ref(0);
const teamId = computed(() => store.state.email.teamID);
const userInfo = computed(() => store.state.user.userInfo);

const getDetailGroupMailList = async (form) => {
  let payload = {
    pageNo: pageNo.value,
    pageSize: pageSize.value,
    teamId: teamId.value,
    taskCode: route.params.taskId,
    userId: route.params.userId,
  }
  if (form) {
    payload = {...payload, ...form, ...ruleForm.value}
  }
  console.log("payload", payload)
  loading.value = true
  const res = await store.dispatch("groupSend/queryByTaskCodeApis", payload)
  console.log('resddd', res)
  loading.value = false;
  const {records, total, current, size} = res;
  tableData.value = records;
  pageNo.value = current
  pageSize.value = size;
  totals.value = total
}


async function handleCurrentChange(page) {
  pageNo.value = page;
  tableRef.value.setScrollTop(0);
  console.log(sortObj.value, "sortObj.value")
  await getDetailGroupMailList(sortObj.value)
  isSelectAll.value ? checkAllItem() : unCheckAllItem()
}


onMounted(async () => {
  // Shift监听/取消监听
  document.addEventListener('keydown', handleKeyDown);
  document.addEventListener('keyup', handleKeyUp);

  await getDetailGroupMailList();
  await store.dispatch("groupSend/statisticsMassTaskByTaskCodeApis", {
    taskCode: route.params.taskId,
    teamId: teamId.value
  })
})

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleKeyDown);
  document.removeEventListener('keyup', handleKeyUp);
});


//-----

// table中的方法
const multipleSelection = ref([]);
const commandList = [1, 0, 1, 0];

async function handleViewDetail(row) {
  console.log('双击', row.mailId);
  const TYPE = 3;
  //邮件已读
  multipleSelection.value = [row]
  await requestTagMail(commandList[0], 'isRead');
  window.open(`${location.origin}/email/detail/${row.mailId}/${JSON.stringify({
    flag: row.flag ? row.flag : 1,
    isExtend: row.isExtend ? row.isExtend : 0,
    from: row.from
  })}/${TYPE}`);
}

async function requestTagMail(tag, type) {
  const result = await tagMail({
    [type]: tag,
    mailIdList: multipleSelection.value.map(item => item.mailId),
  });
  // // console.log('标记邮件', result);
  if (result.code !== 200) {
    ElMessage.error(result.message);
    return;
  }
  // if (type !== "isRead") {
  //   ElMessage.success('修改成功');
  // }
  // 更新标志
  await store.dispatch('countshow/getTeamAllDataApis')
  await getDetailGroupMailList();
}


// 重发
function handleResend(row) {

  const {massTaskId, type,} = route.params
  localStorage.setItem('reSend', JSON.stringify(row))
  const query = {
    id: row.mailId,
    type: type,
    massTaskId: massTaskId,
    mailType: "3",
    typeFlag: "reSentSingle",
    emailArray: JSON.stringify([row.toEmail]),
    // 邮件来源
    from: row.from,
  };
  console.log(row, 'row', query)
  const url = router.resolve({
    path: '/email/send_all',
    query,
  }).href;
  window.open(url, "_blank")
}


async function handleSettingStress(scope, type) {
  // // console.log(scope.row, type);
  multipleSelection.value = [scope.row];
  handleCommand(type);
  // 更新标志
  await store.dispatch('countshow/getTeamAllDataApis')
}

async function handleCommand(val) {
  // // console.log(val, multipleSelection.value);
  if (!multipleSelection.value.length) {
    ElMessage.error(i18n.global.t('sys.tip.unselect.email'));
    return;
  }
  const type = val === 0 || val === 1 ? 'isRead' : 'isStress';
  await requestTagMail(commandList[val], type);
}


async function handleSetRead(scope) {
  // // console.log(scope);
  const isRead = scope.row.isRead;
  const result = await tagMail({
    isRead: isRead === 1 ? 0 : 1,
    mailIdList: [scope.row.mailId],
  });
  if (result.code !== 200) {
    ElMessage.error(result.message);
    return;
  }
  tableData.value[scope.$index].isRead = isRead === 1 ? 0 : 1;
  // 更新标志
  await store.dispatch('countshow/getTeamAllDataApis')
}


async function handleDownload(scope) {
  const result = await requestMailAccessory({id: scope.row.id});
  if (result.code !== 200) {
    ElMessage.error(result.message);
    return;
  }
  const TYPE = 1;
  console.log(result, 'result')
  window.open(`${location.origin}/email/detail/${scope.row.id}/${JSON.stringify({
    flag: scope.row.flag ? scope.row.flag : 0,
    isExtend: scope.row.isExtend ? scope.row.isExtend : 0,
    from: scope.row.from
  })}/${TYPE}`);
}


const rowClassName = ({row, column, rowIndex, columnIndex}) => {
  // // console.log(row, columnIndex)
  if (columnIndex === 3 || columnIndex === 4 || columnIndex === 5) {
    return {textAlign: 'left'}
  } else {
    return {textAlign: 'center'}
  }
}

// 表头
const headerClassName = ({row, column, rowIndex, columnIndex}) => {

  if (columnIndex === 3 || columnIndex === 4 || columnIndex === 5) {
    return {'text-align': 'left'}
  } else {
    return {'text-align': 'center'}
  }
}

const isSelectAll = ref(false)
const selectAll = (val) => {
  console.log(val, 'isSelectAll')
  val ? checkAllItem() : unCheckAllItem()
}
const checkAllItem = () => {
  console.log(tableRef.value, 'tableRef.value')
  tableRef.value.clearSelection()
  tableRef.value.toggleAllSelection()
}
const unCheckAllItem = () => {
  tableRef.value.clearSelection()
}

const selectedData = ref([])
const handleSelectionChange = (val) => {
  endIndex = val.length > 1 ? tableData.value.findIndex(item => item.mailId === val[val.length - 1].mailId) : -1;
  const startIndex = val.length > 1 ? tableData.value.findIndex(item => item.mailId === val[val.length - 2].mailId) : -1;
  console.log(endIndex, startIndex);
  if (isShiftPressed.value && val.length > 1 && endIndex !== startIndex) {
    const start = startIndex < endIndex ? startIndex : endIndex;
    const end = endIndex < startIndex ? startIndex : endIndex;
    // console.log(start, end, '===============');
    for (let i = start; i <= end; i++) {
      const row = tableData.value[i];
      if (start === i || i === end) {
        continue;
      }
      console.log(row, '更新');
      if (row) {
        tableRef.value.toggleRowSelection(row, true); // 调用表格组件的方法来更新选中状态
      }
    }
    nextTick(() => {
      selectedData.value = tableRef.value.getSelectionRows();
      console.log(tableRef.value.getSelectionRows(), '当前选中行');
    });
  } else {
    selectedData.value = val;
  }
}

const handleCheck = (value) => {
  if (isSelectAll.value) {
    isSelectAll.value = false;
  }
}

const groupSend = () => {
  const {massTaskId, type,} = route.params
  // const mapTarget = selectedData.value.
  // 跳转到详情
  router.push({
    path: `/email/send_all`,
    query: {
      mailType: "3",
      type: type,
      massTaskId: massTaskId,
      typeFlag: "reSent",
      emailArray: JSON.stringify(selectedData.value.map(item => item.toEmail)),
      isSelectAll: isSelectAll.value,
      ruleForm: JSON.stringify(removeEmpty(ruleForm.value)),
    }
  })
}

const cellClass = (row) => {
  if (row.columnIndex === 0) {
    return isSelectAll.value ? "disabledCheck" : "abledCheck"
  }
}

function removeEmpty(obj) {
  // 如果传入的不是对象，直接返回
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  // 对象过滤
  return Object.keys(obj)
      .filter(key => {
        const value = obj[key];
        // 过滤掉空值（null、undefined、空字符串）和空对象
        return value !== null && value !== undefined && value !== '' &&
            !(typeof value === 'object' && Object.keys(value).length === 0);
      })
      .reduce((acc, key) => {
        // 递归处理属性值为对象的情况
        acc[key] = typeof obj[key] === 'object' ? removeEmpty(obj[key]) : obj[key];
        return acc;
      }, {});
}


let endIndex = -1;
// table 选择增加 shift
const isShiftPressed = ref(false); // 标记 Shift 键是否被按下
const handleKeyDown = (event) => {
  if (event.key === 'Shift' && event.keyCode === 16) {
    // console.log("进来了-----------------", event.key, event.keyCode);
    isShiftPressed.value = true;
  }
};

const handleKeyUp = (event) => {
  if (event.key === 'Shift' && event.keyCode === 16) {
    isShiftPressed.value = false;
  }
};
</script>
<style lang="less" scoped>
@import "../../../views/Customer/less/detail.less";
@import "../../../views/components/EmailList/index";

.box {
  width: 100%;
  height: 100%;
  background: white;

  .top_box {
    height: max-content;
    min-height: 120px;
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    .top_left {
      width: 55%;
      min-width: 700px;
      height: 100%;
      flex: 0 0 auto; /* 不压缩，不增长 */

      .content_box {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .content_tops {
          & > #status {
            margin-left: 102px;
          }
        }

        .content_bottom {
          display: flex;
          justify-content: flex-start;

          & > div {
            margin-left: 30px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }

          & > div:nth-of-type(1) {
            margin-left: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }
        }
      }
    }

    .top_right {
      width: 45%;
      min-width: 300px;
      flex: 1 1 auto; /* 不压缩，不增长 */
      display: flex;
      justify-content: space-around;
      align-items: center;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 120px);
    background: #f6f6f6;
    padding: 16px;

    & > div {
      display: flex;
      padding: 0px 10px;
    }

    & > div:nth-of-type(1) {
      width: 100%;
      height: 50px;
      background: white;
    }

    & > div:nth-of-type(2) {
      width: 100%;
      margin-top: 16px;
      height: calc(100% - 50px);
      background: white;
    }
  }
}

.font {
  font-size: 16px;
  font-weight: 500;
}

.font_small {
  font-size: 14px;
  min-width: 48px;
}

.font_big {
  font-size: 30px;
  font-weight: 600;
}

.font_small_color {
  color: #707070;
}

span {
  line-height: 16px;
}

:deep(.el-button+.el-button) {
  margin-left: 0px;
}

.demo-ruleForm {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

:deep(.el-form-item) {
  margin-bottom: 0px !important;
}

.btn_reset {
  cursor: text !important;
  pointer-events: none
}

:deep(.el-statistic__number) {
  font-size: 30px;
  font-weight: 600;
}

.bottom_margin {
  margin-bottom: 16px;
}

:deep(.el-table-column--selection .cell) {
  width: 45px !important;
  display: flex;
  justify-content: center;
}

#checkoutAll {
  :deep(.el-checkbox__input) {
    width: 45px !important;
    display: flex;
    justify-content: center;
  }

  :deep(.el-checkbox__label) {
    padding-left: 0px !important;
  }
}

:deep(.disabledCheck .el-checkbox__input) {
  display: none !important;
  padding: 0px !important;
}

:deep(.abledCheck .el-checkbox__input) {
  display: block !important;
  padding: 0px !important;
}

:deep(.disabledCheck) {
  padding: 0px !important;
}

:deep(.abledCheck) {
  padding: 0px !important;
}

.sp_item {
  transition: all .3s;
}
</style>
