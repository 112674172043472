<template>
  <div class="wrap">
    <!--    {{ inboxHeight }}-->
    <!--    {{ inboxTop }}-->
    <!--    |-->
    <!--    {{ pageframeHeight }}-->
    <!--    {{ elementTop }}-->
    <!--    |-->
    <!--    {{ pageframeHeightRaw }}-->
    <!--    {{ elementTopRaw }}-->
    <!--    |-->
    <div class="inbox" ref="inboxRef">
      <div class="bg_w">
        <span class="span">{{ group ? $t('select.user') : $t('common.emailbox.select') }}：</span>
        <el-select v-model="selectedTeamId" @change="handleSelectUser" :placeholder="$t('common.email.chooseEmail')"
                   style="margin-right: 10px;">
          <el-option
              v-for="item in tableDataSelect"
              :label="item.userName"
              :value="item.userId"
              :key="item.userId"
          />
        </el-select>
        <el-select v-model="selectedFolder" @change="handleSelectFolder" v-if="group" style="margin-right: 10px;">
          <!--          <el-option :label="$t('common.setting.allEmail')" value="all" id="all"/>-->
          <el-option
              v-for="item in targetFileList.filter(item=>item.id!=='99999')"
              :label="item.fileName"
              :value="item.id"
              :key="item.id"
          />
        </el-select>
        <el-select v-model="configId">
          <el-option :label="$t('common.setting.allEmail')" value="all" id="all"/>
          <el-option
              v-for="item in emailList"
              :label="item.label"
              :value="item.value"
              :key="item.value"
          />
        </el-select>
        <div style="flex-grow: 1;"></div>
        <el-input
            v-model="searchCount"
            :placeholder="$t('common.search.p')"
            class="input-with-select"
            clearable
            @clear="handleSearchInput('clear')"
            @keydown.enter.native="handleSearchInput"
        >
          <template #append>
            <ElButton type="primary" @click="handleSearchInput('search')">
              {{ $t('common.search') }}
            </ElButton>
          </template>
        </el-input>
        <el-button style="margin-left: 10px;" @click="drawer = true">{{ $t('common.search.advanced') }}</el-button>
      </div>
      <div class="screen">
        <div class="screen_btn">
          <template v-if="type === 1 || type === 3">
            <ElButton size="small" @click="requestTagAllRead">{{ $t('common.read.all') }}</ElButton>
            <ElButton size="small" @click="() => handleCommand(0)">{{ $t('common.read.set') }}</ElButton>
            <ElButton size="small" @click="() => requestDelMail(1,false)">{{ $t('common.delete') }}</ElButton>
            <!-- <ElButton size="small" v-if="type === 1">垃圾邮件</ElButton> -->
          </template>
          <ElButton size="small" v-if="type === 2" @click="() => requestDelMail(1)">{{
              $t('common.draft.delete')
            }}
          </ElButton>
          <ElButton size="small" v-if="type === 1" @click="() => handleBlack()">{{ $t('common.block') }}</ElButton>
          <template v-if="type === 4">
            <ElButton size="small" @click="requestTagAllRead">{{ $t('common.read.all') }}</ElButton>
            <ElButton size="small" @click="() => requestDelMail(2,false)">{{ $t('common.delete.empty') }}</ElButton>
            <ElButton size="small" @click="() => handleBlack()">{{ $t('common.block') }}</ElButton>
            <ElButton size="small" @click="() => requestDelMail(0,false)">{{ $t('common.restore') }}</ElButton>
          </template>
          <ElDropdown size="small" @command="handleCommand">
            <el-button size="small">
              {{ $t('common.mark.to') }}...
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item :command="0">{{ $t('common.email.read') }}</el-dropdown-item>
                <el-dropdown-item :command="1">{{ $t('common.email.noread') }}</el-dropdown-item>
                <el-dropdown-item :command="2">{{ $t('common.email.starmark') }}</el-dropdown-item>
                <el-dropdown-item :command="3">{{ $t('common.cancel.mark') }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </ElDropdown>
          <ElDropdown size="small" @command="handleGroupEmail"
                      v-if="(type===1||type===3||type===2||type===4)&&targetFileList.length>0">
            <el-button size="small">
              {{ $t('common.move.to') }}...
            </el-button>
            <template #dropdown>
              <div @scroll="handleScroll" class="dropdown-menu-wrapper"
                   style="max-height: 300px;max-width: 180px; overflow-y: auto;">
                <el-dropdown-menu class="move-select">
                  <el-dropdown-item :command="item.id"
                                    v-for="item in targetFileList.filter(it => it.id !== route.params.folderId)"
                                    :key="item.id">
                    <template v-if="item.id==='99999'">
                      <span style="color: #5e9ff8">{{ item.fileName }}</span>
                    </template>
                    <template v-else>
                      {{ item.fileName }}
                    </template>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </div>
            </template>
          </ElDropdown>
          <el-button size="small" v-else>
            {{ $t('common.move.to') }}...
          </el-button>
        </div>
        <el-pagination
            v-model:current-page="current"
            v-model:page-size="size"
            small="small"
            v-if="total"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
        />
      </div>
      <!--      新的移动模块-->
      <div class="Drag2" ref="DragBox">
        <div class="box" ref="box">
          <!--      <div class="left">-->
          <!--        &lt;!&ndash;左侧div内容&ndash;&gt;-->
          <!--      </div>-->
          <!--      <div class="resize" title="左右侧边栏">⋮</div>-->
          <div class="mid">
            <!--右侧div内容-->

            <div class="topBox" :style="{height:inboxTop+'px'}">
              <!--右上div内容-->
              <ElTable v-loading="loading"
                       :data="tableData"
                       @selection-change="handleSelectionChange"
                       size="small"
                       highlight-current-row
                       @row-dblclick="handleViewDetail"
                       ref="tableRef"
                       id="sp_tables"
                       :style="{width:'100vw'}"
                       :height="'100%'"
                       @current-change="handleCurrentRowChange"
                       :row-class-name="tableRowClassName"
                       @sort-change="sort_change"
                       :cell-style="rowClassName"
                       :header-cell-style="headerClassName"
                       :row-style="{height: '30px'}"
                       @cell-mouse-enter="mouseEnters"
                       @cell-mouse-leave="mouseLeaves"
              >
                <el-table-column type="selection" width="30" fixed="left"/>
                <el-table-column width="35" prop="isStress" sortable
                                 class-name="sort_ps"
                >
                  <template #header>
                    <img style="padding-left: 10px;padding-top: 6px" src="../../../assets/email/hp.svg"/>
                  </template>
                  <template #default="scope">
                    <img v-if="scope.row.isStress === 1" @click.stop="() => handleSettingStress(scope, 3)"
                         class="pointer flags_f"
                         src="../../../assets/email/hp.svg"/>
                    <img v-else class="pointer flags_f" @click.stop="() => handleSettingStress(scope, 2)"
                         src="../../../assets/email/hp1.svg"/>
                  </template>
                </el-table-column>
                <el-table-column width="40" prop="isRead" sortable class-name="sort_ps">
                  <template #header>
                    <img style="padding-left: 10px" src="../../../assets/email/weidu.svg"/>
                  </template>
                  <template #default="scope">
                    <img style="position: absolute;left: 2px;height: 14px;top:7px" v-show="scope.row.urgent === 1"
                         class="pointer"
                         src="../../../assets/svg/warning.svg">
                    <img style="position: absolute;left: 19px;height: 14px;top:8px" v-if="scope.row.isRead === 1"
                         @click.stop="() => handleSetRead(scope)" class="pointer"
                         src="../../../assets/email/yidu.svg"/>
                    <img style="position: absolute;left: 18px;top:9px" v-else class="pointer"
                         @click.stop="() => handleSetRead(scope)"
                         src="../../../assets/email/weidu.svg"/>
                  </template>
                </el-table-column>
                <el-table-column width="40" prop="fj" sortable class-name="sort_ps">
                  <template #header>
                    <img style="position: absolute;left: 18px;top:10px" src="../../../assets/email/fj.svg"/>
                  </template>
                  <template #default="scope">
                    <img style="position: absolute;left: 18px;top:9px" v-if="scope.row.isExtend === 1" class="pointer"
                         src="../../../assets/email/fj.svg"
                         @click.stop="() => handleDownload(scope)"/>
                  </template>
                </el-table-column>
                <el-table-column width="35" prop="reply" sortable class-name="sort_ps" v-if="type===1||type===3">
                  <template #default="{row}">
                    <img v-if="row.isReply===1&&type===1" style="position: absolute;top:8px" height="14"
                         src="../../../assets/email/rp2.png">
                    <img v-else-if="row.isAnswer===1&&type===3" style="position: absolute;top:8px" height="14"
                         src="../../../assets/email/rp1.png">
                  </template>
                </el-table-column>
                <el-table-column prop="from"
                                 :label="type !== 1 && type!==4 ? $t('common.email.to') : $t('common.email.from')"
                                 show-overflow-tooltip
                                 width="350"
                >
                  <template #default="scope">
                    <template v-if="type === 1 || type === 4">
                      <el-popover
                          v-if="(scope.row.customerName != null || scope.row.contactEmail != null)&&scope.row.onclick"
                          :width="'max-content'"
                          effect="dark"
                          trigger="hover"
                          :show-after="500"
                          :content="scope.row.contactEmail!=null?scope.row.contactEmail:scope.row.customerName+'('+scope.row.companyEmail+')'"
                      >
                        <template #reference>
                          <router-link :to="'/email/customer/detail/' + scope.row.customerId + '?type=1'"
                                       v-if="scope.row.customerName!=null">
                              <span
                                  style="color: #0087ff;width: 100%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">{{
                                  scope.row.customerName
                                }}</span>
                          </router-link>
                          <span v-else>
                                {{
                              scope.row.flag == 1 ? scope.row.from : scope.row.customerName != null ? scope.row.customerName : scope.row.contactName != null ? scope.row.contactName : scope.row.from
                            }}
                            </span>
                        </template>
                      </el-popover>
                      <span v-else>
                        {{
                          scope.row.flag === 1 ? scope.row.from : scope.row.customerName != null ? scope.row.customerName : scope.row.contactName != null ? scope.row.contactName : scope.row.from
                        }}
                      </span>
                    </template>
                    <template v-else>
                      <el-popover
                          v-if="scope.row.customerName !== null && scope.row.onclick"
                          placement="right"
                          :width="'max-content'"
                          effect="dark"
                          trigger="hover"
                          :show-after="500"
                          :content="scope.row.contactEmail!=null?scope.row.contactEmail:scope.row.customerName+'('+scope.row.companyEmail+')'"
                      >
                        <template #reference>
                          <router-link :to="'/email/customer/detail/' + scope.row.customerId + '?type=1'"
                          >
                            <span style="color: #0087ff;"> {{ scope.row.customerName }}</span>
                          </router-link>
                        </template>
                      </el-popover>
                      <template v-else>
                        <template
                            v-if="scope.row.mailRecipientList&&scope.row.mailRecipientList.length>1?true:false">
                          <el-popover
                              placement="right"
                              :width="'max-content'"
                              effect="dark"
                              trigger="hover"
                              :show-after="500"
                              :content="scope.row.mailRecipientList.join(' , ')"
                          >
                            <template #reference>
                              <span style="overflow: hidden">{{ `${scope.row.mailRecipientList[0]} ...` }}</span>
                            </template>
                          </el-popover>
                        </template>
                        <template v-else>
                          {{
                            scope.row.customerName ? scope.row.customerName : scope.row.mailRecipientList ? scope.row.mailRecipientList[0] : ""
                          }}
                        </template>
                      </template>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column prop="subject"
                                 :label="$t('common.subject')" show-overflow-tooltip>
                  <template #default="{row}">
                    <a @click.stop="handleViewDetail(scope.row)"
                    >
                      {{ row.subject }}
                    </a>
                  </template>
                </el-table-column>
                <el-table-column prop="date" sortable :label="$t('common.date')"
                                 width="140">
                  <template #default="{row}">
                    <span v-if="row.sendTime!=null">{{ row.sendTime }}</span>
                    <span v-else-if="row.sentDate!==null">{{ row.sentDate }}</span>
                    <span v-else>{{ row.createTime }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="remark" :label="$t('common.remark')" width="80">
                  <template #default="scope">
                    <div class="flex">
                      <!--                         @click.stop="handleRef(refMap[`${scope.row.id}`], scope.row, 1)"-->
                      <el-icon :ref="(el) => (refMap[`${scope.row.id}`] = el)"
                               @click.stop="handleClickRemark(scope.row)"
                               :color="scope.row.remark ? '#40a9ff' : '#dddddd'" style="padding-top: -2px">
                        <el-tooltip
                            v-if="emailListCheckoutTarget.remark"
                            class="box-item"
                            :content="emailListCheckoutTarget.remark"
                            placement="right"
                            :show-after="600"
                        >
                          <Memo/>
                        </el-tooltip>
                        <Memo v-else/>
                      </el-icon>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column v-if="type===3" prop="isAnswer" :label="$t('common.email.list.isAnswer')"
                                 :width="flexWidth('isAnswer', tableData, $t('common.email.list.isAnswer'))-20">
                  <template #default="{row}">
                    <template v-if="row.isAnswer ===1">
                      <el-button class="btn_reset" link type="success" size="small">{{ $t('common.email.status1') }}
                      </el-button>
                    </template>
                    <template v-else-if="row.isAnswer===0">
                      <el-button class="btn_reset" link type="warning" size="small">{{ $t('common.email.status2') }}
                      </el-button>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column v-if="type===3" prop="isTrack" :label="$t('common.isreading')"
                                 :width="flexWidth('isTrack', tableData, $t('common.isreading'))">
                  <template #default="{row}">
                    <template v-if="row.isTrack===1">
                      <div :ref="(el) => (refMap[`${row.id}_isTrack`] = el)"
                           class="flex">
                        <img
                            @mouseover="handleRef(refMap[`${row.id}_isTrack`], row, 2)" style="cursor: pointer"
                            src="../../../assets/img/email/quanbuyidu.png" height="15"/>
                      </div>
                    </template>
                    <template v-else-if="row.isTrack===0"></template>
                  </template>
                </el-table-column>
                <el-table-column prop="originalPosition" :label="$t('common.location')" v-if="type === 4" width="80">
                  <template #default="{row}">
                    {{
                      row.originalPosition === 1 ? $t('common.emailbox.receive') : row.originalPosition === 3 ? $t('common.has.sent') : row.originalPosition === 2 ? $t('common.draftsbox') : ''
                    }}
                  </template>
                </el-table-column>
                <!--          已发送-->
                <el-table-column prop="groupId" :label="$t('common.send.state')"
                                 :width="flexWidth('groupId', tableData, $t('common.isreading'))" v-if="type===3">
                  <template #default="{row}">
                    <template v-if="row.groupId==='3'">
                      <el-button class="btn_reset" link type="success" style="font-size: 12px !important;">
                        {{ $t('common.email.list.sendSuccess') }}
                      </el-button>
                    </template>
                  </template>
                </el-table-column>
                <!--          草稿箱-->
                <el-table-column prop="groupId" :label="$t('common.send.state')" v-else-if="type===2"
                                 :width="flexWidth('groupId', tableData, $t('common.isreading'))">
                  <template #default="{row}">
                    <el-button class="btn_reset" link type="danger" style="font-size: 12px !important;"
                               v-if="row.groupId==='-1'">{{ $t('common.email.list.sendFailed') }}
                    </el-button>
                    <template v-else-if="row.groupId==='2'">
                      <template v-if="row.sendTime !== null">
                        <el-button class="btn_reset" v-if="new Date(row.sendTime).getTime() > new Date().getTime()" link
                                   type="warning" style="font-size: 12px !important;">{{ $t('common.wait.sent') }}
                        </el-button>
                        <template v-else>
                          <el-button class="btn_reset" link type="default" style="font-size: 12px !important;">
                            {{ $t('common.email.list.draft') }}
                          </el-button>
                        </template>
                      </template>
                      <template v-else>
                        <el-button class="btn_reset" link type="default" style="font-size: 12px !important;">
                          {{ $t('common.email.list.draft') }}
                        </el-button>
                      </template>
                    </template>
                    <template v-else-if="row.groupId==='5'">
                      <el-button class="btn_reset" link
                                 type="warning" style="font-size: 12px !important;">
                        {{ $t('common.wait.sent') }}
                      </el-button>
                    </template>
                    <template v-else-if="row.groupId==='9'">
                      <el-button class="btn_reset" link
                                 type="primary" style="font-size: 12px !important;">
                        {{ $t('common.examine.wait') }}
                      </el-button>
                    </template>
                    <template v-else-if="row.groupId==='10'">
                      <el-button class="btn_reset" link
                                 type="primary" style="font-size: 12px !important;">
                        {{ $t('common.email.list.draft') }}
                      </el-button>
                    </template>
                    <template v-else>
                      {{ $t('common.unknown') }}
                    </template>
                  </template>
                </el-table-column>
                <el-table-column prop="settings" fixed="right" :label="$t('common.cz')" :width="lang==='en'?110:80">
                  <template #default="scope">
                    <template v-if="type !== 4">
                      <el-button link v-if="type===3||type===2" type="primary" size="small"
                                 @click.stop="handleResend(scope.row)"
                      >{{ $t('common.resend') }}
                      </el-button
                      >
                      <el-button link v-if="type===1" type="primary" size="small"
                                 @click.stop="handleReply(scope.row)"
                      >{{ $t('common.reply') }}
                      </el-button
                      >
                      <el-button link type="primary" size="small" @click.stop="handleEdit(scope.row)"
                                 v-if="scope.row.flag===1 && type===1 ||  scope.row.flag===1 && type===3">
                        {{ $t('common.archives.return') }}
                      </el-button>
                    </template>
                    <template v-else>
                      <el-button link type="primary" size="small" @click.stop="requestDelMail(0,scope.row)"
                      >{{ $t('common.restore') }}
                      </el-button
                      >
                      <el-button link type="primary" size="small" @click.stop="requestDelMail(2,scope.row)"
                      >
                        {{ $t('common.delete') }}
                      </el-button>
                    </template>
                  </template>
                </el-table-column>
                <template #empty>
                  <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
                </template>
              </ElTable>
            </div>
            <div title="移动到边界进行位置调整" class="mover">
              <div class="mail_info" ref="moveTarget">
                <div class="up_row move"></div>
                <div style="width: 100%;height: 26px;display: flex">
                  <div class="from over_text">
                    {{ $t('common.email.from') }}：{{ currentMail.mailBody?.from }}
                  </div>
                  <div class="to over_text" ref="toRef">
                    <el-popover
                        v-if="formatText(currentMail.mailRecipientVOList, 'to')"
                        :visible="visibleTo"
                        width="500"
                        :content="formatText(currentMail.mailRecipientVOList, 'to')"
                        :effect="'dark'"
                        popper-class="visible"
                    >
                      <template #reference>
                    <span @mouseenter="showPopover(formatText(currentMail.mailRecipientVOList, 'to'),'to')"
                          @mouseleave="delayedHidePopover('to')">{{
                        $t('common.email.to')
                      }}：{{ formatText(currentMail.mailRecipientVOList, 'to') }}</span>
                      </template>
                      <div @mouseenter="keepPopoverVisible('to')" @mouseleave="hidePopover('to')">
                        {{ formatText(currentMail.mailRecipientVOList, 'to') }}
                      </div>
                    </el-popover>
                    <template v-else>
                      {{ $t('common.email.to') }}：{{ formatText(currentMail.mailRecipientVOList, 'to') }}
                    </template>
                  </div>
                  <div class="cc over_text">
                    <el-popover
                        v-if="formatText(currentMail.mailRecipientVOList, 'cc')"
                        :visible="visibleCC"
                        width="500"
                        :content="formatText(currentMail.mailRecipientVOList, 'cc')"
                        :effect="'dark'"
                        popper-class="visible"
                    >
                      <template #reference>
                <span
                    @mouseenter="showPopover(formatText(currentMail.mailRecipientVOList, 'cc'),'cc')"
                    @mouseleave="delayedHidePopover('cc')"
                >
                {{ $t('common.cc') }}：{{ formatText(currentMail.mailRecipientVOList, 'cc') }}
                </span>
                      </template>
                      <div @mouseenter="keepPopoverVisible('cc')" @mouseleave="hidePopover('cc')">
                        {{ formatText(currentMail.mailRecipientVOList, 'cc') }}
                      </div>
                    </el-popover>
                    <template v-else>
                      {{ $t('common.cc') }}：{{ formatText(currentMail.mailRecipientVOList, 'cc') }}
                    </template>
                  </div>
                </div>
                <div class="down_row move"></div>
              </div>
            </div>
            <div class="downBox" :style="{height:`calc(100% - ${inboxTop + 10}px)`}">
              <!--右下div内容-->
              <!--              <iframe ref="pageframe" id="pageframe"-->
              <!--                      :srcdoc="iframeContent"-->
              <!--                      frameborder="0"-->
              <!--                      :style="{width: '100%',height: '100%'}"-->
              <!--                      :class="detailLoading?'scroll':'scroll loaded'"-->
              <!--              >-->
              <!--                <p>Your browser does not support preview features, please download Google browser or other modern-->
              <!--                  browsers.</p>-->
              <!--              </iframe>-->
              <div v-loading="detailLoading" style="width: 100%;height: 100%">
                <iframe ref="pageframe" id="pageframe" :srcdoc="iframeContent"
                        frameborder="0"
                        :style="{width: '100%',height: '100%'}"
                        :class="detailLoading?'scroll':'scroll loaded'"
                >
                  <p>Your browser does not support preview features, please download Google browser or other modern
                    browsers.</p>
                </iframe>
                <div v-if="isDragging" id="mask"
                     :style="{width: '100%',height:`calc(100% - ${inboxTop + 10}px)`,background:'rgba(0,0,0,0)',position:'absolute',zIndex:999,top:inboxTop+'px'}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!--      高级搜索-->
      <el-drawer
          v-model="drawer"
          :title="$t('common.search.advanced')"
          :direction="direction"
          class="el_modal"
      >
        <el-form
            :label-position="labelPosition"
            label-width="100px"
            :model="formLabelAlign"
        >
          <el-form-item :label="$t('common.customer.nameList')+':'" prop="customerName">
            <el-input v-model="formLabelAlign.customerName" :placeholder="$t('30003')"/>
          </el-form-item>
          <el-form-item :label="$t('common.customer.levelList')+':'" prop="customerTypeId">
            <el-select
                v-model="formLabelAlign.customerTypeId"
                style="width: 100%;"
                popper-class="customer-level"
                :placeholder="$t('common.please.select')"
            >
              <el-option
                  v-for="item in tableDataLevel"
                  :key="item.id"
                  :label="item.customerType"
                  :value="item.id"
              >
                <div class="inner_select">
                  {{ item.customerType }}
                </div>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('common.customer.Region')+':'" prop="zoneAddress">
            <el-select
                filterable
                v-model="formLabelAlign.zoneAddress"
                style="width: 100%;"
                :placeholder="$t('common.please.select')"
            >
              <el-option
                  v-for="item in areaList"
                  :key="item.country"
                  :label="item.country"
                  :value="item.country"
              />
            </el-select>
          </el-form-item>
          <!--          发件箱-->
          <el-form-item :label="$t('reply.status')+':'" prop="isAnswer" v-if="type===3">
            <el-select
                filterable
                v-model="formLabelAlign.isAnswer"
                style="width: 100%;"
                :placeholder="$t('common.please.select')"
            >
              <el-option :label="$t('common.all')" :value="-1"></el-option>
              <el-option :label="$t('common.email.status1')" :value="1"></el-option>
              <el-option :label="$t('common.email.status2')" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <!--          收件箱-->
          <el-form-item :label="$t('reply.status')+':'" prop="isReply" v-if="type===1">
            <el-select
                filterable
                v-model="formLabelAlign.isReply"
                style="width: 100%;"
                :placeholder="$t('common.please.select')"
            >
              <el-option :label="$t('common.all')" :value="-1"></el-option>
              <el-option :label="$t('common.email.status1')" :value="1"></el-option>
              <el-option :label="$t('common.email.status2')" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('read.status')+':'" prop="isTrack" v-if="type===3">
            <el-select
                filterable
                v-model="formLabelAlign.isTrack"
                style="width: 100%;"
                :placeholder="$t('common.please.select')"
            >
              <el-option :label="$t('common.all')" :value="-1"></el-option>
              <el-option :label="$t('read.status.isread')" :value="1"></el-option>
              <el-option :label="$t('read.status.unread')" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('common.send.state')" v-if="type===2">
            <el-select
                filterable
                v-model="formLabelAlign.type"
                style="width: 100%;"
                :placeholder="$t('common.please.select')"
            >
              <el-option :label="$t('common.setting.allTime')" :value="2"></el-option>
              <el-option :label="$t('common.wait.sent')" :value="5"></el-option>
              <el-option :label="$t('common.email.list.sendFailed')" :value="-1"></el-option>
              <el-option :label="$t('common.examine.wait')" :value="9"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('common.date')+':'" prop="rangePickerTime">
            <el-date-picker
                style="width: 100%;"
                v-model="formLabelAlign.rangePickerTime"
                type="daterange"
                format="YYYY-MM-DD HH:mm:ss"
                range-separator="~"
                :start-placeholder="$t('search.start.date')"
                :end-placeholder="$t('search.end.date')"
            />
          </el-form-item>
          <!--          <el-form-item :label="$t('email.list.draft.status')+':'" prop="draftStatus" v-if="type===2">-->
          <!--            <el-select-->
          <!--                filterable-->
          <!--                v-model="formLabelAlign.isReply"-->
          <!--                style="width: 100%;"-->
          <!--                :placeholder="$t('common.please.select')"-->
          <!--            >-->
          <!--              <el-option :label="'草稿'" :value="1"></el-option>-->
          <!--              <el-option :label="'自动草稿'" :value="0"></el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <el-form-item>
            <div class="fx-j-e">
              <el-button type="primary" style="margin-right: 12px;" @click="handleSearch('search')">{{
                  $t('common.search')
                }}
              </el-button>
              <el-button style="margin-left: 10px" @click="filterReset">{{ $t('common.empty') }}</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-drawer>
      <!--      建档-->
      <ReturnEmails ref="emailsReturn" v-if="dialogVisible" v-model="dialogVisible" @close="close"
                    @createCustomer="createdNewCustomer" :targetEmail="activeItemRow" :reGetList="getMailList"
      ></ReturnEmails>
      <!--      创建客户-->
      <CustomerCreated ref="customerCreated" v-if="dialogVisibleCreatedMessage"
                       v-model="dialogVisibleCreatedMessage" @close="closeCreated" :innerDialog="dialogVisible"
                       @changeInnerDialog="close"></CustomerCreated>
      <BlackConfirmModal :showFlag="blackModalShow" :list="multipleSelection.map(item => item.from)"
                         @succ="handleBlackSucc" @cancel="handleBlackCancel"/>
      <!--      彻底删除-->
      <Dialog
          v-model="dialogVisibleDelete"
          :title="$t('common.delete.confirm')"
          :dialogVisible="dialogVisibleDelete"
          :isHeadLine="true"
          :width="400"
          @update:dialogVisible="(val) => (dialogVisibleDelete = val)"
      >
        <template #content>
          <div style="margin-top: 20px;margin-bottom: 40px">
            {{ $t('other.text.ask.email.delete.empty') }}
          </div>
        </template>
        <template #footer>
          <div class="footer_box">
            <el-button @click.native.stop="dialogVisibleDelete = false">{{ $t('common.sss16') }}</el-button>
            <el-button type="primary" @click="deleteAll">{{ $t('common.confirm') }}</el-button>
          </div>
        </template>
      </Dialog>
      <!--组件抽离-->
      <el-popover
          v-if="popoverType!==0"
          virtual-triggering
          :virtual-ref="tempRef"
          v-model:visible="visiblePopover"
          :placement="popoverType===1?'top':'bottom'"
          :width="popoverType===1?204:300"
          :show-after="500"
          popper-class="sp_model_popover"
          :effect="popoverType!==1?'dark':'light'"
          :popper-options="{
              modifiers: [{
		          name: 'offset',
		          options: {
			          offset: [8, 8]
		          }
	        }]
	    }">
        <template v-if="popoverType===2">
          <el-tooltip placement="top" style="pointer-events: none">
            <div slot="content" style="pointer-events: none">
              {{
                $t("common.email.list.isTrack", {
                  openTime: emailListCheckoutTarget.openTime,
                  openAddress: emailListCheckoutTarget.openAddress,
                  openIp: emailListCheckoutTarget.openIp,
                  openCount: emailListCheckoutTarget.openCount,
                })
              }}
            </div>
          </el-tooltip>
        </template>
        <template v-else-if="popoverType===3">
          {{ emailListCheckoutTarget.subject }}
        </template>
      </el-popover>
      <!--   添加备注   -->
      <Remark v-if="visitableRemark" v-model="visitableRemark" @close="closeRemark" :isHeadLine="true"
              :currentItem="currentItem" @getList="getMailList"></Remark>
    </div>
  </div>
</template>
<script>
import {onMounted, reactive, ref, watch, provide, computed, nextTick, onBeforeUnmount} from 'vue';
import {
  getMail,
  tagMail,
  tagAllRead,
  delMail,
  getMailByUserId,
  getMailDetails, getGroupMailList, groupEmail
} from '@/api';
import {
  requestMailAccessory,
  requestUpdateMailRemark,
} from '@/api/mail';
import {getTeamUserListAll} from '@/api/groups';
import {ElMessage, ElTable} from 'element-plus';
import {useStore} from 'vuex';
import {useRoute, useRouter} from "vue-router";
import Dialog from "@/components/Dialog.vue";
import MyTitles from "@/views/Customer/Detail/MyTitles.vue";
import Forms from "@/views/Customer/CreateCheck/Forms.vue";
import Cards from "@/views/Customer/Cards.vue";
import ContactTable from "@/views/Customer/Detail/ContactTable.vue";
import BlackConfirmModal from '../BlackConfirmModal.vue';
import {debouncePlus, throttle} from "@/utils/mylodash"
import {i18n} from "@/lang";
import {Plus} from '@element-plus/icons-vue'
import {emptyUrl, htmlReg, POPOVER_DELAY_TIME, TIME_OUT} from '@/utils/statusParams'
import ReturnEmails from "@/components/DiaPops/Returns/ReturnEmails.vue";
import CustomerCreated from "@/components/DiaPops/Returns/CustomerCreated.vue";
import {formatSize} from "@/utils/file";
import {flexWidth} from "@/utils/tableWidth";
import {addImgOnErrorAttribute, containsHtmlTags, convertLinksToHTML, convertToHTML} from "@/utils/iframe";
import router from "@/router";
import {getScaleValue} from "@/utils/view"
import {getPosType} from "@/utils/device";
import Remark from "@/components/Remark/index.vue"
import CreateDir from "@/views/Cloud/Components/CreateDir.vue";

function numStr(num) {
  return num < 10 ? `0${num}` : num;
}

function formatTime(date) {
  return `${date.getFullYear()}-${numStr(date.getMonth() + 1)}-${numStr(date.getDate())}`;
}

const commandList = [1, 0, 1, 0];

const initForm = {
  customerName: null,
  customerTypeId: null,
  zoneAddress: null,
  isAnswer: null,
  isTrack: null,
  isReply: null,
  type: 2,
  rangePickerTime: [],
}
export default {
  methods: {
    getPosType,
    router() {
      return router
    }, flexWidth, formatSize
  },
  computed: {
    Plus() {
      return Plus
    }
  },
  components: {
    Remark,
    CreateDir,
    ElTable,
    CustomerCreated,
    ReturnEmails,
    ContactTable,
    Cards,
    Forms,
    MyTitles,
    Dialog,
    BlackConfirmModal,
  },
  // (-1:失败邮件,1:收件箱,2:草稿箱,3:已发送,4:垃圾箱,4:垃圾邮件)
  props: ['type', 'group'],
  setup(props) {
    const isShiftPressed = ref(false); // 标记 Shift 键是否被按下
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const configId = ref('all');
    const emailList = ref([]);
    const searchCount = ref('');
    const current = ref(1);
    const size = ref(40);
    const total = ref(0);
    const tableData = ref([]);
    const loading = ref(false);
    const multipleTableRef = ref(null);
    const multipleSelection = ref([]);
    const currentMail = ref({});
    const inboxRef = ref(null);
    const tableHeight = ref(200);
    const drawer = ref(false);
    const direction = ref('rtl')
    const labelPosition = ref('top');
    const selectedTeamId = ref('');
    const selectedFolder = ref("");
    const iframeContent = ref('');
    const selectedUserIdMap = ref({});
    const formLabelAlign = reactive({...initForm});
    const tableDataSelect = ref([]);
    const tableRef = ref(null);
    const blackModalShow = ref(false);
    const dialogVisibleDelete = ref(false);

    // 设备标识
    const deviceFlag = computed(() => {
      return getPosType()
    })

    // 下标记录
    // const lastIdx = ref(-1);
    // // shift标识
    // const shiftFlag = ref(false);

    // 文件夹信息
    const fileList = computed(() => route.path !== '/email/inbox' ? [{
      "id": "99999",
      "fileName": i18n.global.t('common.emailbox.receive'),
    }, ...store.state.sidebar.fileList.filter(item => item.id !== selectedFolder.value)] : store.state.sidebar.fileList.filter(item => item.id !== selectedFolder.value))
    const targetFileList = computed(() => route.path !== '/email/inbox' && route.path !== '/email/has_send' && route.path !== '/email/draft' && route.path !== '/email/waste' ? [{
      "id": "99999",
      "fileName": i18n.global.t('common.emailbox.receive'),
    }, {
      "id": "99999",
      "fileName": i18n.global.t('common.has.sent'),
    }, ...store.state.sidebar.targetFileList.filter(item => item.id !== selectedFolder.value)] : store.state.sidebar.targetFileList.filter(item => item.id !== selectedFolder.value))

    // 建档信息
    const ruleForm = computed(() => store.state.customer.ruleForm);
    const form = computed(() => store.state.customer.form);
    // 区域列表
    const areaList = computed(() => store.state.customer.areaList);
    // 客户等级
    const tableDataLevel = computed(() => store.state.cusList.tableData);
    // 对话框
    const dialogVisible = ref(false);
    // 对话框表单信息
    const forms = reactive({email: '', customerName: '', merge: true, contactName: ''})
    const teamId = computed(() => store.state.email.teamID);
    const options = []
    const optionsCustomers = ref([])
    //默认文件id
    const fileGroupId = computed(() => store.state.sidebar.targetFileList.length > 0 ? store.state.sidebar.targetFileList[0].id : null)


    // 语言
    const lang = computed(() => store.state.system.langLocal)
    // 对话框中的建档
    const dialogVisibleCreatedMessage = ref(false);
    // 联系人
    const contactPerson = [{
      label: i18n.global.t('common.email.existingContacts'),
      value: true
    }, {label: i18n.global.t('common.email.newContacts'), value: false}];
    // 手动筛选联系人列表
    const contactList = ref([])


    const handleSelectUser = async (val) => {
      if (route.path === '/email/foldergroup') {
        console.log(val, 'val', selectedTeamId.value);
        await store.dispatch("sidebar/getEmailGroupFileListApis", {teamId: teamId.value, userId: selectedTeamId.value})
        selectedFolder.value = targetFileList.value.filter(item => item.id !== "99999").length >= 1 ? targetFileList.value[1].id : "";
        await getMailList()
      }
    }
    const handleSelectFolder = async (val) => {
      console.log(val, 'val')
      selectedFolder.value = val;
      await getMailList();
    }


    // 边界控制归档选择
    // const customerFlag = ref(false)
    const visiblePop = ref(false)

    const moneySendGrandson = function (value) {
      setTimeout(() => {
        getMailList()
      }, 3000)
    }
    provide('moneySendGrandson', moneySendGrandson)

    const removeEmail = (index) => {
      ruleFormUsers.mailContactEmailList.splice(index, 1);
    };
    // 编辑邮件信息
    const activeItemRow = ref([])
    const emailsReturn = ref(null)

    async function handleEdit(row) {
      dialogVisible.value = true
      // console.log(route.path, 'route', row)
      if (route.path === "/email/has_send") {
        activeItemRow.value = toEmailObj(row.mailRecipientList)
      } else {
        activeItemRow.value = [{email: row.from}]
      }

      await nextTick();
      await emailsReturn.value.getAllCustomers();
    }

    //邮箱转换
    const toEmailObj = (arr) => {
      return arr.map(item => {
        return {email: item}
      })
    }

    const createdNewCustomer = async (value) => {
      dialogVisibleCreatedMessage.value = true
      store.commit('customer/setRuleForm', {
        zoneCode: "",
        customerCode: "",
        zoneAddress: "",
        customerName: "",
        setUpTime: "",
        economicNature: "",
        companyWebsite: "",
        companyAddress: "",
        phoneWayList: [],
        emailWayList: [],
        companyFax: "",
        companyRemark: "",
        customerLevel: "",
        customerType: "",
        customerTypeId: "",
        product: "",
        marketingRemark: " ",
        createBy: "",
        branchAdmin: "",
        mangerVOList: [{managerName: ''}]
      });
      await store.commit('customer/setForm', {
        phones: [{phoneNumber: "", isDefault: true}],
        emails: [{email: value, isDefault: true}],
      })
    }

    function getMailListConf() {
      getMailByUserId({teamId: teamId.value, userId: selectedTeamId.value || ''})
          .then((res) => {
            // // console.log(res.result, '邮箱配置列表');
            const list = res.result || [];
            emailList.value = list.map(item => ({label: item.username, value: item.id}));
          });
    }

    const teamList = computed(() => store.state.email.teamList)

    const userInfo = computed(() => store.state.user.userInfo)


    async function getAllUserList() {
      const result = await getTeamUserListAll({teamId: teamId.value});
      if (result.code !== 200) {
        ElMessage.error(result.message)
        return
      }
      console.log(result.result, 'result.result')

      // 如果没有查到相关的id那么就需要跳转到默认 选中项
      // await store.dispatch('email/getTeamList');
      console.log(teamList.value, 'teamList.value')

      if (result.result && result.result.length === 0) {
        if (teamList.value.length >= 1) {
          localStorage.setItem('teamId', teamList.value[0].id)
        } else {
          localStorage.removeItem("teamId")
          //   如果查询到没有团队的详情的话那么就跳转到首页
          router.push("/dashboard")
        }
      }
      console.log(result.result, 'result.result')
      if (!route.params.folderId) {
        tableDataSelect.value = result.result || [];
      } else {
        tableDataSelect.value = result.result.filter(item => item.userId === userInfo.value.id)
      }
      if (tableDataSelect.value.length) {
        console.log(tableDataSelect.value, tableDataSelect.value.find(_ => _.userId === userInfo.value.id), 'tableDataSelect.value')
        selectedTeamId.value = tableDataSelect.value.find(_ => _.userId === userInfo.value.id).userId;
        selectedUserIdMap.value = tableDataSelect.value.reduce((prev, item) => {
          prev[item.id] = item.userId;
          return prev;
        }, {});
        console.log('selectedUserIdMap.value', selectedUserIdMap.value)
      }
    }

    async function handleDownload(scope) {
      const result = await requestMailAccessory({id: scope.row.id});
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      console.log(result, 'result')
      window.open(`${location.origin}/email/detail/${scope.row.id}/${JSON.stringify({
        flag: scope.row.flag ? scope.row.flag : 0,
        isExtend: scope.row.isExtend ? scope.row.isExtend : 0,
        from: scope.row.from
      })}/${props.type}`);
    }

    function handleToCustomer(scope) {
      router.push({
        path: `/email/customer/detail/${scope.row.id}`,
        query: {type: 1},
      });
    }

    function formatText(list, type) {
      if (!list || !list.length) {
        return '';
      }
      const infoMap = list.reduce((prev, item) => {
        if (!prev[item.mailType]) {
          prev[item.mailType] = [];
        }
        prev[item.mailType].push(item.mail);
        return prev;
      }, {});
      return infoMap[type] ? infoMap[type].join(' , ') : '';
    }

    async function handleCurrentChange(page) {
      current.value = page;
      tableRef.value.setScrollTop(0);
      await getMailList(sortObj.value);
    }

    //回复
    function handleReply(row) {
      // // console.log(row, 'fff');
      localStorage.setItem('reSend', JSON.stringify(row))
      const query = {id: row.id, flag: row.flag, isRead: row.isRead, mailType: props.type};
      if (row.flag === 0 && row.customerId) {
        query.customerId = row.customerId;
      }
      router.push({
        path: '/email/write_mail',
        query,
      });
    }

    //重发
    function handleResend(row) {
      // console.log(row, 'fff');
      localStorage.setItem('reSend', JSON.stringify(row))
      const query = {id: row.id, flag: row.flag, type: "resend", mailType: props.type};
      if (row.flag === 0 && row.customerId) {
        query.customerId = row.customerId;
      }
      router.push({
        path: '/email/write_mail',
        query,
      });
    }

    async function getMailList(sort) {
      let folderId = route.params.folderId || route.path === '/email/foldergroup';
      const teamIdCheck = route.query.teamID ? route.query.teamID : localStorage.getItem("teamId");
      loading.value = true;
      const formParams = {...formLabelAlign};
      console.log(formParams, 'formParams')
      delete formParams.rangePickerTime;
      delete formParams.isTrack;
      delete formParams.isAnswer;
      delete formParams.isReply;
      let payload = {
        ...formParams,
        mailConfigId: configId.value === 'all' ? null : configId.value,
        pageNo: isSearch.value ? 1 : current.value,
        type: (props.type === 2 ? formParams.type : props.type) || 1, // `group_id` char(50) DEFAULT NULL COMMENT '邮件类型(-1:失败邮件,1:收件箱,2:草稿箱,3:已发送,4:已删除,5:垃圾邮件)',
        userId: selectedTeamId.value,
        teamId: teamIdCheck,
        search: isClear.value ? "" : searchCount.value,
        pageSize: size.value,
        stress: null,
        isAnswer: formLabelAlign.isAnswer === -1 ? "" : formLabelAlign.isAnswer,
        isTrack: formLabelAlign.isTrack === -1 ? "" : formLabelAlign.isTrack,
        isReply: formLabelAlign.isReply === -1 ? "" : formLabelAlign.isReply,
        startTime: formLabelAlign.rangePickerTime.length ? formatTime(formLabelAlign.rangePickerTime[0]) : null,
        endTime: formLabelAlign.rangePickerTime.length ? formatTime(formLabelAlign.rangePickerTime[1]) : null,
        // rangePickerTime: null,
      }
      if (folderId) {
        payload = {...payload, fileGroupId: folderId}
      }
      if (route.path === '/email/foldergroup') {
        payload = {...payload, fileGroupId: selectedFolder.value === "" ? fileGroupId.value : selectedFolder.value}
      }
      if (sort) {
        payload = {...payload, ...sort}
      }

      let ApiTarget = folderId ? getGroupMailList : getMail;

      if (folderId) {
        delete payload.type
      }

      const result = await ApiTarget(payload);
      if (result.code !== 200) {
        ElMessage.error(result.message)
        return
      }
      loading.value = false;
      isSearch.value = false;
      // // console.log('列表', result);
      const {records, total: newTotal} = result.result;
      total.value = newTotal;
      tableData.value = records;
      current.value = result.result.current;
    }

    let endIndex = -1;
    //按住shirt键
    const handleKeyDown = (event) => {
      if (event.key === 'Shift' && event.keyCode === 16) {
        // console.log("进来了-----------------", event.key, event.keyCode);
        isShiftPressed.value = true;
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === 'Shift' && event.keyCode === 16) {
        isShiftPressed.value = false;
      }
    };

    function handleSelectionChange(val) {
      // console.log("val-----------------------", val)
      //判断是否按住shirt键盘
      // console.log(isShiftPressed.value);
      endIndex = val.length > 1 ? tableData.value.findIndex(item => item.id === val[val.length - 1].id) : -1;
      const startIndex = val.length > 1 ? tableData.value.findIndex(item => item.id === val[val.length - 2].id) : -1;
      console.log(endIndex, startIndex);
      if (isShiftPressed.value && val.length > 1 && endIndex !== startIndex) {
        const start = startIndex < endIndex ? startIndex : endIndex;
        const end = endIndex < startIndex ? startIndex : endIndex;
        // console.log(start, end, '===============');
        for (let i = start; i <= end; i++) {
          const row = tableData.value[i];
          if (start === i || i === end) {
            continue;
          }
          console.log(row, '更新');
          if (row) {
            tableRef.value.toggleRowSelection(row, true); // 调用表格组件的方法来更新选中状态
          }
        }
        nextTick(() => {
          multipleSelection.value = tableRef.value.getSelectionRows();
          console.log(tableRef.value.getSelectionRows(), '当前选中行');
        });
        // Shift 键被按下，进行批量选中处理
        // const firstRow = val[0];
        // const lastRow = val[val.length - 1];
        // const firstIndex = tableData.value.findIndex(item => item.id === firstRow.id);
        // const lastIndex = tableData.value.findIndex(item => item.id === lastRow.id);


        // if (firstIndex !== -1 && lastIndex !== -1) {
        //   const start = Math.min(firstIndex, lastIndex);
        //   const end = Math.max(firstIndex, lastIndex);
        //   console.log("start", start)
        //   console.log("end:", end)
        //   for (let i = start; i <= end; i++) {
        //     const row = tableData.value[i];
        //     if (row) { // 检查行是否存在，以防索引超出范围
        //       tableRef.value.toggleRowSelection(row, true); // 调用表格组件的方法来更新选中状态
        //     }
        //   }
        //   for (let i = 0; i < 40; i++) {
        //     if (start <= i && i <= end) {
        //       continue
        //     }
        //     const row = tableData.value[i];
        //     if (row) { // 检查行是否存在，以防索引超出范围
        //       tableRef.value.toggleRowSelection(row, false); // 调用表格组件的方法来更新选中状态
        //     }
        //   }
        //   //批量勾选
        //   multipleSelection.value = tableData.value.slice(start, end + 1).map(row => ({...row}));
        // }
        // console.log("multipleSelection.value-------------", multipleSelection.value)
      } else {
        //走正常逻辑
        multipleSelection.value = val;
        console.log(multipleSelection.value, startIndex);
      }
    }

    async function requestTagMail(tag, type) {
      const result = await tagMail({
        [type]: tag,
        mailIdList: multipleSelection.value.map(item => item.id),
      });
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      // 更新标志
      await store.dispatch('countshow/getTeamAllDataApis')

      if (multipleSelection.value.length === 1) {
        let isStress = multipleSelection.value[0][type]
        console.log(isStress, 'isStress')
        //   软删除
        tableData.value = tableData.value.map(item => {
          if (item.id === multipleSelection.value[0].id) {
            return {...item, [type]: isStress === 1 ? 0 : 1}
          } else {
            return item
          }
        })
      } else {
        // 删除刷新
        await getMailList()
      }


    }

    async function requestTagAllRead() {
      const result = await tagAllRead({
        teamId: teamId.value,
        userId: selectedTeamId.value,
        type: props.type || 1,
        mailConfigId: configId.value === 'all' ? null : configId.value,
      });
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      ElMessage.success(i18n.global.t('email.list.all.isread'));
      // 更新标志
      await store.dispatch('countshow/getTeamAllDataApis')
      await getMailList();
    }

    async function requestDelMail(flag, isSelect) {
      // // console.log(flag, isSelect, 'ff')
      if (isSelect) {
        multipleSelection.value.push(isSelect)
      }

      if (!multipleSelection.value.length) {
        ElMessage.error(i18n.global.t('sys.tip.unselect.email'));
        return;
      }

      if (flag === 0) {
        const result = await delMail({
          type: flag, // 0 还原  1 已删除 2彻底删除
          mailIdList: multipleSelection.value.map(item => item.id),
          originalPosition: isSelect.originalPosition,
        });
        if (result.code !== 200) {
          ElMessage.error(result.message);
          return;
        }
        ElMessage.success(i18n.global.t('email.list.reduction'));
        setTimeout(async () => {
          await getMailList();
        }, 1500);
      }
      if (flag === 1) {
        const result = await delMail({
          type: flag, // 0 还原  1 已删除 2彻底删除
          mailIdList: multipleSelection.value.map(item => item.id),
          originalPosition: props.type,
        });
        if (result.code !== 200) {
          ElMessage.error(result.message);
          return;
        }
        ElMessage.success(i18n.global.t('sys.message.success.delete'));
        setTimeout(async () => {
          await getMailList();
        }, 1500);
      }
      if (flag === 2) {
        dialogVisibleDelete.value = true;
      }
    }

    // 彻底删除提示
    async function deleteAll() {
      const result = await delMail({
        type: 2, // 0 还原  1 已删除 2彻底删除
        mailIdList: multipleSelection.value.map(item => item.id),
        originalPosition: props.type,
      });
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      ElMessage.success(i18n.global.t('email.list.delete'));
      dialogVisibleDelete.value = false
      setTimeout(async () => {
        await getMailList();
      }, 1500);
    }


    async function handleBlack() {
      if (!multipleSelection.value.length) {
        ElMessage.error(i18n.global.t('sys.tip.unselect.email'));
        return;
      }
      blackModalShow.value = true;
    }

    const activeItems = ref({})
    const detailLoading = ref(false)

    const handleCurrentRowChange = async (current) => {
      if (current) {
        getTargetMailId.value = current.id;
      }
      activeItems.value = current
      if (current !== null) {
        detailLoading.value = true
        try {
          const result = await getMailDetails({id: current.id});
          let content = result.result?.mailBody?.content;
          const insertionContent = `<script>
            function errorImage(img) {
                console.log(img,'img')
                img.onerror = null;
                img.src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR42mP4//8/AAX+Av4zEpUUAAAAAElFTkSuQmCC"
            }
        <\/script>`;
          if (!content) {
            iframeContent.value = ''
          } else {
            if (containsHtmlTags(content)) {
              const replacedHtmlString = addImgOnErrorAttribute(content)
              console.log('content', content);
              const res = replacedHtmlString.replace(/(<html[^>]*>)/, '$1' + insertionContent);
              iframeContent.value = res;
            } else {
              console.log("非html")
              // 纯图片需要直接展示
              if (content.indexOf('https://s3gw.cmbimg.com') !== -1) {
                iframeContent.value = content;
              } else {
                iframeContent.value = convertLinksToHTML(convertToHTML(content))
              }
            }
          }
          currentMail.value = result.result;
          detailLoading.value = false
        } catch (e) {
          console.log('------------iframe----------')
          detailLoading.value = false
        }
      }
    }

    const getTargetMailId = ref(null)

    async function handleViewDetail(row) {
      console.log('双击', row);
      // if (props.type === 3) {
      //   const query = {id: row.id, flag: row.flag, type: "resend"};
      //   if (row.flag === 0 && row.customerId) {
      //     query.customerId = row.customerId;
      //   }
      //   router.push({
      //     path: '/email/write_mail',
      //     query,
      //   });
      // } else if (props.type === 2) {
      //   const query = {id: row.id, flag: row.flag, type: "editMail", fromType: props.type};
      //   if (row.flag === 0 && row.customerId) {
      //     query.customerId = row.customerId;
      //   }
      //   router.push({
      //     path: '/email/write_mail',
      //     query,
      //   });
      // } else {
      getTargetMailId.value = row.id
      //邮件已读
      multipleSelection.value = [row]
      // 已读的邮件 避免被重复赋值
      if (row?.isRead !== 1) {
        await requestTagMail(commandList[0], 'isRead');
      }
      window.open(`${location.origin}/email/detail/${row.id}/${JSON.stringify({
        flag: row.flag ? row.flag : 0,
        isExtend: row.isExtend ? row.isExtend : 0,
        from: row.from,
        customerId: row.customerId,
      })}/${props.type}`);

      // window.location.href = `${location.origin}/email/detail/${row.id}/${JSON.stringify({
      //     flag: row.flag ? row.flag : 0,
      //     isExtend: row.isExtend ? row.isExtend : 0,
      //     from: row.from,
      //   })}/${props.type}`;
      await getMailList();
      // }
    }

    async function handleCommand(val) {
      // // console.log(val, multipleSelection.value);
      if (!multipleSelection.value.length) {
        ElMessage.error(i18n.global.t('sys.tip.unselect.email'));
        return;
      }
      const type = val === 0 || val === 1 ? 'isRead' : 'isStress';
      requestTagMail(commandList[val], type);
    }

    // 邮件分组
    async function handleGroupEmail(id) {
      if (!multipleSelection.value.length) {
        ElMessage.error(i18n.global.t('sys.tip.unselect.email'));
        return;
      }
      console.log(multipleSelection.value, 'multipleSelection.value')
      if (id === '99999') {
        // alert("移动到 收件箱")
        const result = await delMail({
          type: 3, // 0 还原  1 已删除 2彻底删除 3.还原
          mailIdList: multipleSelection.value.map(item => item.id),
          teamId: teamId.value,
        });
        if (result.code !== 200) {
          ElMessage.error(result.message);
          return;
        }
        ElMessage.success(result.message)
        console.log('result--邮件已还原', result)
        await getMailList()
      } else {
        await groupEmailTarget(id)
      }
    }

    // 邮件分组
    async function groupEmailTarget(id) {
      try {
        const payload = {
          userId: selectedTeamId.value,
          fileGroupId: id,
          mailIdList: multipleSelection.value.map(item => item.id)
        }
        console.log(payload)
        const res = await groupEmail(payload)
        ElMessage.success(res.message)
        await store.dispatch('countshow/getTeamAllDataApis')
        await getMailList()
      } catch (e) {

      }
    }

    async function handleSettingStress(scope, type) {
      // // console.log(scope.row, type);
      multipleSelection.value = [scope.row];
      handleCommand(type);
      // 更新标志
      await store.dispatch('countshow/getTeamAllDataApis')
    }

    const handleSetRead = async (scope) => {
      // // console.log(scope);
      const isRead = scope.row.isRead;
      const result = await tagMail({
        isRead: isRead === 1 ? 0 : 1,
        mailIdList: [scope.row.id],
      });
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      tableData.value = tableData.value.map(item => {
        if (item.id === scope.row.id) {
          return {...item, isRead: isRead === 1 ? 0 : 1}
        } else {
          return item
        }
      })
      // tableData.value[scope.$index].isRead = isRead === 1 ? 0 : 1;
      // console.log(tableData.value, tableData.value[scope.$index], 'index')
      // if (sortObj.value?.orderRead !== null) {
      //   await getMailList()
      // }
      // 更新标志
      await store.dispatch('countshow/getTeamAllDataApis')
    }

    function handleSearch(val) {
      // // console.log(formLabelAlign);
      if (val === 'search') {
        isSearch.value = true
      }
      getMailList();
      drawer.value = false;
    }

    function filterReset() {
      formLabelAlign.customerName = initForm.customerName;
      formLabelAlign.customerTypeId = initForm.customerTypeId;
      formLabelAlign.isAnswer = initForm.isAnswer;
      formLabelAlign.isTrack = initForm.isTrack;
      formLabelAlign.isReply = initForm.isReply;
      formLabelAlign.rangePickerTime = initForm.rangePickerTime;
      formLabelAlign.zoneAddress = initForm.zoneAddress;
      formLabelAlign.type = 2;
      // drawer.value = false;
      nextTick(() => {
        getMailList();
      })
    }

    function tableRowClassName({row, rowIndex}) {
      return `read_${row.isRead}`;
    }

    function handleBlackSucc() {
      blackModalShow.value = false;
      setTimeout(() => {
        getMailList();
      }, 1500);
    }

    function handleBlackCancel() {
      blackModalShow.value = false;
    }

    const isClear = ref(false);
    //是否点击搜索或者高级搜索按钮,页数从1开始计算
    const isSearch = ref(false);


    function handleSearchInput(flag) {

      if (flag === "clear") {
        isClear.value = true
      } else if (flag === 'search') {
        isSearch.value = true
      } else {
        isClear.value = false
      }
      getMailList();
    }

    watch(() => teamId.value, async () => {
      console.log('组件团队ID 监听', teamId.value);
      await getMailList();
      if (teamId.value) {
        configId.value = 'all';
        await getMailListConf();
        await getAllUserList();
      }
    })
    watch(() => selectedTeamId.value, (value, item) => {
      console.log('组件团队ID 监听', teamId.value);
      if (selectedTeamId.value !== item) {
        getMailList();
        getMailListConf();
        // store.dispatch("user/checkUserInfoIsAdmin", {teamId: teamId.value})
      }
    });
    watch(() => configId.value, () => {
      getMailList();
    });
    const myEmailList = computed(() => store.state.countshow.myEmailList)
    watch(() => myEmailList.value, async (value) => {
      if (myEmailList.value.map((item) => item.count).reduce((acc, val) => acc + val, 0) !== value.map((item) => item.count).reduce((acc, val) => acc + val, 0)) {
        await getMailList()
      }
    }, {immediate: true})

    const getEmailListFlag = computed(() => store.state.countshow.getEmailListFlag)
    watch(() => getEmailListFlag.value, async () => {
      await getMailList();
    })

    // 页面进行缩放
    const adjustContentPosition = debouncePlus(() => {
      console.log('resize')
      localStorage.setItem("resize", true)
      router.go(0)
    }, 200)

    onMounted(async () => {
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keyup', handleKeyUp);
      await nextTick()
      if (DragBox.value) {
        dragBoxHeight.value = DragBox.value.offsetHeight - 30;
      }
      await store.commit("sidebar/setTargetFileList", [])
      await store.commit("sidebar/setPageSetting", {type: 'pageNo', value: 1})
      await store.dispatch("system/getTargetHeight", {userId: userInfo.value.id})
      dragControllerUD();
      await store.dispatch("sidebar/getEmailGroupFileListApis", {teamId: teamId.value, userId: userInfo.value.id})
      // elementTop.value = tableHeight.value
      await store.dispatch('customer/getAreaNames')
      await store.dispatch("cusList/getMailCustomerLevel");
      await getAllUserList();
      if (props.group) {
        selectedFolder.value = targetFileList.value.length > 1 ? targetFileList.value[1].id : null;
      }
      window.addEventListener('resize', adjustContentPosition);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', adjustContentPosition);
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    });


    const close = () => {
      dialogVisible.value = false;
    }
    const closeCreated = () => {
      dialogVisibleCreatedMessage.value = false;
    }

    // 上下拖动事件
    const topBoxValue = ref(0);
    const downBoxValue = ref(0)
    // 移动
    const moveTarget = ref(null);
    const scrollBox = ref(null);
    const isDragging = ref(false);
    const initialMouseY = ref(0)
    const elementLeft = ref(0)
    const DragBox = ref()
    const dragBoxHeight = ref(0)
    const inboxTop = computed(() => {
      return topBoxValue.value !== 0 ? topBoxValue.value : store.state.system.inboxTop > dragBoxHeight.value ? dragBoxHeight.value : store.state.system.inboxTop
    })
    const inboxHeight = computed(() => store.state.system.inboxHeight)
    const elementTopRaw = ref(inboxTop.value);
    const elementTop = computed({
      get() {
        return elementTopRaw.value;
      },
      set(newValue) {
        elementTopRaw.value = newValue;
      },
    });

    const pageframeHeightRaw = ref(deviceFlag.value === 'mac' ? inboxHeight.value : inboxHeight.value + inboxHeight.value * 0.4);
    const pageframeHeight = computed({
      get() {
        return pageframeHeightRaw.value;
      },
      set(newValue) {
        pageframeHeightRaw.value = newValue;
      },
    });


    const saveTargetHeight = debouncePlus((userId, pageframeHeight, elementTop) => {
      store.dispatch("system/settingTargetHeight", {
        userId,
        inboxHeight: pageframeHeight,
        inboxTop: elementTop
      });
    }, 200); // 300ms 防抖延迟

    const sortObj = ref({})

    function sort_change({column, prop, order}) {
      console.log('ffff', column, prop, order)
      if (order != null) {
        const flag = order === 'descending' ? 2 : 1;
        // 映射排序字段到相应的请求参数
        const sortMapping = {
          isStress: 'orderStress',
          isRead: 'orderRead',
          fj: 'orderExtend',
          date: 'orderTime',
          contentSize: 'orderSize'
        };

        // 获取相应的请求参数
        const requestParam = sortMapping[prop];
        sortObj.value = {[requestParam]: flag};
        console.log(sortObj.value, 'sortObj')
        if (requestParam) {
          getMailList({[requestParam]: flag});
        }
      } else {
        getMailList()
      }
    }

    // 表格内容
    const rowClassName = ({row, column, rowIndex, columnIndex}) => {
      // // console.log(row, columnIndex)
      if (props.type === 1) {
        if (columnIndex === 0 || columnIndex === 1 || columnIndex === 3 || columnIndex === 4 || columnIndex === 5 || columnIndex === 6 || columnIndex === 9) {
          return {textAlign: 'left'}
        } else {
          return {textAlign: 'center'}
        }
      } else if (props.type === 3) {
        if (columnIndex === 0 || columnIndex === 3 || columnIndex === 4 || columnIndex === 5 || columnIndex === 6 || columnIndex === 12) {
          return {textAlign: 'left'}
        } else {
          return {textAlign: 'center'}
        }
      } else if (props.type === 2) {
        if (columnIndex === 0 || columnIndex === 3 || columnIndex === 4 || columnIndex === 5 || columnIndex === 10) {
          return {textAlign: 'left'}
        } else {
          return {textAlign: 'center'}
        }
      } else if (props.type === 4) {
        if (columnIndex === 0 || columnIndex === 3 || columnIndex === 4 || columnIndex === 5 || columnIndex === 9) {
          return {textAlign: 'left'}
        } else {
          return {textAlign: 'center'}
        }
      }
    }

    // 表头
    const headerClassName = ({row, column, rowIndex, columnIndex}) => {
      if (props.type === 1) {
        if (columnIndex === 0 || columnIndex === 1 || columnIndex === 3 || columnIndex === 4 || columnIndex === 5 || columnIndex === 6 || columnIndex === 9) {
          return {'text-align': 'left',}
        } else {
          return {'text-align': 'center'}
        }
      } else if (props.type === 3) {
        if (columnIndex === 0 || columnIndex === 3 || columnIndex === 4 || columnIndex === 5 || columnIndex === 6 || columnIndex === 12) {
          return {'text-align': 'left',}
        } else {
          return {'text-align': 'center'}
        }
      } else if (props.type === 2) {
        if (columnIndex === 0 || columnIndex === 3 || columnIndex === 4 || columnIndex === 5 || columnIndex === 10) {
          return {'text-align': 'left',}
        } else {
          return {'text-align': 'center'}
        }
      } else if (props.type === 4) {
        if (columnIndex === 0 || columnIndex === 3 || columnIndex === 4 || columnIndex === 5 || columnIndex === 9) {
          return {'text-align': 'left',}
        } else {
          return {'text-align': 'center'}
        }
      }
    }


    const refMap = ref([])
    const tempRef = ref(null)
    const visiblePopover = ref(false)
    const popoverType = ref(0)
    const emailListCheckoutTarget = ref({remark: ''})
    const handleRef = debouncePlus((ref, item, type) => {
      tempRef.value = ref
      popoverType.value = type
      visiblePopover.value = true;
      emailListCheckoutTarget.value = item;
    }, POPOVER_DELAY_TIME)

    const hidePopoverTimeoutModel = ref(false)
    // 这里是开始点
    const mouseEnters = debouncePlus((row) => {
      // 特殊处理 不然第一次 修改后 后续触发有问题
      popoverType.value = 0
      emailListCheckoutTarget.value = row;
    }, 200)

    const mouseLeaves = () => {
      hidePopoverTimeoutModel.value = setTimeout(() => {
        visiblePopover.value = false;
      }, 300);
    }
    const keepPopoverVisibleModel = () => {
      if (hidePopoverTimeoutModel.value) {
        clearTimeout(hidePopoverTimeoutModel.value);
        hidePopoverTimeoutModel.value = null;
      }
      visiblePopover.value = true;
    }

    const hidePopoverModel = () => {
      visiblePopover.value = false;
    }

    const cancelPopover = () => {
      visiblePopover.value = false;
      emailListCheckoutTarget.value.remark = ''
    }

    // 搜索条件重制
    const resetSearchFlags = () => {
      isClear.value = true;
      searchCount.value = "";
      configId.value = "all"
    }

    const handleEditMail = (row) => {
      const query = {
        id: row.id,
        flag: row.flag,
        type: "editMail",
        mailType: props.type,
      };
      if (row.flag === 0 && row.customerId) {
        query.customerId = row.customerId;
      }
      router.push({
        path: '/email/write_mail',
        query,
      });
    }


    const computedTargetWidth = computed(() => {
      if (props.type !== 4) {
        const res = document.getElementsByClassName(".el-button")
        console.log(res, 'res')
      } else {

      }
    })


    const visibleTo = ref(false)
    const visibleCC = ref(false)

    const hidePopoverTimeout = ref(false)

    function showPopover(string = '', flag) {
      if (moveTarget.value) {
        const itemWidth = Math.round((moveTarget.value.clientWidth - 40) * (1 / 3))
        const stringWidth = string.pxWidth('normal 12px PingFangSC, PingFang SC')
        console.log(itemWidth, stringWidth)
        if (stringWidth + 10 > itemWidth) {
          if (flag === "to") {
            visibleCC.value = false;
            visibleTo.value = true;
          } else if (flag === "cc") {
            visibleTo.value = false;
            visibleCC.value = true;
          }
          if (hidePopoverTimeout.value) {
            clearTimeout(hidePopoverTimeout.value);
            hidePopoverTimeout.value = null;
          }
        }
      }
    }

    function delayedHidePopover(flag) {
      hidePopoverTimeout.value = setTimeout(() => {
        flag === "to" ? visibleTo.value = false : visibleCC.value = false;
      }, 200); // Adjust delay as necessary
    }

    function keepPopoverVisible(flag) {
      if (hidePopoverTimeout.value) {
        clearTimeout(hidePopoverTimeout.value);
        hidePopoverTimeout.value = null;
      }
      flag === "to" ? visibleTo.value = true : visibleCC.value = true;
    }

    function hidePopover(flag) {
      flag === "to" ? visibleTo.value = false : visibleCC.value = false;
    }

    // 弹窗进行编辑备注
    const visitableRemark = ref(false)
    const currentItem = ref({remark: ""})
    const handleClickRemark = (row) => {
      const {id} = row
      console.log(id, row, 'id')
      currentItem.value = row
      visitableRemark.value = true
    }
    const closeRemark = () => {
      visitableRemark.value = false
    }

    function dragControllerUD() {
      var resize = document.getElementsByClassName("move");
      var topBox = document.getElementsByClassName("topBox");
      var downBox = document.getElementsByClassName("downBox");
      var box = document.getElementsByClassName("mid");
      console.log(document.getElementsByClassName("move"), resize.length, 'resize.length');
      for (let i = 0; i < resize.length; i++) {
        // 鼠标按下事件
        resize[i].onmousedown = function (e) {
          console.log(resize[i].top);
          //颜色改变提醒
          resize[i].style.background = "#818181";
          // 100 这里是 有上面的 操作栏的高度
          var startY = e.clientY + 120;
          resize[i].top = resize[i].offsetTop;
          // 鼠标拖动事件
          document.onmousemove = function (e) {
            isDragging.value = true
            var endY = e.clientY;
            var moveLen = resize[i].top + (endY - startY); // （endY - startY）=移动的距离。resize[i].top+移动的距离=上边区域最后的高度
            var maxT = box[0].clientHeight - resize[i].offsetHeight; // 容器高度 - 上边区域的高度 = 下边区域的高度

            if (moveLen < 30) moveLen = 30; // 上边区域的最小高度为50px
            if (moveLen > maxT - 30) moveLen = maxT - 30; //下边区域最小高度为150px

            resize[i].style.top = moveLen; // 设置上边区域的高度

            for (let j = 0; j < topBox.length; j++) {
              topBox[j].style.height = moveLen + "px";
              topBoxValue.value = moveLen
              downBox[j].style.height = box[0].clientHeight - moveLen - 10 + "px";
              downBoxValue.value = box[0].clientHeight - moveLen - 10

              console.log(moveLen, box[0].clientHeight - moveLen - 10, 'mover')
              saveTargetHeight(userInfo.value.id, box[0].clientHeight - moveLen - 10, moveLen);
            }
          };
          // 鼠标松开事件
          document.onmouseup = function () {
            resize[i].style.background = "#d6d6d6";
            isDragging.value = false
            document.onmousemove = null;
            document.onmouseup = null;
            resize[i].releaseCapture && resize[i].releaseCapture();
          };

          resize[i].setCapture && resize[i].setCapture();
          return false;
        };
      }
    }

    document.onmouseup = function (e) {
      document.onmousemove = null;
      document.onmouseup = null;
    };

    // 文件夹list scroll
    const pageSetting = computed(() => store.state.sidebar.pageSetting)
    const hasMoreData = computed(() => store.getters["sidebar/hasMoreData"])
    const isLoading = ref(false)
    const handleScroll = throttle((event) => {
      const bottom = event.target.scrollHeight + 300 > event.target.scrollTop + event.target.clientHeight;
      console.log(bottom, !isLoading.value, hasMoreData.value, 'bottom && !isLoading.value && hasMoreData.value')
      if (bottom && !isLoading.value && hasMoreData.value) {
        loadMore();
      }
    }, 100)
    const loadMore = async () => {
      isLoading.value = true;
      const targetPage = pageSetting.value.pageNo + 1
      store.commit("sidebar/setPageSetting", {type: 'pageNo', value: targetPage})
      await store.dispatch("sidebar/getEmailGroupFileListApis", {teamId: teamId.value, userId: userInfo.value.id})
      isLoading.value = false; // 加载完成
    }


    return {
      DragBox,
      isLoading,
      loadMore,
      handleScroll,
      topBoxValue,
      downBoxValue,
      currentItem,
      closeRemark,
      visitableRemark,
      handleClickRemark,
      selectedFolder,
      props,
      handleGroupEmail,
      fileList,
      hidePopoverModel,
      keepPopoverVisibleModel,
      showPopover,
      delayedHidePopover, keepPopoverVisible, hidePopover,
      visibleTo,
      visibleCC,
      lang,
      computedTargetWidth,
      handleEditMail,
      popoverType,
      refMap,
      tempRef,
      detailLoading,
      cancelPopover,
      mouseLeaves,
      mouseEnters,
      emailListCheckoutTarget,
      visiblePopover,
      handleRef,
      visiblePop,
      handleResend,
      headerClassName,
      rowClassName,
      sort_change,
      getMailList,
      createdNewCustomer,
      emailsReturn,
      activeItemRow,
      close,
      closeCreated,
      deleteAll,
      pageframeHeight,
      elementLeft,
      elementTop,
      scrollBox,
      moveTarget,
      configId,
      emailList,
      tableData,
      current,
      size,
      total,
      loading,
      multipleTableRef,
      inboxRef,
      tableHeight,
      tableRef,
      drawer,
      direction,
      labelPosition,
      formLabelAlign,
      tableDataLevel,
      areaList,
      iframeContent,
      tableDataSelect,
      selectedTeamId,
      blackModalShow,
      multipleSelection,
      searchCount,
      handleCommand,
      currentMail,
      tableRowClassName,
      handleViewDetail,
      handleReply,
      handleSetRead,
      handleSearch,
      handleSearchInput,
      filterReset,
      handleDownload,
      handleCurrentChange,
      handleCurrentRowChange,
      handleSettingStress,
      handleSelectionChange,
      requestTagAllRead,
      requestDelMail,
      handleBlack,
      handleEdit,
      formatText,
      handleBlackSucc,
      handleBlackCancel,
      dialogVisible,
      dialogVisibleCreatedMessage,
      forms,
      options,
      optionsCustomers,
      contactPerson,
      contactList,
      removeEmail,
      ruleForm,
      form,
      dialogVisibleDelete,
      emptyUrl,
      route,
      handleSelectUser,
      handleSelectFolder,
      targetFileList,
      isDragging,
      inboxHeight,
      inboxTop,
      pageframeHeightRaw,
      elementTopRaw,
      deviceFlag,
    }
  }
}
</script>


<style lang="less">
.el_modal {
  .el-drawer__header {
    margin-bottom: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8e8e8;
  }
}
</style>
<style lang="less" scoped>
@import "../../Customer/less/detail.less";
@import "./index.less";

.up_row, .down_row {
  width: 100%;
  height: 2px !important;
  cursor: row-resize !important;
}

.flex {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center
}

.over_text {
  color: black !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 26px;
  width: 33.33%;
  text-align: left;
}

#pageframe {
  background: transparent;
}

iframe {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

iframe.loaded {
  opacity: 1;
}

:deep(.el-popper) {
  max-width: 45% !important;
}

.el-popover.el-popper.visible {
  max-width: 300px !important;
}

.el-popover.el-popper.sp_model_popover {
  max-width: 300px !important;
}

.visible {
  padding: 0px !important;

  & > div {
    margin: -12px !important;
    padding: 8px !important;
  }
}

.sp_model_popover {
  padding: 0px !important;

  & > div {
    margin: -12px !important;
    padding: 12px !important;
  }
}

#sp_tables {
  position: relative;

  :deep(.el-table__header) {
    width: 100% !important;
  }

  :deep(.el-table__body) {
    width: 100% !important;
  }
}

:deep(.el-scrollbar__bar.is-horizontal) {
  height: 6px !important;
}

:deep(.el-table__inner-wrapper:before) {
  background: transparent !important;
}

:deep(.sp_email .el-input__wrapper) {
  background: #f5f7fa !important;
}

:deep(.el-input__wrapper) {
  min-height: 0px !important;
}

.Drag2 {
  height: calc(100vh - 190px);
}

/*包围div样式*/
.box {
  width: 100%;
  height: 100%;
  margin: 1% 0px;
  overflow: hidden;
  box-shadow: -1px 9px 10px 3px rgba(0, 0, 0, 0.11);
}

/*左侧div样式*/
.left {
  width: calc(32% - 10px); /*左侧初始化宽度*/
  height: 100%;
  background: #71ad88;
  float: left;
}

/* 拖拽区div样式 */
.resize {
  cursor: w-resize;
  float: left;
  position: relative;
  top: 45%;
  background-color: #d6d6d6;
  border-radius: 5px;
  margin-top: -10px;
  width: 10px;
  height: 50px;
  background-size: cover;
  background-position: center;
  /*z-index: 99999;*/
  font-size: 32px;
  color: white;
}

/*拖拽区鼠标悬停样式*/
//.movers:hover {
//  color: #444444;
//}

/*右侧div'样式*/
.mid {
  float: left;
  width: 100%; /*右侧初始化宽度*/
  height: 100%;
  background: #f3f3f3;
  box-shadow: -1px 4px 5px 3px rgba(0, 0, 0, 0.11);
  /*上方div'样式*/

  .topBox {
    height: 60%;
    background-color: #3ff53f;
    display: flex;
  }

  /*下方div'样式*/

  .downBox {
    //height: calc(40% - 32px);
    background-color: white;
    display: flex;
  }

  /* 拖拽区div样式 */

  .mover {
    padding: 0px 20px;
    //cursor: s-resize;
    cursor: text;
    width: 100%;
    height: 30px;
    background-color: #d6d6d6;
    border-radius: 5px;
    text-align: center;
    line-height: 3px;
    font-size: 12px;
    user-select: text; /* 启用文本选择 */
  }

  /*拖拽区鼠标悬停样式*/

  .mover:hover {
    color: #444444;
  }
}

#pageframe {
  padding-bottom: 30px;
}

:deep(.el-checkbox__inner::after) {
  top: 1px !important;
  left: 4px !important;
}

//
.move-select {
  .dropdown-menu-wrapper {
    min-width: auto !important;
    width: auto;
    max-width: 200px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  :deep(.el-dropdown-menu__item) {
    min-width: auto !important;
    width: auto;
    max-width: 200px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inherit !important;
  }
}

.customer-level .el-select-dropdown__item {
  max-width: 380px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inner_select {
  width: auto;
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
