<template>
    <div class="message-timestamp">
        {{nick}}撤回了一条消息
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useStore } from "vuex";
import TencentCloudChat from "@tencentcloud/chat";

const props = defineProps(['messageItem']);
const store = useStore();

const userInfo = computed(() => store.state.user.userInfo)

const nick = computed(() => {
    if(props.messageItem.from === userInfo.value.id){
        return '你';
    }else if(props.messageItem.conversationType === TencentCloudChat.TYPES.CONV_C2C){
        return '对方';
    }else{
        return props.messageItem.nick;
    }
});
</script>
<style lang="less" scoped>

.message-timestamp {
    margin: 10px auto;
    color: #999;
    font-size: 12px;
    overflow-wrap: anywhere;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
</style>