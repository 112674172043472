<template>
  <div class="inbox" ref="inboxRef">
    <el-table :height="tableHeight"
              :data="tableData"
              style="width: 100%;"
              ref="TableRef"
              :cell-style="{ textAlign: 'center' }"
              v-loading="loading"
              :row-style="{height: '50px'}"
              :header-cell-style="{ 'text-align': 'center',background: '#f9f9f9', color: 'black',padding:'16px 0px'}"
    >
      <el-table-column :label="$t('common_customer_level')" prop="customerLevel">
        <template #default="{ row, column, $index }">
          <div v-if="!row.editing">
            {{ row.customerLevel }}
          </div>
          <div v-else>
            <el-select v-model="row.customerLevel" :placeholder="$t('common.please.select')" clearable
                       :class="[isErrorSelect||unAddPre?'isErrorSelect':'normal']" @change="handleChange">
              <el-option
                  v-for="item in trueCustomerLevels"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
            <p v-if="isErrorSelect||unAddPre" style="color: red;font-size: 12px;text-align: left;position: absolute">
              {{ $t('common.customer.level.empty') }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common_customer_type')" prop="customerType" :width="400">
        <template #default="{ row, column, $index }">
          <template v-if="!row.editing">
            <div :ref="ref => (refMap[`${row.id}_type_customer`] = ref)"
                 @mouseover.stop="handleRef(refMap[`${row.id}_type_customer`], row, 1)"
                 v-if="getTextWidth(row.customerType)>=390"
                 class="over_normal"
                 :key="row.customerType">
              {{ row.customerType }}
            </div>
            <div v-else>
              {{ row.customerType }}
            </div>
          </template>
          <div v-else>
            <el-input v-model.number="row.customerType" @change="handleClickInput"
                      @keydown.native.enter="handleBlur(row, column, $index)"
                      :class="[isError||unAddPre?'isErrorSelect':'normal']"></el-input>
            <p v-if="isError||unAddPre" style="color: red;font-size: 12px;text-align: left;position: absolute">
              {{ $t('common.customer.type.empty') }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.operate')">
        <template #default="{ row, column, $index }">
          <div v-if="!row.editing">
            <div class="settings">
              <el-button
                  type="primary"
                  link
                  @click="handleEdit(row, column, $index)"
              >{{ $t('common.edit') }}
              </el-button
              >
              <el-popover
                  :visible="row.popoverVisible"
                  placement="top"
                  :width="164"
                  trigger="click"
              >
                <div class="inners">
                  <el-icon>
                    <WarningFilled/>
                  </el-icon>
                  <p>{{ $t('sys.message.ask.type.delete') }}</p>
                </div>
                <div style="text-align: center; margin-top:10px">
                  <el-button
                      size="small"
                      text
                      @click="cancelPopover(row, column, $index)"
                  >{{ $t('common.cancel') }}
                  </el-button
                  >
                  <el-button
                      size="small"
                      type="primary"
                      @click="handleDelete(row, column, $index)"
                  >{{ $t('common.confirm') }}
                  </el-button
                  >
                </div>
                <template #reference>
                  <el-button
                      type="danger"
                      link
                      @click="requestHandleDelete(row, column, $index)"
                  >{{ $t('common.delete') }}
                  </el-button
                  >
                </template>
              </el-popover>
            </div>
          </div>
          <div v-else>
            <div class="settings">
              <el-button
                  type="primary"
                  link
                  @click="handleBlur(row, column, $index)"
              >{{ $t('common.save') }}
              </el-button
              >
              <el-button
                  type="danger"
                  link
                  @click="handleCancel(row, column, $index)"
              >{{ $t('common.sss16') }}
              </el-button
              >
            </div>
          </div>
        </template>
      </el-table-column>
      <template #empty>
        <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
      </template>
    </el-table>
    <div class="footer">
      <el-button
          :icon="Plus"
          type="primary"
          @click="addNewTableItem()"
      >{{ $t("common.customer.level.add") }}
      </el-button
      >
    </div>
    <!--    </el-scrollbar>-->
  </div>


  <!--  el-popover-->
  <el-popover
      v-model:visible="visiblePopover"
      :virtual-ref="tempRef"
      virtual-triggering
      :width="'max-content'"
      trigger="hover"
      :show-after="600"
      effect="dark"
  >
    <template v-if="popoverType === 1">
      {{ activeItemElpopover.customerType }}
    </template>
  </el-popover>
</template>

<script setup>
import {ref, onMounted, computed, onUpdated, onBeforeUpdate, watch, nextTick, onUnmounted} from "vue";
import {Plus} from "@element-plus/icons-vue";
import dayjs from "dayjs";
import {ElMessage, ElTable} from "element-plus";
import {useStore} from "vuex";
import {
  addMailCustomerFollowLevel,
  getMailCustomerLevelList,
  deleteMailCustomerLevel,
  editMailCustomerLevel,
} from "@/api/customer.js";
import {i18n} from "@/lang";
import {emptyUrl, POPOVER_DELAY_TIME} from "@/utils/statusParams";
import {useRouter} from "vue-router";
import sessionStorageUtil from "@/utils/sessionStorageUtil";
import {debouncePlus, throttle} from "@/utils/mylodash";
import {getTextWidth} from "@/utils/util";

const router = useRouter()
const store = useStore();
const tableData = computed(() => store.state.cusList.tableData);
const loading = ref(false)
const customerLevels = [
  {value: "A", label: "A"},
  {value: "B", label: "B"},
  {value: "C", label: "C"},
  {value: "D", label: "D"},
  {value: "E", label: "E"},
  {value: "F", label: "F"},
  {value: "G", label: "G"},
  {value: "H", label: "H"},
  {value: "I", label: "I"},
  {value: "J", label: "J"},
  {value: "K", label: "K"},
  {value: "L", label: "L"},
  {value: "M", label: "M"},
  {value: "N", label: "N"},
  {value: "O", label: "O"},
  {value: "P", label: "P"},
  {value: "Q", label: "Q"},
  {value: "R", label: "R"},
  {value: "S", label: "S"},
  {value: "T", label: "T"},
  {value: "U", label: "U"},
  {value: "V", label: "V"},
  {value: "W", label: "W"},
  {value: "X", label: "X"},
  {value: "Y", label: "Y"},
  {value: "Z", label: "Z"},
  // Add more options as needed
];


// el-popover 优化
const refMap = ref({})
const tempRef = ref()
const visiblePopover = ref(false)
// -1-字句审核 0-整句审核 1-日志查看
const popoverType = ref(0)
const activeItemElpopover = ref(null)

const handleRef = debouncePlus((ref, item, type) => {
  console.log('refMap', refMap.value)
  tempRef.value = ref
  popoverType.value = type
  activeItemElpopover.value = item;
  if (type === 1) {
    // ...业务逻辑1
  } else {
    // ...业务逻辑2、3
  }
  visiblePopover.value = true
}, POPOVER_DELAY_TIME)


const tableHeight = ref(`calc(100vh - 270px)`);

const inboxRef = ref(null);

const trueCustomerLevels = computed(() => {
  const targetObj = tableData.value.map(item => item.customerLevel)
  return customerLevels.filter(item => !targetObj.includes(item.value));
})

// 记录状态是否编辑
let isEdit = false;

const teamId = computed(() => store.state.email.teamID)
// 编辑用户
const handleEdit = async (row, column, index) => {
  if (!isEditing.value) {
    isEditing.value = true
    store.commit("cusList/changeEditing", {index, flag: true});
    isEdit = false;
  } else {
    ElMessage.warning("您当前正处于编辑中，请保存后再进行编辑！")
  }
};

const isAdd = ref(false);
// 是否新增了第一个新增项
const unAddPre = ref(false)
// 添加
const addNewTableItem = () => {
  if (!isEditing.value) {
    if (isAdd.value) {
      unAddPre.value = true
      return
    }
    isAdd.value = true;
    isEdit = true;
    tableData.value.push({
      "customerType": "",
      "alertDay": 0,
      "customerLevel": "",
      "editing": true
    });
    nextTick(() => {
      scrollBehavior()
    })
  } else {
    ElMessage.warning("您当前正处于编辑中，请保存后再进行添加！")
  }
};

// table的虚拟dom
const TableRef = ref()

// 滚动行为
function scrollBehavior(e) {
  // 获取提供实际滚动的容器
  const dom = TableRef.value.$refs.bodyWrapper.getElementsByClassName('el-scrollbar__wrap')[0]
  //console.log(dom, 'dom')
  const {clientHeight, scrollTop, scrollHeight} = dom
  // 父容器高度 + 子容器距离父容器顶端的高度 = 子容器可滚动的高度
  if (clientHeight + scrollTop !== scrollHeight) {
    // //console.log('竖向滚动条已经滚动到底部')
    dom.scrollTop = dom.scrollHeight
  }
}

const isEditing = ref(false)
const isError = ref(false)
const isErrorSelect = ref(false)

const handleClickInput = (value) => {
  if (value) {
    isError.value = false
  } else {
    isError.value = true
  }
}

const handleChange = (value) => {
  if (value) {
    isErrorSelect.value = false
  } else {
    isErrorSelect.value = true
  }
}

//保存
const handleBlur = async (row, column, index) => {
  isEditing.value = false
  const {customerLevel, customerType, id} = row;
  const targetParams = {
    alertDay: !isEdit ? -1 : 0,
    createBy: "",
    createTime: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    customerLevel,
    customerType,
    delFlag: 0,
    id,
    remark: "",
    teamId: teamId.value,
    updateBy: "",
    updateTime: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  };
  console.log(customerType, customerLevel, 'dddddddddd')
  if (!`${customerType}` || customerType === undefined) {
    isError.value = true
  }
  if (!`${customerLevel}` || customerLevel === undefined) {
    isErrorSelect.value = true
  }
  if (isError.value || isErrorSelect.value) {
    return
  }
  isError.value = false
  isErrorSelect.value = false
  if (isEdit) {
    await store.dispatch("cusList/addCustomerData", targetParams);
    //console.log('fff@@@')
    isEdit = false;

  } else {
    await store.dispatch("cusList/editCustomerData", targetParams);
    isEdit = true;
  }
  isAdd.value = false;
  unAddPre.value = false
  store.commit("cusList/changeEditing", {index, flag: false});
  await store.dispatch("cusList/getMailCustomerLevel", {isUpdate: true});
};

// 取消
const handleCancel = async (row, column, index) => {
  isAdd.value = false;
  unAddPre.value = false;
  isError.value = false;
  isErrorSelect.value = false;
  isEditing.value = false;
  store.commit("cusList/changeEditing", {index, flag: false});
  await store.dispatch("cusList/getMailCustomerLevel", {isUpdate: true});
};

// 删除
const handleDelete = async (row, column, index) => {
  const {id} = row;
  let res = await deleteMailCustomerLevel({id: id});
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return;
  }
  ElMessage.success(res.result);
  row.popoverVisible = false;
  await store.dispatch("cusList/getMailCustomerLevel", {isUpdate: true});
};

const requestHandleDelete = (row) => {
  row.popoverVisible = true;
};

const cancelPopover = async (row) => {
  row.popoverVisible = false;
  await store.dispatch("cusList/getMailCustomerLevel");
};

onMounted(async () => {
  loading.value = true;
  try {
    await store.dispatch("cusList/getMailCustomerLevel");
  } catch (error) {
    console.error("Failed to fetch customer levels:", error);
  } finally {
    loading.value = false;
  }
})

</script>
<style scoped lang="less">
.settings {
  display: flex;
  justify-content: center;
}

.inners {
  display: flex;
  justify-content: center;
  align-items: center;

  & > .el-icon {
    color: #faad14;
    margin-right: 2px;
  }
}

:deep(.el-table tr) {
  background: white !important;
}

.footer {
  height: 65px;
  background: #fafafa;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > .el-button {
    margin-left: 20px;
  }
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

.inbox {
  background-color: #fff;
  height: 100%;
}

:deep(.el-table .el-table__cell) {
  padding: 20px 0px !important;
}

.isErrorSelect {
  border: 1px solid red;
  border-radius: 4px;
  transition: border .2s;
}

.normal {
  border: 1px solid transparent;
  border-radius: 4px;
  transition: border .2s;
}
</style>
