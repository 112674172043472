<template>
  <ElDialog
      v-model="isShow"
      :title="$t('import.group.list')"
      :footer="null"
      centered
      top="6em"
      :width="'40%'"
      style="min-width:350px"
      destroyOnClose
      @cancel="close"
  >
    <el-scrollbar height="300">
      <div style="margin-top: 20px">
        <div v-for="item in showTargetEmails">
          <p>
            <span>{{ $t('common.email.ContactName') }}：</span><span>{{ item.name }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
            <span>{{ $t('common.contact.email') }}：</span><span>{{ item.email }}</span>
          </p>
        </div>
      </div>
    </el-scrollbar>
    <div class="settings">
      <div>{{ $t('count.data', {count: showTargetEmails.length}) }}</div>
      <div>
        <ElButton @click="close" :loading="loading">
          {{ $t('common.sss16') }}
        </ElButton>
        <ElButton type="primary" @click="addToEmails" :loading="loading">
          {{ $t('common.confirm') }}
        </ElButton>
      </div>
    </div>
  </ElDialog>
</template>

<script setup>
import {ElButton, ElDialog, ElMessage} from "element-plus";
import {ref, defineProps, watch, defineEmits, computed} from 'vue'
import {useStore} from "vuex";
import * as XLSX from 'xlsx/xlsx.mjs';
import {i18n} from "@/lang";

const store = useStore();
const emit = defineEmits()
const isShow = ref(false);
const teamName = ref("")
const loading = ref(false);

const {value, isChecked, showTargetEmails} = defineProps({
  value: {type: Boolean, default: false},
  isChecked: {type: Array, default: []},
  showTargetEmails: {type: Array, default: []}
})

watch(() => value, (val) => {
  isShow.value = val;
}, {immediate: true})

const teamId = computed(() => store.state.email.teamID);
const isLoading = ref(false);


//收集邮箱并添加
const addToEmails = () => {
  if (showTargetEmails.length !== 0) {
    emit('addTo', showTargetEmails)
  } else {
    ElMessage.warning(i18n.global.t('import.excel.info'))
  }
}

const close = () => {
  emit("close");
}
</script>
<style scoped lang="less">
@import "../../less/page.less";

.fx-j-c {
  display: flex;
  justify-content: center;
}

.settings {
  margin-top: 40px;
  display: flex;
  margin-left: -16px;
  justify-content: space-between;
  align-items: center;
}

:deep(.el-dialog__header) {
  margin-left: 12px !important;
}
</style>
