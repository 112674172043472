<template>
  <div class="wrap">
    <div class="exam" ref="inboxRef">
      <div class="screen">
        <div class="btn_w">
          <ElButton size="small" :loading="btnIsLoadingResolve" type="primary" @click="() => handlePass(1)">
            {{ $t('email.list.examine') }}
          </ElButton>
          <ElButton size="small" :loading="btnIsLoadingReject" @click="() => handlePass(2)">
            {{ $t('email.list.examine.reject') }}
          </ElButton>
        </div>
        <el-pagination
            v-model:current-page="current"
            v-model:page-size="size"
            small="small"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
      </div>
      <!--      新的移动模块-->
      <div class="Drag2" ref="DragBox">
        <div class="box" ref="box">
          <!--      <div class="left">-->
          <!--        &lt;!&ndash;左侧div内容&ndash;&gt;-->
          <!--      </div>-->
          <!--      <div class="resize" title="左右侧边栏">⋮</div>-->
          <div class="mid">
            <!--右侧div内容-->
            <div class="topBox" :style="{height:fileInboxTop+'px'}">
              <!--右上div内容-->
              <ElTable v-loading="loading" :height="'100%'" :data="list" @selection-change="handleSelectionChange"
                       size="small" show-overflow-tooltip
                       highlight-current-row @current-change="handleCurrentRowChange"
                       :cell-style="rowClassName"
                       :header-cell-style="headerClassName">
                <el-table-column type="selection" width="20"/>
                <el-table-column width="30">
                  <template #header>
                    <img src="@/assets/email/fj.svg"/>
                  </template>
                  <template #default="scope">
                    <img v-if="scope.isExtend === 1" class="pointer" src="@/assets/email/fj.svg"/>
                  </template>
                </el-table-column>
                <el-table-column prop="mailRecipientList" :label="$t('common.email.to')">
                  <template #default="scope">
                    <el-popover
                        v-if="(scope.row.customerName != null || scope.row.contactName != null)"
                        placement="right"
                        :width="'max-content'"
                        effect="dark"
                        trigger="hover"
                        show-after="500"
                        :content="scope.row.contactEmail!=null?scope.row.contactEmail:scope.row.customerName+'('+scope.row.companyEmail+')'"
                    >
                      <template #reference>
                        <router-link :to="'/email/customer/detail/' + scope.row.customerId + '?type=1'"
                        >
                          <span style="color: #0087ff;"> {{ scope.row.customerName }}</span>
                        </router-link>
                      </template>
                    </el-popover>
                    <template v-else>
                      <template v-if="scope.row.mailRecipientList.length>1?scope.row.mailRecipientList.length>1:false">
                        <el-popover
                            placement="right"
                            :width="'max-content'"
                            effect="dark"
                            trigger="hover"
                            :show-after="500"
                            :content="scope.row.mailRecipientList.join(' , ')"
                        >
                          <template #reference>
                            <span style="overflow: hidden">{{ `${scope.row.mailRecipientList[0]} ...` }}</span>
                          </template>
                        </el-popover>
                      </template>
                      <template v-else>
                        {{ scope.row.customerName ? scope.row.customerName : scope.row.mailRecipientList[0] }}
                      </template>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column prop="subject" :label="$t('common.subject')"/>
                <el-table-column prop="createTime" :label="$t('common.date')" width="160"/>
                <el-table-column prop="userName" :label="$t('common.staff')" width="160"/>
                <el-table-column fixed="right" :label="$t('common.cz')" width="120">
                  <template #default="scope">
                    <el-button link type="primary" size="small"
                               @click="() => handleSingle(scope.row, 1)"
                    >{{ $t('common.pass') }}
                    </el-button
                    >
                    <el-button link type="danger" size="small"
                               @click="() => handleSingle(scope.row, 2)">
                      {{ $t('common.reject') }}
                    </el-button>
                  </template>
                </el-table-column>
                <template #empty>
                  <el-empty :description="$t('common.data.empty')" :image="emptyUrl"/>
                </template>
              </ElTable>
            </div>
            <div title="移动到边界进行位置调整" class="mover">
              <div class="mail_info" ref="moveTarget">
                <div class="up_row move"></div>
                <div style="width: 100%;height: 26px;display: flex">
                  <div class="from over_text">
                    {{ $t('common.email.from') }}：{{ currentMail.mailBody?.from }}
                  </div>
                  <div class="to over_text" ref="toRef">
                    <el-popover
                        v-if="formatText(currentMail.mailRecipientVOList, 'to')"
                        :visible="visibleTo"
                        width="500"
                        :content="formatText(currentMail.mailRecipientVOList, 'to')"
                        :effect="'dark'"
                        popper-class="visible"
                    >
                      <template #reference>
                    <span @mouseenter="showPopover(formatText(currentMail.mailRecipientVOList, 'to'),'to')"
                          @mouseleave="delayedHidePopover('to')">{{
                        $t('common.email.to')
                      }}：{{ formatText(currentMail.mailRecipientVOList, 'to') }}</span>
                      </template>
                      <div @mouseenter="keepPopoverVisible('to')" @mouseleave="hidePopover('to')">
                        {{ formatText(currentMail.mailRecipientVOList, 'to') }}
                      </div>
                    </el-popover>
                    <template v-else>
                      {{ $t('common.email.to') }}：{{ formatText(currentMail.mailRecipientVOList, 'to') }}
                    </template>
                  </div>
                  <div class="cc over_text">
                    <el-popover
                        v-if="formatText(currentMail.mailRecipientVOList, 'cc')"
                        :visible="visibleCC"
                        width="500"
                        :content="formatText(currentMail.mailRecipientVOList, 'cc')"
                        :effect="'dark'"
                        popper-class="visible"
                    >
                      <template #reference>
                <span
                    @mouseenter="showPopover(formatText(currentMail.mailRecipientVOList, 'cc'),'cc')"
                    @mouseleave="delayedHidePopover('cc')"
                >
                {{ $t('common.cc') }}：{{ formatText(currentMail.mailRecipientVOList, 'cc') }}
                </span>
                      </template>
                      <div @mouseenter="keepPopoverVisible('cc')" @mouseleave="hidePopover('cc')">
                        {{ formatText(currentMail.mailRecipientVOList, 'cc') }}
                      </div>
                    </el-popover>
                    <template v-else>
                      {{ $t('common.cc') }}：{{ formatText(currentMail.mailRecipientVOList, 'cc') }}
                    </template>
                  </div>
                </div>
                <div class="down_row move"></div>
              </div>
            </div>
            <div class="downBox" :style="{height:`calc(100% - ${fileInboxTop + 10}px)`}">
              <!--右下div内容-->
              <!--              <iframe ref="pageframe" id="pageframe"-->
              <!--                      :srcdoc="iframeContent"-->
              <!--                      frameborder="0"-->
              <!--                      :style="{width: '100%',height: '100%'}"-->
              <!--                      :class="detailLoading?'scroll':'scroll loaded'"-->
              <!--              >-->
              <!--                <p>Your browser does not support preview features, please download Google browser or other modern-->
              <!--                  browsers.</p>-->
              <!--              </iframe>-->
              <div v-loading="detailLoading" style="width: 100%;height: 100%">
                <iframe ref="pageframe" id="pageframe" :srcdoc="iframeContent"
                        frameborder="0"
                        :style="{width: '100%',height: '100%'}"
                        :class="detailLoading?'scroll':'scroll loaded'"
                >
                  <p>Your browser does not support preview features, please download Google browser or other modern
                    browsers.</p>
                </iframe>
                <div v-if="isDragging" id="mask"
                     :style="{width: '100%',height:`calc(100% - ${fileInboxTop + 10}px)`,background:'rgba(0,0,0,0)',position:'absolute',zIndex:999,top:fileInboxTop+'px'}">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {onMounted, ref, computed, watch, onBeforeUnmount, nextTick} from 'vue';
import {useStore} from 'vuex';
import {ElMessage} from 'element-plus';
import {requestAuditMailList, requestBatchAuditEmail} from '@/api/mail';
import {getMailDetails} from '../../api/index';
import {emptyUrl, TIME_OUT} from "@/utils/statusParams";
import {useRoute, useRouter} from "vue-router";
import {debouncePlus, throttle} from "@/utils/mylodash"
import {addImgOnErrorAttribute, convertLinksToHTML, convertToHTML} from "@/utils/iframe";
import {getScaleValue} from "@/utils/view";

export default {
  setup() {
    const store = useStore();
    const teamId = computed(() => store.state.email.teamID);
    const route = useRoute()
    const current = ref(1);
    const size = ref(10);
    const total = ref(0);
    const list = ref([]);
    const loading = ref(false);
    const currentMail = ref({});
    const multipleSelection = ref([]);
    const iframeContent = ref('');
    const tableHeight = ref(200);
    const inboxRef = ref(null);
    const router = useRouter()
    const dragBoxHeight = ref(0)
    const DragBox = ref(null)

    function handleSizeChange() {
    }

    function handleCurrentChange(val) {
      current.value = val
      getList()
    }

    const detailLoading = ref(false)

    async function handleCurrentRowChange(current) {
      console.log(current, 'ff');
      if (current) {
        detailLoading.value = true
        try {
          const result = await getMailDetails({id: current.id});
          let content = result.result?.mailBody?.content;
          detailLoading.value = false
          const insertionContent = `<script>
            function errorImage(img) {
                console.log(img,'img')
                img.onerror = null;
                img.src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR42mP4//8/AAX+Av4zEpUUAAAAAElFTkSuQmCC"
            }
        <\/script>`;
          if (!content) {
            iframeContent.value = ''
          } else {
            if (content.indexOf('html') !== -1 || content.indexOf('<div>') !== -1) {
              const replacedHtmlString = addImgOnErrorAttribute(content)
              console.log('content', replacedHtmlString);
              const res = replacedHtmlString.replace(/(<html[^>]*>)/, '$1' + insertionContent);
              iframeContent.value = res;
            } else {
              // 纯图片需要直接展示
              if (content.indexOf('https://s3gw.cmbimg.com') !== -1) {
                iframeContent.value = content;
              } else {
                iframeContent.value = convertLinksToHTML(convertToHTML(content))
              }
            }
          }
          currentMail.value = result.result;
        } catch (e) {
          detailLoading.value = false
        }
      }
    }

    function handleSelectionChange(val) {
      multipleSelection.value = val;
    }

    async function getList() {
      loading.value = true;
      const res = await requestAuditMailList({pageNo: current.value, pageSize: size.value, teamId: teamId.value});
      loading.value = false;
      if (res.code !== 200) {
        ElMessage.error(res.message);
        return;
      }
      list.value = res.result?.records || [];
      total.value = res.result?.total;
    }

    async function batchAuditEmail(type) {
      console.log(multipleSelection.value[0].id, 'multipleSelection.value')
      if (type === 1) {
        btnIsLoadingResolve.value = true
      } else {
        btnIsLoadingReject.value = true
      }
      const result = await requestBatchAuditEmail({
        auditStatus: type,
        idList: multipleSelection.value.map(item => item.id)
      });
      if (type === 1) {
        btnIsLoadingResolve.value = false
      } else {
        btnIsLoadingReject.value = false
      }
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      ElMessage.success(result.message)
      getList();
      // 更新标志
      await store.dispatch('countshow/getTeamAllDataApis')
      console.log('审核邮件', result);
    }

    function handlePass(type) {
      if (!multipleSelection.value.length) {
        ElMessage.error(i18n.global.t('sys.tip.unselect.email'));
        return;
      }
      batchAuditEmail(type);
    }

    const btnIsLoadingResolve = ref(false)
    const btnIsLoadingReject = ref(false)
    const handleSingle = throttle((row, type) => {
      multipleSelection.value = [row];
      console.log(multipleSelection.value, '000000')
      batchAuditEmail(type);
    }, TIME_OUT)

    function formatText(list, type) {
      console.log(list, type);
      if (!list || !list.length) {
        return '';
      }
      const infoMap = list.reduce((prev, item) => {
        if (!prev[item.mailType]) {
          prev[item.mailType] = [];
        }
        prev[item.mailType].push(item.mail);
        return prev;
      }, {});
      return infoMap[type] ? infoMap[type].join(',') : '';
    }

    watch(() => teamId.value, () => {
      console.log('组件团队ID 监听', teamId.value);
      getList();
    });

    // 页面进行缩放
    const adjustContentPosition = debouncePlus(() => {
      console.log('resize')
      localStorage.setItem("resize", true)
      router.go(0)
    }, 200)

    onMounted(async () => {
      await nextTick()
      if (DragBox.value) {
        dragBoxHeight.value = DragBox.value.offsetHeight - 30;
      }
      await store.dispatch("system/getTargetHeight", {userId: userInfo.value.id})
      dragControllerUD();
      if (teamId.value) {
        getList();
      }
      window.addEventListener('resize', adjustContentPosition);
    })

    const userInfo = computed(() => store.state.user.userInfo)

    // 上下拖动事件
    const topBoxValue = ref(0);
    const downBoxValue = ref(0)
    // 移动
    const moveTarget = ref(null);
    const scrollBox = ref(null);
    const isDragging = ref(false);
    const initialMouseY = ref(0)
    const elementLeft = ref(0)
    const draggableHeight = ref(30);
    const fileInboxHeight = computed(() => store.state.system.fileInboxHeight)
    const fileInboxTop = computed(() => {
      return topBoxValue.value !== 0 ? topBoxValue.value : store.state.system.fileInboxTop > dragBoxHeight.value ? dragBoxHeight.value : store.state.system.fileInboxTop
    })
    const elementTopRaw = ref(fileInboxTop.value);
    const elementTop = computed({
      get() {
        return elementTopRaw.value;
      },
      set(newValue) {
        elementTopRaw.value = newValue;
      },
    });

    const pageframeHeightRaw = ref(fileInboxHeight.value);
    const pageframeHeight = computed({
      get() {
        return pageframeHeightRaw.value;
      },
      set(newValue) {
        pageframeHeightRaw.value = newValue;
      },
    });

    function startDrag(e) {
      e = e || window.event;
      isDragging.value = true;
      initialMouseY.value = e.clientY;
    }

    const saveTargetHeight = debouncePlus((userId, pageframeHeight, elementTop) => {
      store.dispatch("system/settingTargetHeight", {
        userId,
        fileInboxHeight: pageframeHeight,
        fileInboxTop: elementTop
      });
    }, 200); // 300ms 防抖延迟
    function stopDragEx() {
      // 结束拖拽
      isDragging.value = false;
    }


    const rowClassName = ({row, column, rowIndex, columnIndex}) => {
      console.log(row, columnIndex, '333')
      if (columnIndex === 4 || columnIndex === 5) {
        return {textAlign: 'center'}
      } else {
        return {textAlign: 'left'}
      }
    }

    const headerClassName = ({row, column, rowIndex, columnIndex}) => {
      if (columnIndex === 4 || columnIndex === 5) {
        return {'text-align': 'center'}
      } else {
        return {'text-align': 'left',}
      }
    }


    const sidebarTargetNumber = computed(() => store.state.countshow.sidebarTargetNumber)
    watch(() => sidebarTargetNumber.value, async () => {
      await getList();
    })

    const visibleTo = ref(false)
    const visibleCC = ref(false)

    const hidePopoverTimeout = ref(false)

    function showPopover(string = '', flag) {

      if (moveTarget.value) {
        const itemWidth = Math.round((moveTarget.value.clientWidth - 40) * (1 / 3))
        const stringWidth = string.pxWidth('normal 12px PingFangSC, PingFang SC')
        console.log(itemWidth, stringWidth)
        if (stringWidth + 20 > itemWidth) {
          if (flag === "to") {
            visibleCC.value = false;
            visibleTo.value = true;
          } else if (flag === "cc") {
            visibleTo.value = false;
            visibleCC.value = true;
          }
          if (hidePopoverTimeout.value) {
            clearTimeout(hidePopoverTimeout.value);
            hidePopoverTimeout.value = null;
          }
        }
      }
    }

    function delayedHidePopover(flag) {
      hidePopoverTimeout.value = setTimeout(() => {
        flag === "to" ? visibleTo.value = false : visibleCC.value = false;
      }, 200); // Adjust delay as necessary
    }

    function keepPopoverVisible(flag) {
      if (hidePopoverTimeout.value) {
        clearTimeout(hidePopoverTimeout.value);
        hidePopoverTimeout.value = null;
      }
      flag === "to" ? visibleTo.value = true : visibleCC.value = true;
    }

    function hidePopover(flag) {
      flag === "to" ? visibleTo.value = false : visibleCC.value = false;
    }


    function dragControllerUD() {
      var resize = document.getElementsByClassName("move");
      var topBox = document.getElementsByClassName("topBox");
      var downBox = document.getElementsByClassName("downBox");
      var box = document.getElementsByClassName("mid");
      console.log(document.getElementsByClassName("move"), resize.length, 'resize.length');
      for (let i = 0; i < resize.length; i++) {
        // 鼠标按下事件
        resize[i].onmousedown = function (e) {
          console.log(resize[i].top);
          //颜色改变提醒
          resize[i].style.background = "#818181";
          // 100 这里是 有上面的 操作栏的高度
          var startY = e.clientY + 60;
          resize[i].top = resize[i].offsetTop;
          // 鼠标拖动事件
          document.onmousemove = function (e) {
            isDragging.value = true
            var endY = e.clientY;
            var moveLen = resize[i].top + (endY - startY); // （endY - startY）=移动的距离。resize[i].top+移动的距离=上边区域最后的高度
            var maxT = box[0].clientHeight - resize[i].offsetHeight; // 容器高度 - 上边区域的高度 = 下边区域的高度

            if (moveLen < 30) moveLen = 30; // 上边区域的最小高度为50px
            if (moveLen > maxT - 30) moveLen = maxT - 30; //下边区域最小高度为150px

            resize[i].style.top = moveLen; // 设置上边区域的高度

            for (let j = 0; j < topBox.length; j++) {
              topBox[j].style.height = moveLen + "px";
              topBoxValue.value = moveLen
              downBox[j].style.height = box[0].clientHeight - moveLen - 10 + "px";
              downBoxValue.value = box[0].clientHeight - moveLen - 10
              saveTargetHeight(userInfo.value.id, box[0].clientHeight - moveLen - 10, moveLen);
            }
          };
          // 鼠标松开事件
          document.onmouseup = function () {
            //颜色恢复
            isDragging.value = false
            resize[i].style.background = "#d6d6d6";
            document.onmousemove = null;
            document.onmouseup = null;
            resize[i].releaseCapture && resize[i].releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
          };
          resize[i].setCapture && resize[i].setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
          return false;
        };
      }
    }

    return {
      DragBox,
      fileInboxTop,
      dragControllerUD,
      showPopover,
      delayedHidePopover, keepPopoverVisible, hidePopover,
      detailLoading,
      visibleTo,
      visibleCC,
      rowClassName,
      headerClassName,
      scrollBox,
      initialMouseY,
      draggableHeight,
      moveTarget,
      pageframeHeight,
      elementLeft,
      elementTop,
      startDrag,
      current,
      size,
      total,
      list,
      loading,
      currentMail,
      iframeContent,
      tableHeight,
      inboxRef,
      formatText,
      handlePass,
      handleSingle,
      handleCurrentChange,
      handleSizeChange,
      handleSelectionChange,
      handleCurrentRowChange,
      emptyUrl,
      btnIsLoadingResolve,
      btnIsLoadingReject,
      isDragging
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  background-color: rgb(245, 245, 245);
  padding: 16px;
  height: -webkit-fill-available !important;
  //overflow: hidden;

  :deep(.el-form-item) {
    margin: 20px 0 0;
  }

  .exam {
    background-color: #fff;
    height: 100%;

    .screen {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      padding-bottom: 10px;
      background: #f3f3f3;
    }

    .current_mail {

      //padding: 0px 20px;

      .mail_info {
        display: flex;
        flex-direction: column;
        height: 30px;
        align-items: center;
        background: rgb(229, 229, 229);
        //user-select: text;
        cursor: n-resize;
        transform: translateZ(0);
        will-change: transform;
        font-family: "PingFangSC, PingFang SC";

        & > div:nth-of-type(2) {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 0px 20px;
          height: 26px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
          cursor: text !important;

          & > div {
            width: 33.33%;
            min-width: 33.33% !important;
            max-width: 33.33% !important;
          }
        }
      }
    }
  }

  :deep(.el-empty__image img) {
    width: 54% !important;
  }

  :deep(.el-empty__description) {
    margin-top: -20px;
  }

  .scroll_out_box {
    position: relative;
  }

  .mail_info {
    width: 100%;
    height: 26px;
  }

  #pageframe {
    background: white;
  }

  .from {
    padding-left: 20px;
  }
}

.up_row, .down_row {
  width: 100%;
  height: 2px !important;
  cursor: row-resize !important;
}

.over_text {
  color: black !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 26px;
  width: 33.33%;
  text-align: left;
}

iframe {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

iframe.loaded {
  opacity: 1;
}

:deep(.el-popper) {
  max-width: 45% !important;
}

.visible {
  padding: 0px !important;

  & > div {
    margin: -12px !important;
    padding: 8px !important;
  }
}


.Drag2 {
  height: calc(100vh - 143px);
}

/*包围div样式*/
.box {
  width: 100%;
  height: 100%;
  margin: 1% 0px;
  overflow: hidden;
  box-shadow: -1px 9px 10px 3px rgba(0, 0, 0, 0.11);
}

/*左侧div样式*/
.left {
  width: calc(32% - 10px); /*左侧初始化宽度*/
  height: 100%;
  background: #71ad88;
  float: left;
}

/* 拖拽区div样式 */
.resize {
  cursor: w-resize;
  float: left;
  position: relative;
  top: 45%;
  background-color: #d6d6d6;
  border-radius: 5px;
  margin-top: -10px;
  width: 10px;
  height: 50px;
  background-size: cover;
  background-position: center;
  /*z-index: 99999;*/
  font-size: 32px;
  color: white;
}

/*拖拽区鼠标悬停样式*/
//.movers:hover {
//  color: #444444;
//}

/*右侧div'样式*/
.mid {
  float: left;
  width: 100%; /*右侧初始化宽度*/
  height: 100%;
  background: #f3f3f3;
  box-shadow: -1px 4px 5px 3px rgba(0, 0, 0, 0.11);
  /*上方div'样式*/

  .topBox {
    height: 60%;
    background-color: #3ff53f;
    display: flex;
  }

  /*下方div'样式*/

  .downBox {
    //height: calc(40% - 32px);
    background-color: #f0fd35;
    display: flex;
  }

  /* 拖拽区div样式 */

  .mover {
    padding: 0px 20px;
    //cursor: s-resize;
    cursor: text;
    width: 100%;
    height: 30px;
    background-color: #d6d6d6;
    border-radius: 5px;
    text-align: center;
    line-height: 3px;
    font-size: 12px;
    user-select: text; /* 启用文本选择 */
  }

  /*拖拽区鼠标悬停样式*/

  .mover:hover {
    color: #444444;
  }
}

#pageframe {
  padding-bottom: 30px;
}
</style>