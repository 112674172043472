<template>
  <div class="inners">
    <div class="right">
      <div class="titles">{{ $t("repairOrder.title") }}</div>
      <div>
        <div>
          <el-button type="primary" @click="handleClick">{{ $t("repairOrder.des") }}</el-button>
        </div>
        <div style="margin-top: 20px">
          <el-table
              :data="tableData"
              style="width: 100%"
              :row-style="{ height: '70px' }"
              v-loading="loading"
              :header-cell-style="{'text-align': 'left', fontSize:'14px',color:'color: rgba(0,0,0,.85);', fontWeight: '500',background: '#f9f9f9', color: 'black', padding: '16px 0px'}"
          >
            <el-table-column prop="id" :label="$t('repairOrder.table.no')" width="180"/>
            <el-table-column prop="title" :label="$t('repairOrder.table.title')" width="180"/>
            <el-table-column prop="status" :label="$t('repairOrder.table.status')">
              <template #default="{row}">
                <el-button link type="success" v-if="row.status===2">{{ $t("repairOrder.table.ok") }}</el-button>
                <el-button link type="danger" v-else>{{ $t("repairOrder.table.wait") }}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="updateTime" :label="$t('repairOrder.update.time')" width="180"/>
            <el-table-column :label="$t('repairOrder.setting')">
              <template #default="{ row, column, $index }">
                <el-button v-if="row.status!==2" link type="primary" @click="handleClickReplay(row)">回复</el-button>
                <el-button v-else link type="primary" @click="handleClickInfo(row)">{{
                    $t('repairOrder.show')
                  }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pages">
            <el-pagination
                v-model:current-page="current"
                v-model:page-size="size"
                v-if="total"
                layout="total, prev, pager, next"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from "vue";
import {useRouter} from "vue-router";

import BasicCard from "./Basic/BasicCard.vue";
import {getMailOpinionList} from "@/api/usercenter";
import {ElMessage} from "element-plus";

const router = useRouter();
const tableData = ref([
  {id: 1, titles: "标题1", status: "状态1", updateTimes: "2022-02-02"},
  {id: 2, titles: "标题2", status: "状态2", updateTimes: "2022-02-02"},
]);
const loading = ref(false)

const current = ref(1);
const size = ref(10);
const total = ref(0);
const handleCurrentChange = (page) => {
  current.value = page;
  getMailOpinionListApis();
};

const getMailOpinionListApis = async () => {
  loading.value = true;
  const res = await getMailOpinionList({
    pageNo: current.value,
    pageSize: size.value,
  })
  if (res.code !== 200) {
    ElMessage.error(res.message);
    return
  }
  const {records} = res.result;
  tableData.value = records;
  current.value = res.result.current;
  size.value = res.result.size;
  total.value = res.result.total;
  loading.value = false

}
const handleClick = () => {
  router.push("/personal/createdRepairOrder");
};

const handleClickInfo = (row) => {
  router.push({path: '/personal/repairOrderDetails', query: {id: row.id}})
}

const handleClickReplay = (row) => {
  router.push({path: '/personal/repairOrderDetails', query: {id: row.id}})
}

onMounted(async () => {
  await getMailOpinionListApis()
})
</script>

<style lang="less" scoped>
@import "./less/Tables.less";
@import "../../less/page.less";

.inners {
  width: 840px !important;
  min-width: 840px !important;

  .right {
    height: max-content !important;
    padding-bottom: 20px !important;
  }
}

</style>
