<template>
  <!--      归档对话框-->
  <Dialog
      v-model="dialogVisible"
      :title="$t('common.archives.return')"
      :dialogVisible="dialogVisible"
      :isHeadLine="false"
      style="min-width: 800px"
      @update:dialogVisible="close"
      :width="'65%'"
      :top="forms.merge===$t('common.email.newContacts')?'4vh':'8vh'"
      :close-on-click-modal="false"
  >
    <template #content>
<!--    {{customerListAll}}-->
      <el-scrollbar :height="forms.merge===$t('common.email.newContacts')|| forms.customerName===9999?'78vh':'340'">
        <div class="my_forms">
          <div>
            <div class="box_titles">{{ $t("common.email.selectDocumentEmail") }}</div>
            <div class="inner_card sp_box">
              <el-form-item class="sp_email" :label="$t('common.emailbox')+':'" label-width="72px" prop="email"
                            label-position="right">
                <!--                <el-input v-model="forms.email"></el-input>-->
                <el-input v-if="options.length===0"
                          v-model="forms.email"
                          style="color: #a9abb1"
                          :placeholder="$t('20202')"
                />
                <el-select
                    v-else
                    v-model="forms.email"
                    placeholder="Select"
                    size="default"
                    label-position="left"
                    style="width: 240px"
                >
                  <el-option
                      v-for="item in options"
                      :key="item.email"
                      :label="item.email"
                      :value="item.email"
                  />
                </el-select>
                <!--                  {{ $t("system.copy") }}-->
                <el-icon @click="copyEmail()" style="margin-left: 12px">
                  <CopyDocument/>
                </el-icon>
              </el-form-item>
            </div>
          </div>
          <div>
            <div class="box_titles">{{ $t("common.email.chooseToArchiveCustomer") }}</div>
            <div class="inner_card sp_box">
              <el-form-item class="el-row" :label="$t('common.customer.name')+':'" label-position="left"
                            prop="customerName">
                <CustomerSelect
                    v-model="forms.customerName"
                    :teamId="teamId"
                    class="demo"
                    ref="customerSelectRef"
                    @change="handleSelectChange"
                    :placeholder="customerFlag?$t('select.new.customer'):$t('select.customer.name')"
                    newCusomerFlag
                />
              </el-form-item>
              <el-form-item v-if="forms.customerName!==9999" class="el-row" :label="$t('common.email.archiveWay')+':'"
                            prop="customerName" label-position="left">
                <el-select v-model="forms.merge" class="m-2" placeholder="Select"
                           @change="handleChangesMerge"
                           :disabled=" forms.customerName === ''">
                  <el-option :label="$t('common.email.newContacts')"
                             :value="$t('common.email.newContacts')"></el-option>
                  <el-option :label="$t('common.cds6')" :value="$t('common.cds6')"></el-option>
                  <el-option
                      v-for="item in contactList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div v-if="forms.customerName===9999">
            <div class="box_titles">{{ $t('create.gui.customer') }}</div>
            <Forms :isShowButton="false" ref="childRef">
              <template #createMessage>
                <el-row :gutter="24">
                  <el-col :span="6">
                    <el-form-item :label="$t('common.region.name')" prop="zoneAddress">
                      <el-select v-model="ruleForm.zoneAddress" :placeholder="$t('common.please.select')" filterable
                                 value-key="country"
                                 @change="selectMore($event)"
                      >
                        <el-option
                            v-for="item in areaList"
                            :label="item.country"
                            :value="item"
                            :key="item.country"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item
                        prop="customerCode">
                      <template #label>
                        <span><span style="color: #e47470;margin-right: 4px">*</span>{{ $t('common.customer.number') }}</span>
                      </template>
                      <el-input v-model="ruleForm.customerCode" :placeholder="ruleForm.customerCodeDefault"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item
                        :label="$t('common_customer_name')"
                        prop="customerName">
                      <el-input v-model="ruleForm.customerName" :placeholder="$t('common.please.input')"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item
                        :label="$t('common.customer.source')"
                        prop="customerOrigin"
                    >
                      <el-input
                          v-model="ruleForm.customerOrigin"
                          :placeholder="$t('common.please.input')"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>
            </Forms>
            <!--            新建联系人勾选-->
            <el-checkbox v-model="isSelectCreateContact"
                         style="padding-left: 38px;font-size: 16px;font-weight: 800;margin-top: -200px"
                         :label="$t('common.email.newContacts')" size="large"
                         @change="selectCreateContact">
            </el-checkbox>
          </div>
          <div v-if="forms.merge===$t('common.email.newContacts') || isSelectCreateContact&&forms.customerName===9999"
               class="box_titles_created">
            <div class="inner_card">
              <el-form
                  ref="ruleFormRef"
                  :model="ruleFormUsers"
                  :rules="rules"
                  label-width="80px"
                  class="demo-ruleForm"
              >
                <div class="tops">
                  <div class="span_block"></div>
                  <div class="inner_text">{{ $t('contact.info') }}</div>
                </div>
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item :label="$t('common.name')" prop="name">
                      <el-input v-model="ruleFormUsers.name"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item :label="$t('common.sex')" prop="sex">
                      <el-radio-group
                          v-model="ruleFormUsers.sex"
                          class="ml-4"
                      >
                        <el-radio :label="1" :value="1">{{ $t('common.man') }}</el-radio>
                        <el-radio :label="2" :value="2">{{ $t('common.woman') }}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item :label="$t('common.company.position')" prop="post">
                      <el-input v-model="ruleFormUsers.post"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item :label="$t('common.phone.number')" prop="phone">
                      <el-input v-model="ruleFormUsers.phone"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item :label="$t('common.contact.phone')" prop="telPhone">
                      <el-input v-model="ruleFormUsers.telPhone"/>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item :label="$t('common.dateofbirth')" prop="birthday">
                      <el-date-picker
                          v-model="ruleFormUsers.birthday"
                          type="date"
                          :placeholder="$t('sys.tip.select.date')"
                          :size="size"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="24">
                    <el-form-item :label="$t('common.remark')" prop="remark">
                      <el-input
                          v-model="ruleFormUsers.remark"
                          type="textarea"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="24">
                    <MyTitles :title="$t('common.emailbox')">
                      <template #content>
                        <div class="bottoms">
                          <el-form-item
                              class="sp_form_item"
                              v-for="(
                                      item, index
                                    ) in ruleFormUsers.mailContactEmailList"
                              style="margin-top: 20px"
                              :key="index"
                              :label="index === 0 ? $t('common.emailbox') : ''"
                              :prop="
                                      'mailContactEmailList[' +
                                      index +
                                      '].email'
                                    "
                              :rules="[
                                      {
                                        pattern:emailReg,
                                        message: $t('sys.message.error.emailbox'),
                                        trigger: ['change','blur'],
                                      },
                                    ]"
                          >
                            <div class="email-input">
                              <el-input
                                  v-model="item.email"
                                  :placeholder="$t('20202')"
                              />
                              <el-icon
                                  v-if="
                                          ruleFormUsers.mailContactEmailList
                                            .length !== 1
                                        "
                                  style="
                                          font-size: 20px;
                                          color: #d93737;
                                          margin-left: 20px;
                                          cursor: pointer;
                                        "
                                  @click="removeEmail(index)"
                              >
                                <RemoveFilled/>
                              </el-icon>
                              <div v-else style="width: 44px"></div>
                            </div>
                          </el-form-item>
                          <el-form-item>
                            <el-button
                                @click="addEmail"
                                id="spButton"
                                :icon="Plus"
                            >
                              {{ $t('common.add') }}
                            </el-button>
                          </el-form-item>
                        </div>
                      </template>
                    </MyTitles>
                  </el-col>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="24">
                    <MyTitles :title="$t('common.memorialday')">
                      <template #content>
                        <div class="remembers">
                          <!--                          <RememberDate></RememberDate>-->
                          <div>
                            <div
                                v-for="(
                                        event, index
                                      ) in ruleFormUsers.mailContactSouvenirList"
                                :key="index"
                                class="event-input"
                            >
                              <template v-if="!event.editing">
                                <div class="out_box">
                                  <div
                                      class="event-text"
                                      @click="editEvent(index)"
                                  >
                                    {{ event.remark }}
                                  </div>
                                  <div class="event-text"></div>
                                </div>
                              </template>
                              <el-input
                                  v-if="event.editing"
                                  v-model="event.remark"
                                  :placeholder="$t('common.contact.info.anniversary.name')"
                                  @focus="showButtons(index)"
                                  @blur="hideButtons(index)"
                                  ref="inputRef"
                              ></el-input>
                              <div style="margin: 0 10px"></div>
                              <el-date-picker
                                  v-model="event.souvenirDay"
                                  type="date"
                                  class="event-text"
                                  :placeholder="$t('common.please.select')"
                              />
                              <div class="setting">
                                <el-button
                                    v-if="!event.showButtons"
                                    @click="updateEvent(index)"
                                    icon="el-icon-check"
                                    type="primary"
                                    link
                                >{{ $t('common.edit') }}
                                </el-button>
                                <el-button
                                    v-if="event.showButtons"
                                    @click="removeEvent(index)"
                                    icon="el-icon-delete"
                                    link
                                    type="danger"
                                >{{ $t('common.delete') }}
                                </el-button>
                                <el-button
                                    v-if="!event.showButtons"
                                    @click="removeEvent(index)"
                                    icon="el-icon-delete"
                                    link
                                    type="danger"
                                >{{ $t('common.delete') }}
                                </el-button>
                              </div>
                            </div>

                            <el-button @click="addEvent" type="primary"
                            >{{ $t('common.memorialday.add') }}
                            </el-button>
                          </div>
                        </div>
                      </template>
                    </MyTitles>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </template>
    <template #footer>
      <div class="footer_box">
        <el-button @click.native.stop="close">{{ $t('common.sss16') }}</el-button>
        <el-button type="primary" @click.native.stop="saveAllDiaDatas">{{ $t('common.save') }}</el-button>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import Dialog from "@/components/Dialog.vue";
import {defineProps, ref, watch, defineEmits, reactive, computed, onMounted, defineExpose, toRaw, nextTick} from "vue";
import {useRoute, useRouter} from "vue-router";
import MyTitles from "@/views/Customer/Detail/MyTitles.vue";
import {
  addMailCustomerContact,
  editMailCustomerContact,
  getMailCustomerContactByCustomerId,
  requestAllCustomer
} from "@/api/customer";
import {ElMessage} from "element-plus";
import {useStore} from "vuex";
import {i18n} from "@/lang";
import {emailReg} from '@/utils/statusParams'
import {Plus} from "@element-plus/icons-vue";
import dayjs from "dayjs";
import Forms from "@/views/Customer/CreateCheck/Forms.vue";
import {addOutsideUser} from "@/api/groups";
import CustomerSelect from '../../../components/CustomerSelect.vue';

const router = useRouter()
const route = useRoute()
const emit = defineEmits()
const store = useStore();
const customerSelectRef = ref()

const customerListAll = computed(() => store.state.customer.customerListAll)

// 建档信息
const ruleForm = computed(() => store.state.customer.ruleForm);
// 区域列表
const areaList = computed(() => store.state.customer.areaList);
const randomCustomerCode = computed(() => store.state.customer.randomCustomerCode)
const dialogVisibleTips = ref(false)
const contactList = ref([]) // 手动筛选联系人列表
// 归档
const dialogVisible = ref(false)
// 是否勾选 新建联系人
const isSelectCreateContact = ref(false);
// 选择勾选 改变状态
const selectCreateContact = (val) => {
  console.log(val)
}
// 对话框表单信息
const forms = reactive({email: '', customerName: '', merge: "公司邮箱", contactName: ''})
const teamId = computed(() => store.state.email.teamID);
const customerFlag = computed(() => store.state.email.customerFlag)
// 表单数据
const ruleFormUsers = reactive({
  name: "",
  sex: 1,
  post: "",
  phone: "",
  telPhone: "",
  birthday: null,
  remark: "",
  mailContactEmailList: [
    {
      email: "",
      isDefault: 0,
    },
  ],
  mailContactSouvenirList: [],
});
const options = ref([])
// 表单校验规则
const rules = reactive({
  name: [{required: true, message: i18n.global.t('sys.tip.input.name'), trigger: ["blur", 'change']}],
});


const {value, targetEmail, reGetList} = defineProps({
  value: {type: Boolean, default: false},
  targetEmail: {
    type: Array,
  },
  reGetList: {
    type: Function,
  },
})

watch(() => value, (val) => {
  dialogVisibleTips.value = val;
}, {immediate: true})


const close = () => {
  emit("close")
}
const toBuy = () => {
  router.push({path: "/personal/buystorage", query: {index: 1}});
}


async function defaultSelectCustomer() {
  // console.log(targetEmail, 'targetEmail')
  if (route.path === '/email/has_send') {
    options.value = targetEmail;
  }

  if (targetEmail.length !== 0) {
    forms.email = targetEmail[0].email
  }
  // 默认选中第一个
  // const result = JSON.parse(JSON.stringify(optionsCustomers))
  // console.log('$$$', optionsCustomers.value)
  const optionsCustomers = customerListAll.value
  console.log(optionsCustomers, 'customerSelectRef.value.customerListAll')
  if (optionsCustomers.length > 1) {
    forms.customerName = optionsCustomers[1].id
    let res = await getMailCustomerContactByCustomerId({customerId: forms.customerName})
    if (res.code !== 200) {
      ElMessage.error(res.message)
      return
    }
    const targetNewObj = res.result
    // 寻找第一个的联系人
    if (targetNewObj.length !== 0) {
      contactList.value = targetNewObj
      forms.merge = targetNewObj.length > 2 ? targetNewObj[2].id : "公司邮箱"
    } else {
      contactList.value = []
      forms.merge = targetNewObj.length > 2 ? targetNewObj[2].id : "公司邮箱"
      ruleFormUsers.mailContactEmailList = [{email: forms.email, isDefault: 1}]
      forms.contactName = ''
    }
    localStorage.setItem('formsFlag', JSON.stringify(forms))
    store.commit('email/setCustomerFlag', false)
  } else {
    store.commit('email/setCustomerFlag', true)
  }
}

async function handleSelectChange(value) {

  if (value === 9999) {
    //   表单重制
    await store.commit('customer/setRuleForm', {
      zoneCode: "",
      customerCode: "",
      customerCodeDefault: "请输入",
      zoneAddress: "",
      customerName: "",
      setUpTime: null,
      economicNature: "",
      companyWebsite: "",
      companyAddress: "",
      phoneWayList: [],
      emailWayList: [],
      companyFax: "",
      companyRemark: "",
      customerLevel: "",
      customerType: "",
      customerTypeId: "",
      product: "",
      marketingRemark: " ",
      createBy: "",
      branchAdmin: "",
      mangerVOList: [{managerName: ''}]
    })
    await store.commit('customer/setForm', {
      phones: [{phoneNumber: "", isDefault: true}],
      emails: [{email: "", isDefault: true}],
    })
  }

  let res = await getMailCustomerContactByCustomerId({customerId: value})
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  const targetNewObj = res.result
  if (targetNewObj.length !== 0) {
    contactList.value = targetNewObj
    forms.merge = targetNewObj.length > 2 ? targetNewObj[2].id : "公司邮箱"
  } else {
    contactList.value = [];
    forms.merge = targetNewObj.length > 2 ? targetNewObj[2].id : "公司邮箱";
  }
  localStorage.setItem('formsFlag', JSON.stringify(forms))
}


// 获取所有的客户 ！！所有客户
function getAllCustomers() {
  nextTick(async () => {
    await customerSelectRef.value.getAllCustomer()
    console.log(customerSelectRef.value, 'customerListAll')
    await defaultSelectCustomer();
  })
}


// 建档
function createdNewCustomer() {
  emit('createCustomer', forms.email)
}

function handleChangesMerge(value) {
  // console.log(value, 'fff')
  forms.merge = value;
  ruleFormUsers.mailContactEmailList = [{email: forms.email, isDefault: 1}]
}

const removeEmail = (index) => {
  ruleFormUsers.mailContactEmailList.splice(index, 1);
};


//复制文本
const copyEmail = async () => {
  await navigator.clipboard.writeText(forms.email);
  ElMessage.success('复制成功');
}


const addEmail = () => {

  console.log(ruleFormUsers.mailContactEmailList, forms, 'ruleFormUsers.mailContactEmailList')
  if (
      ruleFormUsers.mailContactEmailList.filter((item) => item.email == "")
          .length >= 1
  ) {
    ElMessage.error(i18n.global.t('common.email.email.notempty'));

  } else {
    ruleFormUsers.mailContactEmailList.push({
      email: "",
      isDefault: 0,
    });
    ruleFormUsers.mailContactEmailList[0].isDefault = 1;
  }
};


const updateEvent = (index) => {
  ruleFormUsers.mailContactSouvenirList[index].editing = true;
  ruleFormUsers.mailContactSouvenirList[index].showButtons = true;
};

const removeEvent = (index) => {
  ruleFormUsers.mailContactSouvenirList.splice(index, 1);
};

const showButtons = (index) => {
  ruleFormUsers.mailContactSouvenirList[index].showButtons = true;
};

const hideButtons = (index) => {
  ruleFormUsers.mailContactSouvenirList[index].showButtons = false;
  ruleFormUsers.mailContactSouvenirList[index].editing = false;

  if (ruleFormUsers.mailContactSouvenirList[index].remark === "") {
    removeEvent(index);
  }
};

// 添加纪念日
const dates = ref([{value: new Date()}]);
// 添加纪念日
const addEvent = () => {
  if (ruleFormUsers.mailContactSouvenirList.filter(item => item.souvenirDay === "").length >= 1) {
    ElMessage.warning("请填写完整后再进行添加")
    return;
  } else {
    ruleFormUsers.mailContactSouvenirList.push({
      remark: "",
      souvenirDay: "",
      editing: true,
      showButtons: true,
    });
    dates.value.push({value: new Date()});
  }
};

// 添加联系人表单
const ruleFormRef = ref(null);
const contactMessage = computed(() => store.state.customer.contactMessage);
const childRef = ref(null)

// 保存添加的联系人
async function saveAllDiaDatas() {
  // console.log(forms, ruleFormRef, forms.merge, '数据整合');
  const {email, customerName, contactName, merge} = forms
  // 是否需要开启表单验证

  // 已有联系人 ---获取更新联系人

  // 添加联系人
  // forms.merge true 已有联系人；false 新建联系人

// 保存客户信息
  if (forms.customerName !== 9999) {
    // 非新建客户
    // 归档有三种情况
    if (forms.merge === "新建联系人") {
      // 添加新的联系人
      ruleFormRef.value.validate(async (valid, fields) => {
        if (valid) {
          const copObj = JSON.parse(JSON.stringify(ruleFormUsers));
          console.log(copObj, 'copObj')
          const {mailContactSouvenirList, mailContactEmailList} = copObj;
          let resMails = mailContactSouvenirList.map((item) => {
            return {
              ...item,
              souvenirDay: item.souvenirDay && dayjs(item.souvenirDay).isValid()
                  ? dayjs(item.souvenirDay).format("YYYY-MM-DD")
                  : null,
            };
          });
          //默认邮箱
          const selectDefault = mailContactEmailList.some(item => item.isDefault === 1) ? mailContactEmailList.filter(_ => _.email !== "") : mailContactEmailList.map((item, index) => {
            if (index === 0) {
              return {isDefault: 1, email: item.email}
            } else {
              return {...item, isDefault: 1}
            }
          }).filter(_ => _.email !== "");
          delete copObj.birthday;
          delete copObj.mailContactSouvenirList;
          delete copObj.mailContactEmailList
          const targetObj = {
            ...copObj,
            birthday:
                (ruleFormUsers.birthday && dayjs(ruleFormUsers.birthday).isValid())
                    ? dayjs(ruleFormUsers.birthday).format("YYYY-MM-DD")
                    : null,
            mailContactSouvenirList: resMails,
            mailContactEmailList: selectDefault,
            customerId: forms.customerName,
            teamId: teamId.value,
          };
          // 添加联系人邮箱
          let res = await addMailCustomerContact(targetObj);
          if (res.code !== 200) {
            ElMessage.error(res.message);
            return;
          }
          ElMessage.success(res.message);
          // console.log('pinkser', res.result)
          const {id} = res.result;

          if (forms.merge === "已有联系人") {
            // 最终的数据
            const endTargetObj = {
              contactId: id,
              customerId: customerName,
              email,
              teamId: teamId.value,
            }
            // console.log(endTargetObj, 'end')
            await store.dispatch("customer/addAttachment", endTargetObj)
          }
          close()
          reGetList()
        } else {
          // console.log("error submit!", fields);
        }
      });
    } else if (forms.merge === "公司邮箱") {
      //   获取客户详情
      await store.dispatch("customer/getQueryById", {
        customerId: customerName,
      });
      const {emailWayList, phoneWayList} = ruleForm.value

      const payload = {
        emails: [...toRaw(emailWayList).map(item => ({
          id: item.id,
          email: item.content,
          isDefault: item.isDefault === 1
        })), {isDefault: false, email: email}],
        phones: [...toRaw(phoneWayList).map(item => ({
          id: item.id,
          phoneNumber: item.content,
          isDefault: item.isDefault === 1
        }))]
      }

      console.log(ruleForm.value, payload, 'detail')
      await store.commit("customer/setForm", payload)
      const customerType = computed(() => store.state.customer.selectTarget);
      await store.dispatch("customer/UpdateActivity", {
        customerType: customerType.value,
        isAlert: false,
        teamId: teamId.value
      });
      ElMessage.success("归档成功");
      close()
      reGetList()
    } else {
      await store.dispatch("customer/getContactDetail", {
        contactId: merge,
        customerId: customerName,
      });
      const res = JSON.parse(JSON.stringify(contactMessage.value?.mailContactEmailList || []))
      let newEmails = [...res, {
        email: forms.email,
        isDefault: res.filter(_ => _.isDefault === 1).length > 0 ? 0 : 1
      }]
      console.log({
        ...contactMessage.value,
        teamId: teamId.value,
        contactId: contactName,
        customerId: customerName,
        mailContactEmailList: newEmails,
      })
      const result = await editMailCustomerContact({
        ...contactMessage.value,
        teamId: teamId.value,
        contactId: contactName,
        customerId: customerName,
        mailContactEmailList: newEmails,
      })
      if (result.code !== 200) {
        ElMessage.error(result.message)
        return
      }
      ElMessage.success(result.message);
      close()
      reGetList()
    }
  } else {
    childRef.value.ruleFormRef.validate(async (valid) => {
      if (valid) {
        if (isSelectCreateContact.value) {
          //   是否需要联系人校验
          ruleFormRef.value.validate(async (validitem) => {
            if (validitem) {
              //   新建客户
              const {mailContactEmailList} = ruleFormUsers
              const targetMailContactEmailList = toRaw(mailContactEmailList)
              const result = targetMailContactEmailList.map(item => ({...item, isDefault: 1}))
              const mailContactDTOList = [{
                ...ruleFormUsers,
                teamId: teamId.value,
                mailContactEmailList: result,
                flag: true,//区分客户入口还是收件箱入口
              }]
              console.log('innerForm1', mailContactDTOList)
              await nextTick()
              await store.dispatch("customer/saveActivity", mailContactDTOList);
              close()
              reGetList()
            } else {
              await nextTick(() => {
                // 获取错误节点
                let isError = ruleFormRef.value.$el.getElementsByClassName('is-error')
                isError[0].scrollIntoView({
                  // 滚动到指定节点
                  // 值有start,center,end，nearest，当前显示在视图区域中间
                  block: 'center',
                  // 值有auto、instant,smooth，缓动动画（当前是慢速的）
                  behavior: 'smooth',
                })
              })
            }
          })
        } else {
          //   新建客户
          await nextTick()
          await store.dispatch("customer/saveActivity", {teamId: teamId.value});
          close()
          reGetList()
        }
      } else {
        await nextTick(() => {
          // 获取错误节点
          let isError = childRef.value.ruleFormRef.$el.getElementsByClassName('is-error')
          isError[0].scrollIntoView({
            // 滚动到指定节点
            // 值有start,center,end，nearest，当前显示在视图区域中间
            block: 'center',
            // 值有auto、instant,smooth，缓动动画（当前是慢速的）
            behavior: 'smooth',
          })
        })
      }
    })
  }
}

onMounted(async () => {
  await store.dispatch('customer/getAreaNames')
})


const selectMore = async (event) => {
  ruleForm.value.zoneAddress = event.country;
  ruleForm.value.zoneCode = event.zoneCode;
  ruleForm.value.customerIds = event.zoneCode
  ruleForm.value.teamId = teamId.value;

  //   获取 随机生成的 id
  const payload = {teamId: teamId.value, zoneCode: event.zoneCode}
  await store.dispatch("customer/getCustomerCodeApi", payload)
  ruleForm.value.customerCodeDefault = randomCustomerCode.value;
  store.commit("customer/setIsSelected", true)
}


defineExpose({getAllCustomers})

</script>

<style lang="less" scoped>
@import "../../../views/Customer/less/detail.less";

.inners_warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.setting_center {
  display: flex;
  justify-content: center;
}

:deep(.el-table .read_0) {
  font-weight: 700 !important;
}

.wrap {
  background-color: rgb(245, 245, 245);
  padding: 16px;
  height: 100%;

  .inbox .bg_w {
    & :deep(.el-select) {
      width: 200px !important;
    }
  }
}

.fx-j-e {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.pointer {
  cursor: pointer;
}

.inbox {
  background-color: #fff;
  height: 100%;
}

.bg_w {
  padding: 8px;
  display: flex;
  align-items: center;

  .span {
    font-size: 14px;
    color: rgba(0, 0, 0, .65);
  }
}

.input-with-select {
  width: 350px;
  margin-right: 10px;
}

.screen {
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
  background-color: #f5f5f5;

  .el-button + .el-button {
    margin-left: 2px;
  }

  .el-dropdown {
    margin-left: 2px;
  }
}

.current_mail {
  .mail_info {
    display: flex;
    height: 30px;
    align-items: center;
    padding: 0px 20px;
    background: rgb(229, 229, 229);
    cursor: n-resize;

    div {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
    }
  }
}

.box_titles {
  position: relative;
  height: max-content;
}

.inner_card {
  margin: 10px 0px 30px 38px;
  //background: red;
}

.sp_box {
  width: 45% !important;
}

.footer_box {
  margin-top: 20px;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;

  .el-button {
    margin: 0px 10px;
  }
}

.box_titles {
  font-size: 16px;
  color: black;
  font-weight: 700 !important;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #e8e8e8;
}

.sp_buttons {
  margin-left: 20px;
  position: absolute;
  left: 105%;
}

.my_forms {
  margin-top: 20px;
}

:deep(.el-form-item__content) {
  flex-wrap: nowrap !important;
}

.wrap :deep(.el-form-item) {
  margin: 20px 0 0;
}

.el-form-item__content > div {
  position: relative;
}

:deep(.el-scrollbar__bar.is-horizontal) {
  height: 0 !important;
}

:deep(.el-scrollbar__wrap) {
  overflow-x: hidden !important;
  padding-right: 10px !important;
}

:deep(.el-input-group__append) {
  padding: 0 20px 0 0;
}

:deep(.el-input-group__append button.el-button) {
  background: #5a9cf8;
  color: white;
  width: 76px;
  border-radius: 0px 4px 4px 0px !important;
}


.screen_btn {
  button:nth-of-type(1) {
    margin-left: 0px !important;
  }

  button {
    margin-left: 2px !important;
  }
}

:deep(.el-empty__image img) {
  width: 54% !important;
}

:deep(.el-empty__description) {
  margin-top: -20px;
}

.scroll_out_box {
  position: relative;
}

.mail_info {
  width: 100%;
  position: absolute;
  z-index: 999;
}

#pageframe {
  padding: 30px 0px 8px 0px;
}

:deep(.el-card__body) {
  padding: 0px 20px 20px 20px !important;
}

:deep(.el-checkbox__label) {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: black;
}

#Card {
  padding: 0px !important;
}

:deep(.el-checkbox.el-checkbox--large .el-checkbox__inner) {
  border-width: 2px !important;
  border-color: black !important;
}


.tops {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;

  .span_block {
    width: 4px;
    height: 16px;
    padding-left: 5px;
    border-left: 4px solid #1890ff;
  }

  .inner_text {
    font-weight: 700;
    color: #000;
  }
}

:deep(.sp_email .el-input__wrapper) {
  background: #f5f7fa !important;
}

:deep(.sp_email .el-select__wrapper) {
  background: #f5f7fa !important;
}
</style>
