<template>
  <div ref="scrollContainer">
    <el-transfer
        v-model="transferData"
        :data="mockData"
        :titles="[$t('sys.notice.all'), $t('common.sss2')]"
        :filterable="true"
        :filter-placeholder="$t('search.input.text')"
        :props="{
          key: 'id',
          label: 'account',
          email:'account'
    }"
        @change="handleChange"
    >
      <template v-slot:footer>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
      </template>
    </el-transfer>
  </div>
</template>

<script setup>
import {ref, onMounted, onBeforeUnmount, computed} from 'vue';
import {useStore} from 'vuex'

const store = useStore();
const mockData = computed(() => store.state.groups.innerUserList)
// const transferData = ref([]);
const transferData = computed(() => store.state.groups.transferData);
const total = computed(() => store.state.groups.innerUserPage.totalsUserList);
const page = computed(() => store.state.groups.innerUserPage.currentUserList)
const pageSize = computed(() => store.state.groups.innerUserPage.sizeUserList)

const loading = ref(false);
const scrollContainerRef = ref(null);
const teamId = computed(() => store.state.email.teamID);
const fetchData = async () => {
  loading.value = true;
  await store.dispatch('groups/getCompanyUserList', {teamId: teamId.value});

  // 分页相关
  // const newData = computed(() => store.state.groups.innerUserList)
  // // 模拟异步加载延迟
  // setTimeout(() => {
  //   mockData.value = [...mockData.value, ...newData.value];
  //   loading.value = false;
  // }, 500);
};

const handleCurrentChange = async (currentPage) => {
  // page.value = currentPage;
  store.commit('groups/setInnerUserPage', {
    currentUserList: currentPage,
  })
  await fetchData();
};

const handleScroll = () => {
  const container = scrollContainerRef.value;
  if (container.scrollTop + container.clientHeight >= container.scrollHeight && !loading.value) {
    // page.value++;
    // console.log(page.value , total.value, pageSize.value)
    store.commit('groups/setInnerUserPage', {
      currentUserList: page.value > (total.value + pageSize.value - 1) / pageSize.value ? page.value : page.value + 1,
    })
    fetchData();
  }
};

onMounted(async () => {
  await fetchData();
  scrollContainerRef.value = document.querySelector('.el-transfer-panel__list');
  // scrollContainerRef.value.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  scrollContainerRef.value.removeEventListener('scroll', handleScroll);
});

const handleChange = (value) => {
  store.commit('groups/setTransferData', value)
}


</script>
<style lang="less" scoped>
:deep(.el-transfer__buttons) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

:deep(.el-transfer) {
  display: flex !important;
}

:deep(.el-transfer__button:nth-child(2)) {
  margin-left: 0px !important;
  margin-top: 10px !important;
}

</style>
