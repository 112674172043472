

import TencentCloudChat from '@tencentcloud/chat';
import { genTestUserSig } from '../../debug';
import TIMUploadPlugin from 'tim-upload-plugin';
import { ElMessage } from "element-plus";
import router from '../../router';
import { getOutsideUser } from "@/api/groups";
// import { getImInfo } from '../../api/chat';

const SDKAppID = 1600069131;  // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID

const appKey = ''; // 推送服务 Push 的客户端密钥

// console.log(userSig, '密文');

// 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
export const chat = TencentCloudChat.create({
  SDKAppID,
}); // SDK 实例通常用 chat 表示

chat.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
// 注册腾讯云即时通信 IM 上传插件，即时通信 IM SDK 发送图片、语音、视频、文件等消息需要使用上传插件，将文件上传到腾讯云对象存储
chat.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});

const chatModule = {
  namespaced: true,
  state: {
    // 当前会话对象
    currConversation: {
      showModal: false,
      conversation: null,
    },
    conversationList: [],
    groupList: [],
    friendList: [],
    friendApplication: {
      list: [],
      unreadCount: 0,
    },
    searchUserProfileList: [],
  },
  actions: {
    async initChat(store, userID) {
      // const userID = '1795276512932253697'; // 您的 userID
      // const userID = 'WM66472713';
      // const secretKey = "0d1cce06895eae7c61fe444dbaf5ea68b705cbfde52f5050ef4d964e2c2d0252"; // Your secretKey
      const secretKey =
        "541cd13f1387736b169d5d1a280658ab71304bf79e8e2044267c9f7863e59a35"; // Your secretKey

      const { userSig } = genTestUserSig({ SDKAppID, secretKey, userID }); // 用户登录即时通信 IM 的密码，其本质是对 UserID 等信息加密后得到的密文
      // const result = await getImInfo();
      // const { userSig } = result.result;
      // console.log('im信息 接口返回', result);
      console.log("初始化 chat ====", userSig);

      // 登录 Chat
      let promise = chat.login({ userID, userSig });
      console.log("登录");
      return promise
        .then(function (imResponse) {
          console.log(imResponse.data, "登录成功"); // 登录成功
          setTimeout(() => {
            store.dispatch("initData");
          }, 1000);
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。
            console.log(imResponse.data.errorInfo);
          }
          return true;
        })
        .catch(function (imError) {
          console.log("login error:", imError); // 登录失败的相关信息
        });
    },
    initData(store) {
      chat.on(TencentCloudChat.EVENT.SDK_READY, () => {
        store.dispatch("chat/updateConversationList");
        store.dispatch("chat/updateGroupList");
        store.dispatch("chat/updateFriendList");
        store.dispatch("chat/updateFriendApplicationList");
        store.dispatch('chat/onSdkNotReady');
      });
    },
    onSdkNotReady(store) {
      async function onSdkNotReady() {
        ElMessage.error('账号多设备登录，请重新登录');
        await store.dispatch('user/UserQuit');
        await store.dispatch('chat/logout');
        router.push('/login');
      };
      chat.on(TencentCloudChat.EVENT.SDK_NOT_READY, onSdkNotReady);
    },
    /**
     * 退出登录
     */
    logout() {
      const promise = chat.logout();
      promise
        .then(function (imResponse) {
          console.log(imResponse.data); // 登出成功
        })
        .catch(function (imError) {
          console.log("logout error: " + imError);
        });
    },
    /**
     * 修改当前会话对象
     * @param {*} param0
     * @param {Object} conversation 会话对象
     * @param {Boolean} showModal 是否可见
     */
    updateCurrentConversation({ state }, payload) {
      // 会话类型
      const type = payload.conversation ? payload?.conversation?.type : state.currConversation?.conversation?.type;
      state.currConversation = {
        type,
        showModal: false, // 给个默认值
        ...state.currConversation,
        ...payload,
      };
    },
    /**
     * 获取会话列表
     * @param {*} param0
     */
    updateConversationList({ state }) {
      // 获取全量的会话列表
      let promise = chat.getConversationList();
      promise
        .then(function (imResponse) {
          // 全量的会话列表，用该列表覆盖原有的会话列表
          const conversationList = imResponse.data.conversationList;
          console.log(imResponse, "全量的会话列表");
          console.log(conversationList[0].getLastMessageTime, "获取时间");
          // 从云端同步会话列表是否完成
          state.conversationList = conversationList.map((conversation) => ({
            ...conversation,
            getShowName() {
              if (conversation.type === TencentCloudChat.TYPES.CONV_GROUP) {
                return (
                  conversation?.groupProfile?.name ||
                  conversation?.groupProfile?.groupID
                );
              }
              if (conversation.type === TencentCloudChat.TYPES.CONV_C2C) {
                return (
                  conversation?.remark ||
                  conversation?.userProfile?.nick ||
                  conversation?.userProfile?.userID
                );
              }
              return conversation?.userProfile?.userID;
            },
            getAvatar() {
              if (
                conversation.type === TencentCloudChat.TYPES.CONV_GROUP &&
                conversation?.groupProfile?.avatar
              ) {
                return conversation?.groupProfile?.avatar;
              }
              if (
                conversation.type === TencentCloudChat.TYPES.CONV_C2C &&
                conversation?.userProfile?.avatar
              ) {
                return conversation?.userProfile?.avatar;
              }
              return "https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png";
            },
          }));
        })
        .catch(function (imError) {
          // 获取会话列表失败的相关信息
          console.warn("getConversationList error:", imError);
        });
    },
    /**
     * 获取群组列表
     * @param {*} param0
     */
    updateGroupList({ state }) {
      // 该接口默认只会拉取这些资料：群类型、群名称、群头像、最后一条消息的时间。
      let promise = chat.getGroupList();
      promise
        .then(function (imResponse) {
          const groupList = imResponse.data.groupList;
          console.log(groupList, "群组列表"); // 群组列表
          state.groupList = groupList;
        })
        .catch(function (imError) {
          console.warn("getGroupList error:", imError); // 获取群组列表失败的相关信息
        });
    },
    /**
     * 获取好友列表
     * @param {*} param0
     */
    updateFriendList({ state }) {
      let promise = chat.getFriendList();
      promise
        .then(function (imResponse) {
          const friendList = imResponse.data; // 好友列表
          console.log(friendList, "好友列表");
          state.friendList = friendList;
          state.friendList = friendList.map((friend) => ({
            ...friend,
            getShowName() {
              return (
                friend?.remark ||
                friend?.profile?.nick ||
                friend?.profile?.userID
              );
            },
            getAvatar() {
              return (
                friend?.profile?.avatar ||
                "https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png"
              );
            },
          }));
        })
        .catch(function (imError) {
          console.warn("getFriendList error:", imError); // 获取好友列表失败的相关信息
        });
    },
    /**
     * 获取好友申请列表
     * @param {*} param0
     */
    updateFriendApplicationList({ state }) {
      let promise = chat.getFriendApplicationList();
      promise.then(function (imResponse) {
        // friendApplicationList - 好友申请列表 - [FriendApplication]
        // FriendApplication 数据结构详情请参考 https://web.sdk.qcloud.com/im/doc/v3/zh-cn/FriendApplication.html
        // unreadCount - 好友申请的未读数
        const { friendApplicationList, unreadCount } = imResponse.data;
        state.friendApplication = {
          list: friendApplicationList,
          unreadCount,
        };
      });
    },
    /**
     * 发送好友申请
     * @param {*} param0
     * @param {Object} payload
     */
    sendFriendApplication({}, payload) {
      // 添加好友
      let promise = chat.addFriend({
        to: payload.userID,
        source: "AddSource_Type_Web",
        wording: payload.wording,
        groupName: "好友",
        type: TencentCloudChat.TYPES.SNS_ADD_TYPE_BOTH,
      });
      console.log("添加好友", payload);
      promise
        .then(function (imResponse) {
          // 添加好友的请求发送成功
          const { code } = imResponse.data;
          console.log("添加好友", imResponse);
          if (code === 30539) {
            // 30539 说明 user1 设置了【需要经过自己确认对方才能添加自己为好友】，此时 SDK 会触发 TencentCloudChat.EVENT.FRIEND_APPLICATION_LIST_UPDATED 事件
            ElMessage({
              message: "好友申请成功.",
              type: "success",
            });
          } else if (code === 0) {
            // 0 说明 user1 设置了【允许任何人添加自己为好友】，此时 SDK 会触发 TencentCloudChat.EVENT.FRIEND_LIST_UPDATED 事件
            ElMessage({
              message: "好友添加成功.",
              type: "success",
            });
          }
        })
        .catch(function (imError) {
          ElMessage({
            message: imError.message,
            type: "error",
          });
          console.log("添加好友", imError.message);
        });
    },
    /**
     * 处理好友申请
     * @param {*} param0
     * @param {Object} param1 申请好友对象
     */
    processFriendApplication({}, { userID }) {
      let promise = chat.acceptFriendApplication({
        userID,
        type: TencentCloudChat.TYPES.SNS_APPLICATION_AGREE_AND_ADD,
      });
      promise
        .then(function (imResponse) {
          // 同意好友成功后，SDK 会触发 TencentCloudChat.EVENT.FRIEND_APPLICATION_LIST_UPDATED 事件
        })
        .catch(function (imError) {
          console.warn("acceptFriendApplication error:", imError);
        });
    },
    /**
     * 根据userID搜索用户
     * @param {*} param0
     * @param {String[]} payload
     */
    async searchUserProfileByUserID({ state }, payload) {
      console.log(payload);
      const res = await getOutsideUser({ uuid: payload });
      state.searchUserProfileList = res.result ? [res.result] : [];
      console.log(res, "搜索");
      // let promise = chat.getUserProfile({
      //   userIDList: [...payload] // 请注意：即使只拉取一个用户的资料，也需要用数组类型，例如：userIDList: ['user1']
      // });
      // promise.then(function (imResponse) {
      //   console.log("searchUserProfileByUserID", imResponse.data); // 存储用户资料的数组 - [Profile]
      //   state.searchUserProfileList = imResponse.data;
      // }).catch(function (imError) {
      //   console.warn('getUserProfile error:', imError); // 获取其他用户资料失败的相关信息
      // });
    },
    setSearchUserProfileList({ state }, payload) {
      state.searchUserProfileList = payload;
    },
  },
};

export default chatModule;
