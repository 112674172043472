<template>
  <Cards>
    <TopSearch :adminShow="true" :searchShow="true" @searchKey="getSearchKey"/>
  </Cards>
  <Cards>
    <div>
      <div style="position: relative">
        <div class="top_tips">
          <div><h2 class="h2_titles">{{ $t("common.customer.list") }}</h2></div>
          <div>
            <el-button
                :disabled="totals===0"
                type="danger"
                :icon="Delete"
                @click="handleClickDelete"
                v-if="isSuperAdmin"
            >{{ $t('common.delete') }}
            </el-button
            >
            <el-button type="primary" :icon="Plus" @click="toDetails('Create')">{{
                $t("common.create")
              }}
            </el-button>
            <!--          批量导入-->
            <el-button type="primary" :icon="DocumentAdd" @click="fileImport">
              {{ $t('list.customer.location.import') }}
            </el-button>
            <!--          批量导出-->
            <el-button :disabled="totals===0" type="primary" :icon="Upload" @click="fileExport" v-if="isSuperAdmin">
              {{ $t('list.customer.export.user') }}
            </el-button>
          </div>
        </div>
        <Tables
            :totals="totals"
            ref="targetTable"
            :table-data="customerList"
            :table-colum-titles="table_columns_list"
            :is-show-pages="false"
            :isShowDrawer="true"
            :isShowEdit="true"
            :isLoading="isLoading"
            @selections="handleSelectionChange"
            @getSelect="getSelectValue"
            @selectionChange="getSelectChange"
            @select-all="handleClickSelectAll"
        >
          <template #linkUserHistory>
            <div class="settings">
              <el-button type="primary" link>{{ $t("common.contact.record") }}</el-button>
            </div>
          </template>
        </Tables>
      </div>
    </div>
    <div class="pages">
      <el-pagination
          popper-class="page"
          v-model:current-page="current"
          v-model:page-size="size"
          :page-sizes="[10,30,50]"
          background
          :pager-count="4"
          layout="sizes, total, prev, pager, next ,jumper"
          v-if="totals"
          :total="totals"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      />
    </div>
  </Cards>
  <CustomerExcelImport v-if="visitable" v-model="visitable" @close="close"
                       @getCustomerList="getCustomerList"></CustomerExcelImport>

  <DeleteCustomer v-if="dialogVisiblePopover" v-model="dialogVisiblePopover" @close="closeDialog"
                  :multipleSelection="multipleSelection" :getCustomerList="getCustomerList"
                  :isSelectAll="isSelectAll" :searchValue="searchValue"></DeleteCustomer>

</template>
<script setup>
import {ref, computed, watch, nextTick, onMounted, onBeforeUnmount} from "vue";
import {useRouter} from "vue-router";
import {Delete, DocumentAdd, Plus, Upload} from "@element-plus/icons-vue";
import Cards from "./Cards.vue";
import TopSearch from "./TopComponents/TopSearch.vue";
import Tables from "./Tables/Tables.vue";
import {
  exportCustomerData,
  getMailCustomerList,
} from "@/api/customer";
import {useStore} from "vuex";
import {table_columns_list} from './map'
import {ElMessage} from "element-plus";
import CustomerExcelImport from "@/components/DiaPops/CustomerExcelImport.vue";
import {throttle} from "@/utils/mylodash"
import {i18n} from "@/lang";
import DeleteCustomer from "@/components/DiaPops/DeleteCustomer.vue";
import {flattenChildren, replaceMailMassEmailVOListWithChildren} from "@/utils/filters";
import {emailReg} from "@/utils/statusParams";


const store = useStore()
// 权限控制
const isSuperAdmin = computed(() => store.getters["user/isSuperAdmin"])


// 分页
const current = ref(1);
const size = ref(10);
const totals = ref(0);
const targetTable = ref(null)

const teamId = computed(() => store.state.email.teamID);
const selectedRows = computed(() => store.state.customer.selectedRows)
const globalSelectedRows = computed(() => store.state.customer.globalSelectedRows)
const isCounterElection = computed(() => store.state.customer.isCounterElection);
// 搜索
const searchValue = ref({});
const getSearchKey = async (value) => {
  // 重置选择
  nextTick(() => {
    isSelectAll.value = false
    targetTable.value.unCheckAllItem()
    targetTable.value.setIsSelectAll(false)
  })

  const newObj = JSON.parse(JSON.stringify(value));
  searchValue.value = newObj;
  console.log(searchValue.value, 'searchForm.value.managerId')
  // 列表数据
  await getCustomerList();
  isSelectAll.value ? targetTable.value.checkAllItem() : targetTable.value.unCheckAllItem()
};

const router = useRouter();
const multipleSelection = ref([]);
const isLoading = ref(false);

// table 选中的数组
const handleSelectionChange = (val) => {
  console.log(val, 'val')
  endIndex = val.length > 1 ? customerList.value.findIndex(item => item.id === val[val.length - 1].id) : -1;
  const startIndex = val.length > 1 ? customerList.value.findIndex(item => item.id === val[val.length - 2].id) : -1;
  console.log(endIndex, startIndex);
  if (isShiftPressed.value && val.length > 1 && endIndex !== startIndex) {
    const start = startIndex < endIndex ? startIndex : endIndex;
    const end = endIndex < startIndex ? startIndex : endIndex;
    // console.log(start, end, '===============');
    for (let i = start; i <= end; i++) {
      const row = customerList.value[i];
      if (start === i || i === end) {
        continue;
      }
      console.log(row, '更新');
      if (row) {
        targetTable.value.toggleRowSelection(row, true); // 调用表格组件的方法来更新选中状态
      }
    }
    nextTick(() => {
      multipleSelection.value = targetTable.value.getSelectionRows();
      console.log(targetTable.value.getSelectionRows(), '当前选中行');
    });
  } else {
    multipleSelection.value = val;
  }
};


const isSelectAll = ref(false)
const getSelectValue = async (val) => {
  isSelectAll.value = val
  // if (allCustomerList.value.length === 0) {
  //   await getCustomerAllList()
  // }
  console.log(isSelectAll.value, val, selectedRows.value, 'selectedRows', globalSelectedRows.value)

  // if (!isEmpty(globalSelectedRows.value[current.value])) {
  //   // 当前页数据全部勾选 / 不勾选
  //   // 取交集
  //   console.log(globalSelectedRows.value[current.value].filter(item => item.level == 1), 'globalSelectedRows.value[current.value]')
  //   const pageResult = globalSelectedRows.value[current.value].filter(item => item.level == 1)
  //   customerList.value.forEach((item) => {
  //     const isSelected = pageResult.value.some((selected) => selected.id === item.id);
  //     console.log(item, isSelected, selectedRows.value.map(item => item.id), 'isSelected')
  //     treeTable?.value.toggleRowSelection(item, isSelected);
  //   })
  // } else {
  console.log(targetTable.value, 'targetTable.value')
  customerList.value.forEach((item) => {
    targetTable?.value.toggleRowSelection(item, isSelectAll.value)
  })
}

const getSelectChange = async (val) => {
  console.log(val, '当前页勾选的值');
  // 选择 底部选择 取消 全部勾选
  await nextTick(async () => {
    // 获取当前页的页码，假设分页数据存储在 store 中
    const currentPage = current.value;

    // 获取全局勾选记录
    const globalSelectedRows = store.state.customer.globalSelectedRows || {};

    // 更新当前页的勾选数据到全局记录
    globalSelectedRows[currentPage] = val;

    // 提交更新全局勾选记录到 store
    await store.commit("customer/setGlobalSelectedRows", globalSelectedRows);

    // 合并所有页的勾选数据
    const allSelectedRows = Object.values(globalSelectedRows).flat();

    // 更新 `selectedRows` 和 `targetEmailList`
    await store.commit("customer/setSelectedRows", allSelectedRows);
  })
}

const handleClickSelectAll = async (val) => {
  console.log(val, '当前页勾选的值');
  // 获取当前页的页码，假设分页数据存储在 store 中
  const currentPage = current.value;

  // 获取全局勾选记录
  const globalSelectedRows = store.state.customer.globalSelectedRows || {};

  // 更新当前页的勾选数据到全局记录
  globalSelectedRows[currentPage] = val;

  // 提交更新全局勾选记录到 store
  await store.commit("customer/setGlobalSelectedRows", globalSelectedRows);

  // 合并所有页的勾选数据
  const allSelectedRows = Object.values(globalSelectedRows).flat();

  // 更新 `selectedRows` 和 `targetEmailList`
  await store.commit("customer/setSelectedRows", allSelectedRows);
}


// 删除按钮
const dialogVisiblePopover = ref(false)
const handleClickDelete = throttle(() => {
  if (multipleSelection.value.length === 0 && !isSelectAll.value) {
    ElMessage.warning(i18n.global.t('common.email.chooseCustomer'));
    return;
  }
  dialogVisiblePopover.value = true;
}, 2000)

const closeDialog = () => {
  targetTable.value.setIsSelectAll(false)
  targetTable.value.unCheckAllItem()
  dialogVisiblePopover.value = false;
}
/**
 * 新建客户
 * @param name
 * @param id
 * @returns {Promise<void>}
 */
const toDetails = async (name, id) => {
  let newUrl = router.resolve({
    name,
    params: {id},
    query: {contact: 1}
  });
  await store.commit('customer/setRuleForm', {
    zoneCode: "",
    customerCode: "",
    zoneAddress: "",
    customerName: "",
    setUpTime: "",
    economicNature: "",
    companyWebsite: "",
    companyAddress: "",
    phoneWayList: [],
    emailWayList: [],
    companyFax: "",
    companyRemark: "",
    customerLevel: "",
    customerType: "",
    customerTypeId: "",
    product: "",
    marketingRemark: " ",
    createBy: "",
    branchAdmin: "",
    mangerVOList: [{managerName: ''}]
  })
  await store.commit('customer/setForm', {
    phones: [{phoneNumber: "", isDefault: true}],
    emails: [{email: "", isDefault: true}],
  })
  openNewWindow(newUrl.href);
};

const openNewWindow = (url) => {
  window.open(url, "_self");
};


async function handleCurrentChange(page) {
  current.value = page
  //  重新获取数据
  await getCustomerList()
  console.log(isSelectAll.value, JSON.parse(isCounterElection.value), 'flag')
  // 全选
  if (isSelectAll.value) {
    await getSelectValue()
  }

  // 反选
  // if (JSON.parse(isCounterElection.value)) {
  //   counterElection()
  // }
}

async function handleSizeChange(pageSize) {
  size.value = pageSize;
  await getCustomerList();
  isSelectAll.value ? targetTable.value.checkAllItem() : targetTable.value.unCheckAllItem()
}


// 全部分管客户列表数据
const customerList = ref([]);
const userInfo = computed(() => store.state.user.userInfo)
/**
 * 获取客户列表
 * @returns {Promise<void>}
 */
const getCustomerList = async () => {
  isLoading.value = true;
  // const managerIdLocalStorage = JSON.parse(window.localStorage.getItem('userInfo')).id
  const {
    targetTime,
  } = searchValue.value
  const startTime = targetTime[0] ? `${targetTime[0]} 00:00:00` : null;
  const endTime = targetTime[1] ? `${targetTime[1]} 23:59:59` : null;
  if (searchValue.value.managerId === "all") {
    searchValue.value.managerId = ""
  }
  await getMailCustomerList({
    ...searchValue.value,
    startTime,
    endTime,
    pageSize: size.value,
    pageNo: current.value,
    teamId: teamId.value,
    type: 1,
  }).then((res) => {
    const {records, size, total, pages} = res.result;
    // 添加一条假的数据——————目的是为了 添加 全选按钮
    customerList.value = records;
    totals.value = total - 0;
    isLoading.value = false;
    multipleSelection.value = []
  });
};

watch(() => teamId.value, async () => {
  getCustomerList();
  await store.dispatch("groups/getUserList", {teamId: teamId.value});
})

/**
 * 客户的批量导入
 */
const visitable = ref(false);
const fileImport = async (event) => {
  visitable.value = true;
}
const close = () => {
  visitable.value = false;
}


/**
 * 客户的批量导出
 */
const fileExport = throttle(async () => {
//   是否有选中的项？
  const params = {
    customerIdList: [],
    teamId: teamId.value,
    customerOrigin: searchValue.value.customerOrigin,
    customerTypeId: searchValue.value.customerTypeId,
    customerName: searchValue.value.customerName,
    customerCode: searchValue.value.customerCode,
    email: searchValue.value.email,
    zoneAddress: searchValue.value.zoneAddress,
  }
  console.log(multipleSelection.value.length === 0, isSelectAll.value, 'show')
  if (multipleSelection.value.length === 0 || isSelectAll.value) {
    //   否则全部导出
    delete params.customerIdList
    params.userId = searchValue.value.managerId
  } else {
    //   有选中的项 就只导出选中的项
    params.customerIdList = multipleSelection.value.map(_ => _.id)
  }
  await exportCustomerData(params, {
    responseType: "blob",
  }).then(res => {
    console.log(res, 'res')
    const link = document.createElement('a');
    const blob = new Blob([res], {type: 'application/vnd.ms-excel;charset=utf-8'}); // 设置正确的字符编码
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', i18n.global.t('common.customer.info'));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
}, 1000);


// 记录历史记录
const resetSelection = async () => {
  await nextTick(() => {
    console.log(targetTable?.value, customerList.value, selectedRows.value, 'treeTable?.value')
    if (targetTable?.value && selectedRows?.value.count > 0) {
      customerList.value.forEach((item) => {
        const isSelected = selectedRows.value?.some((selected) => selected.id === item.id);
        console.log(item, isSelected, selectedRows.value.map(item => item.id), 'isSelected')
        targetTable?.value.toggleRowSelection(item, isSelected);
      })
    }
  })
}

// 当前页数据发生变化
watch(() => customerList.value, (newValue) => {
  if (newValue) {
    console.log('newValue')
    resetSelection()
  }
}, {immediate: true})


onMounted(() => {
  // Shift监听/取消监听
  document.addEventListener('keydown', handleKeyDown);
  document.addEventListener('keyup', handleKeyUp);
})

onBeforeUnmount(() => {
  document.removeEventListener('keydown', handleKeyDown);
  document.removeEventListener('keyup', handleKeyUp);
});

let endIndex = -1;
// table 选择增加 shift
const isShiftPressed = ref(false); // 标记 Shift 键是否被按下
const handleKeyDown = (event) => {
  if (event.key === 'Shift' && event.keyCode === 16) {
    // console.log("进来了-----------------", event.key, event.keyCode);
    isShiftPressed.value = true;
  }
};

const handleKeyUp = (event) => {
  if (event.key === 'Shift' && event.keyCode === 16) {
    isShiftPressed.value = false;
  }
};
</script>

<style lang="less" scoped>
@import "../../less/page.less";
@import "../../less/resetFul.less";

.table_name {
  font-size: 20px;
}

.top_tips {
  display: flex;
  justify-content: space-between;
}

.settings {
  display: flex;
  justify-content: center;
}

:deep(.el-table__cell) {
  text-align: center;
}

:deep(.el-checkbox__inner::after) {
  top: 1px !important;
  left: 4px !important;
}

.affix-container {
  text-align: center;
  height: 400px;
  border-radius: 4px;
  background: var(--el-color-primary-light-9);
}

.pages {
  margin-top: 20px !important;
}

.pages :deep(.el-pagination .el-select) {
  width: 100px !important;
}

:deep(.el-pagination) {
  position: relative !important;
  background: white !important;
  z-index: 9;
}

</style>
