<template>
  <Dialog
      v-model="dialogVisible"
      :title="userInfo.accountType===2?$t('common.sss4'):$t('common.member.add')"
      :dialogVisible="dialogVisible"
      :isHeadLine="true"
      :width="400"
      @update:dialogVisible="close"
  >
    <template #content>
      <div class="my_forms">
        <div>{{ $t("common.ph351") }}：</div>
        <div style="margin-top: 14px">
          <el-input v-model="inputUserId"
                    style="box-shadow:none !important;border: 1px solid #dddfe5;border-radius: 4px"
                    :placeholder="$t('common.ph361')" @keydown.enter.native="searchTargetUsers">
            <template #append>
              <el-button style="cursor: pointer;background: white !important;border-radius: 0px 4px 4px 0px"
                         @click="searchTargetUsers" :icon="Search"/>
            </template>
          </el-input>
        </div>
        <div style="margin-top: 20px" class="users_search">
          <template v-if="!findIsLoading">
            <template v-if="cantFindUser">
              <div style="width:100%;font-size: 12px;text-align: center;color: rgba(0, 0, 0, 0.25);">
                {{ $t('other.text.invite.user.notfind') }}
              </div>
            </template>
            <template v-else-if="!isEmpty(findTargetUser)">
              <div>
                <el-avatar :size="45" v-if="findTargetUser.avatar" @error="errorHandler">
                  <img :src="findTargetUser.avatar"/>
                </el-avatar>
                <el-avatar :size="45" v-else>
                  <img style="height: 20px; width: 20px" :src="require('@/assets/default/avatar.png')"/>
                </el-avatar>
              </div>
              <div class="users_username">
                <span>{{ findTargetUser.userName }}</span>
              </div>
            </template>
          </template>
          <template v-else>
            <div style="width: 100%;display: flex;justify-content: center;align-items: center">
              <el-icon class="is-loading">
                <Loading/>
              </el-icon>
            </div>
          </template>
        </div>

      </div>
      <div id="sendRequire">
        <el-button :disabled="!findTargetUser.id" @click="sendRequest">
          {{ $t("common.send.invitation") }}
        </el-button>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import Dialog from "@/components/Dialog.vue";
import {defineProps, ref, watch, defineEmits, computed} from "vue";
import {useRouter} from "vue-router";
import {ElMessage} from "element-plus";
import {addOutsideUser, getOutsideUser} from "@/api/groups";
import {isEmpty, throttle} from "@/utils/mylodash";
import {Search} from "@element-plus/icons-vue";
import {useStore} from "vuex";

const router = useRouter()
const emit = defineEmits()
const store = useStore()
const dialogVisible = ref(false);
const userInfo = computed(() => store.state.user.userInfo);
// team id
const teamIdTarget = computed(() => store.state.email.teamID);
const inputUserId = ref('')


const {value, teamId} = defineProps({
  value: {type: Boolean, default: false},
  teamId: {type: String, default: ''}
})

watch(() => value, (val) => {
  dialogVisible.value = val;
}, {immediate: true})


const errorHandler = () => true;
const close = () => {
  emit("close")
}


// 查询指定的用户
const findIsLoading = ref(false)
const findTargetUser = ref({})
const cantFindUser = ref(false)
const searchTargetUsers = throttle(async () => {
  findIsLoading.value = true;
  const res = await getOutsideUser({uuid: inputUserId.value})
  findIsLoading.value = false;
  if (res.result === null) {
    cantFindUser.value = true;
    findTargetUser.value = {}
  } else {
    cantFindUser.value = false
    findTargetUser.value = res.result
  }
}, 3000)


// 邀请外部成员
const sendRequest = async () => {
  const res = await addOutsideUser({
    teamId: teamId !== '' ? teamId : teamIdTarget.value,
    userId: findTargetUser.value.id
  })
  if (res.code !== 200) {
    ElMessage.error(res.message)
    return
  }
  ElMessage.success(res.message)
  close();
  inputUserId.value = ''
  findTargetUser.value = {}
  await store.dispatch('groups/getUserList', {teamId: teamIdTarget.value})
  await store.dispatch("user/getPositionByLoginList");
}
</script>


<style lang="less" scoped>

.users_search {
  display: flex;
  justify-content: center;
  align-content: center;
}

.users_username {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    margin-left: 10px;
  }
}

:deep(.el-input__wrapper) {
  box-shadow: none !important;
}

:deep(.el-avatar) {
  background: #f3f3f3 !important;
}

:deep(.el-transfer) {
  justify-content: space-between !important;

}


#sendRequire {
  text-align: center;
  margin-top: 15px;
}

</style>