<template>
  <div class="write_wrap">
    <div class="btn_list">
      <div style="display: flex">
        <ElButton size="small" type="primary" @click="() => handleSendMail('send')" :loading="loadingSend">
          {{ $t('common.group.send') }}
        </ElButton>
        <!--        <ElUpload-->
        <!--            class="upload-demo"-->
        <!--            style="margin-left: 5px"-->
        <!--            :action="action"-->
        <!--            :headers="{-->
        <!--          'X-Access-Token': token,-->
        <!--        }"-->
        <!--            :before-upload="beforeUpload"-->
        <!--            :show-file-list="false"-->
        <!--            :on-error="handleUploadError"-->
        <!--            :on-success="handleUploadSuccess"-->
        <!--            :on-progress="onProgress"-->
        <!--            multiple-->
        <!--        >-->
        <!--          <template #trigger>-->
        <!--            <ElButton size="small">上传附件</ElButton>-->
        <!--          </template>-->
        <!--        </ElUpload>-->
        <el-dropdown trigger="click">
    <span class="el-dropdown-link" style="margin-left: 5px">
     <ElButton size="small">{{ $t('common.annex.upload') }}</ElButton>
    </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <ElUpload
                    style="margin-left: 2px;width: 100%;height: 100%"
                    class="upload-demo"
                    :action="action"
                    :headers="{
                        'X-Access-Token': token,
                      }"
                    :on-progress="onProgress"
                    :show-file-list="false"
                    :before-upload="beforeUpload"
                    :on-error="handleUploadError"
                    :on-success="handleUploadSuccess"
                    multiple
                >
                  <template #trigger>
                    <span style="padding:5px 16px">{{ $t("common.cds88") }}</span>
                  </template>
                </ElUpload>
              </el-dropdown-item>
              <!--            <el-dropdown-item>上传超大附件</el-dropdown-item>-->
              <el-dropdown-item @click="handleClickSelectFileFromCloud"><span
                  style="padding:5px 16px">{{ $t("common.cloud.file.select") }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <ElButton size="small" style="margin-left: 5px" @click="changeType">
          {{ route.query.type === "1" ? $t("send.all.change.smart") : $t("send.all.change.system") }}
        </ElButton>
      </div>
      <!--      <div v-if="route.query.type === '2'" style="display: flex;align-items: center">-->
      <!--        <div style="font-weight: 400;font-size: 12px;color: rgba(0,0,0,0.65);line-height: 12px;">-->
      <!--          {{ $t('write.send.all.email') }}-->
      <!--          <span style="color: red">4999{{ $t('common.ph23') }}</span>,-->
      <!--        </div>-->
      <!--        <el-button link type="primary" size="small" style="text-decoration: underline" @click="toBuy">-->
      <!--          {{ $t("write.to.buy.group.email") }}-->
      <!--        </el-button>-->
      <!--      </div>-->
    </div>
    <div class="top">
      <div class="form">
        <el-form :model="form" label-width="100px" size="large" ref="ruleFormRef">
          <el-form-item :label="`${$t('common.email.from')}:` " prop="configId">
            <el-select
                v-model="form.configId"
                class="mailselectEl"
                remote
                allow-create
                :placeholder="$t('common.please.input')"
                style="width: 100%;"
            >
              <el-option
                  v-for="item in userMail_dropDownOptionList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>

            <!--            <div class="send_times">{{ $t('common.group.send.times') }}：60s</div>-->
          </el-form-item>
          <el-form-item :label="$t('common.email.to') + ':'" prop="to">
            <template #label>
              <div
                  style="
                  display: flex;
                  align-items: center;
                  position: relative;
                ">
                <!--                <el-icon size="16" @click="handleSetOpen">-->
                <!--                  <CaretRight v-if="!open"/>-->
                <!--                  <CaretBottom v-else/>-->
                <!--                </el-icon>-->
                <label style="text-align: right;">{{ $t('common.email.to') + ':' }} </label>
              </div>
            </template>
            <div style="display: flex;flex-direction: column;width: 100%">
              <div class="select" ref="selectEmail" style="width: 100%;max-height: 100px;overflow: scroll"
                   @scroll="handleScrollToEmail">
                <el-select
                    v-model="form.to"
                    class="mailselectEl"
                    remote
                    multiple
                    filterable
                    allow-create
                    default-first-option
                    :reserve-keyword="false"
                    :placeholder="$t('common.please.input')"
                    @focus="(event) => handleFocus(event, 'to')"
                    @blur="handleBlur"
                    @remove-tag="removeTag"
                >
                </el-select>
              </div>
              <div style="display: flex;justify-content: flex-end;height: 40px;margin-right:10px;align-items: center">

                <el-popover
                    placement="bottom"
                    :width="'max-content'"
                    trigger="hover"
                    :content="$t('write.add.customer.list')"
                    effect="dark"
                    show-after="500"
                >
                  <template #reference>
                    <div style="font-size: 12px;color: rgba(0,0,0,0.65);margin-right: 9px"><span
                        style="color: #D93737">{{ form.to.length }}</span>/{{ maxToEmailNumber }}
                    </div>
                  </template>
                </el-popover>
                <el-popover
                    placement="bottom"
                    :width="'max-content'"
                    trigger="hover"
                    :content="$t('write.file.select')"
                    effect="dark"
                    show-after="500"
                >
                  <template #reference>
                    <el-button size="small" type="primary" plain :icon="Plus" @click="showTreeTable">
                      {{ $t('common.email.addCustomer') }}
                    </el-button>
                  </template>
                </el-popover>
                <el-popover
                    placement="bottom"
                    :width="'max-content'"
                    trigger="hover"
                    :content="$t('write.send.all.email.pop')"
                    effect="dark"
                    show-after="500"
                >
                  <template #reference>
                    <el-upload
                        ref="uploadRef"
                        class="upload-demo"
                        action=""
                        :accept="['.xls','.xlsx']"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="handleUploadChange"
                    >
                      <el-button style="margin: 0px 10px" size="small" type="primary" plain :icon="Upload"
                                 @click="uploadExcel">
                        {{ $t("list.customer.location.import") }}
                      </el-button>
                    </el-upload>
                  </template>
                </el-popover>
                <el-popover
                    placement="bottom"
                    :width="'max-content'"
                    trigger="hover"
                    :content="$t('write.send.all.email.download')"
                    effect="dark"
                    show-after="500"
                >
                  <template #reference>
                    <!--                  <el-icon style="position: absolute;right:15px;color: #606266" @click="downloadFile" size="18">-->
                    <!--                    <Download/>-->
                    <!--                  </el-icon>-->
                    <el-button size="small" type="primary" plain :icon="Download" @click="downloadFileAll">
                      {{ $t('write.send.all.email.down.template') }}
                    </el-button>
                  </template>
                </el-popover>
              </div>
            </div>
          </el-form-item>
          <!--          抄送 密送-->
          <el-form-item :label="`${$t('common.email.cc')}:`" prop="cc" style="position: relative">
            <template #label>
              <div
                  style="
                  display: flex;
                  align-items: center;
                  position: relative;
                ">
                <el-icon size="16" @click="handleSetOpen">
                  <CaretRight v-if="!open"/>
                  <CaretBottom v-else/>
                </el-icon>
                <label style="text-align: right;">{{ $t('common.email.cc') }}: </label>
              </div>
            </template>
            <el-select
                v-model="form.cc"
                class="mailselectEl"
                style="width: 100%;"
                remote
                multiple
                filterable
                allow-create
                default-first-option
                :reserve-keyword="false"
                :placeholder="$t('common.please.input')"
                @focus="(event) => handleFocus(event, 'cc')"
                @blur="handleBlur"
            >
            </el-select>
          </el-form-item>
          <template v-if="open">
            <el-form-item :label="`${$t('common.email.bcc')}:`" prop="bcc">
              <el-select
                  v-model="form.bcc"
                  class="mailselectEl"
                  style="width: 100%;"
                  remote
                  multiple
                  filterable
                  allow-create
                  default-first-option
                  :reserve-keyword="false"
                  :placeholder="$t('common.please.input')"
                  @focus="(event) => handleFocus(event, 'bcc')"
                  @blur="handleBlur"
              >
              </el-select>
            </el-form-item>
          </template>
          <el-form-item :label="$t('common.subject')+':' " prop="subject" :rules="[
        {
          required: true,
          message:'',
          trigger: ['blur','change'],
        },
      ]"
          >
            <el-input
                :placeholder="$t('20212')"
                maxlength="300"
                show-word-limit
                style="width: 100%"
                class="email_theme"
                v-model="form.subject"
            >
            </el-input>
          </el-form-item>
          <div style="display: flex; align-items: center; height: 40px;overflow-x: scroll;overflow-y: hidden">
            <el-button @click="handleSelectTemplate" size="small" style="margin: 0 10px;">
              {{ $t("common.email.template.select") }}
            </el-button>
            <el-dropdown trigger="click">
              <el-button size="small" style="margin: 0px 6px 0 -4px;">{{ $t('send.all.insert.key') }}</el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="insertContent(0)" style="padding: 4px 10px !important;">
                    {{ $t('send.all.insert.com.name') }}
                  </el-dropdown-item>
                  <el-dropdown-item @click="insertContent(1)" style="padding: 4px 10px !important;">
                    {{ $t("send.all.insert.com.contact") }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-checkbox
                v-model="form.urgent"
                :true-label="1"
                :false-label="0"
                style="margin-right: 8px;margin-left: 10px"
            >
              {{ $t("common.urgent") }}
            </el-checkbox
            >
            <el-checkbox
                v-model="form.receipt"
                :true-label="1"
                :false-label="0"
                style="margin-right: 8px;"
            >
              {{ $t('common.read.receipt') }}
            </el-checkbox
            >
            <el-checkbox
                v-model="form.readTrack"
                :true-label="1"
                :false-label="0"
                style="margin-right: 8px;"
            >
              {{ $t('send.all.follow') }}
            </el-checkbox
            >
            <el-space style="font-weight: 700; font-size: 14px; margin-left: 10px; color: #606266;">
              {{ $t('common.send.timing') }}:
              <el-date-picker
                  v-model="form.sendTime"
                  type="datetime"
                  size="small"
                  style="width: 160px;"
                  :disabled-date="disabledDate"
                  format="YYYY-MM-DD HH:mm:ss"
                  value-format="YYYY-MM-DD HH:mm:ss"
                  :placeholder="$t('sys.tip.select.date')">
              </el-date-picker>
            </el-space>
            <el-checkbox
                v-model="form.followDay"
                :true-label="1"
                :false-label="0"
                style="margin-left: 10px"
            >
              {{ $t('common_followup_remind') }}
            </el-checkbox>
            <el-space style="font-weight: 700; font-size: 14px; margin-left: 10px; color: #606266;">
              <el-input
                  v-model="sendNum"
                  class="w-50 m-2"
                  placeholder=""
                  style="width: 140px;"
                  size="small"
              >
                <template #suffix>
                  <div class="suffix">
                    <el-icon class="el-input__icon" style="margin-left: auto;">
                      <Calendar/>
                    </el-icon>
                    <el-date-picker
                        class="oh"
                        v-model="sendDate"
                        type="date"
                        @change="handleSendDateChange"
                        size="small"
                        format="YYYY-MM-DD HH:mm:ss"
                        :disabled-date="disabledDate"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        :placeholder="$t('sys.tip.select.date')">
                    </el-date-picker>
                  </div>
                </template>
              </el-input>
              <!--  -->
              {{ $t('common.p2h3') }}
            </el-space>
            <el-space style="font-weight: 700; font-size: 14px; margin-left: 10px; color: #606266;">
              {{ $t("common.remark") }}：
              <el-input size="small" style="width: 200px;" v-model="form.remark"></el-input>
            </el-space>

            <!--            发送时间间隔-->
            <el-space style="font-weight: 700; font-size: 14px; margin-left: 10px; color: #606266;margin-right: 20px">
              <div style="width: max-content">发送间隔（{{ valueTime }}）秒</div>
              <el-select class="sp_select" v-model="valueTime" placeholder="Select" style="width:20px">
                <el-option
                    v-for="item in optionsTime"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
              </el-select>
            </el-space>
          </div>
        </el-form>
      </div>
      <div class="customer">
        <el-tabs model-value="customer" class="demo-tabs">
          <el-tab-pane :label="$t('common.customer')" name="customer">
          </el-tab-pane>
        </el-tabs>
        <el-input
            v-model="customerName"
            class="input"
            size="small"
            :placeholder="$t('common.please.input')"
            @keyup.enter="handleSearchCustomer"
        >
          <template #suffix>
            <el-icon class="el-input__icon" @click="handleSearchCustomer">
              <Search/>
            </el-icon>
          </template>
        </el-input>
        <div style="margin-top: 80px" v-loading="customerLoading">
        </div>
        <div class="list" @scroll="handleScroll" ref="List">
          <div class="item" v-for="(item) in customerList"
               @click="() => handleSelectCustomer(item)" :key="item.email">
            <el-popover
                placement="right"
                :width="200"
                trigger="hover"
                :content="item.email"
                effect="dark"
                popper-class="sp_list"
                show-after="500"
            >
              <template #reference>
                {{ item.name }}
              </template>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <div class="editor_wrap">
      <TinyEditor ref="tinyEditorWrite" :width="'calc(100% - 245px)'"
                  :initial-content="tinyEditorWriteContentHtml"
                  :editorId="'write'"></TinyEditor>
      <div class="files" ref="filesRef">
        <div class="files_title">{{ $t('common.file.list') }}</div>
        <div class="file_box" style="position: relative">
          <div class="files_out_box" style="position: absolute;z-index: 80">
            <div class="file" v-for="(item, index) in multipartFiles" :key="item.fileName">
              <div style="display: flex;flex-direction: column;width: 100%">
                <div style="display: flex;justify-content: space-between;align-items: center">
                  <div style="display: flex;justify-content: flex-start;align-items: center;">
                    <el-icon v-if="item.percentage===100 && item.response || item.isCloud || item.url"
                             color="rgba(0,0,0,.45)"
                             size="14">
                      <Link/>
                    </el-icon>
                    <el-icon v-else class="is-loading" color="rgba(0,0,0,.45)" size="14">
                      <Loading/>
                    </el-icon>
                    <el-popover
                        placement="right"
                        :width="'max-content'"
                        effect="dark"
                        trigger="hover"
                        :content="item.name"
                        show-after="500"
                    >
                      <template #reference>
                        <a v-if="item.response||item.isCloud|| item.url" @click="downloadFiles(item.url,item.name)"
                           class="name contents text"
                           :download="true">{{
                            item.name
                          }}</a>
                        <span v-else class="name contents text" style="color: grey;pointer-events: none">{{
                            item.name
                          }}</span>
                      </template>
                    </el-popover>
                  </div>
                  <!-- 查看-->
                  <template v-if="item.percentage===100 && item.response || item.isCloud || item.url">
                    <el-icon color="rgba(0,0,0,.45)" size="14" @click="handleDetailFile(item)">
                      <Search/>
                    </el-icon>
                    <el-icon color="rgba(0,0,0,.45)" size="14" @click="handleDelMul(index)">
                      <Delete/>
                    </el-icon>
                  </template>
                </div>
                <div style="margin-top: 4px" v-if="!item.isCloud">
                  <!--              5% 等待服务端返回-->
                  <el-progress v-if="!item.response"
                               :percentage="item.response&&uploadCompleted?100:Math.max(0,item.percentage-5)"/>
                </div>
              </div>
            </div>
          </div>
          <div class="drop_area">
            <el-upload
                class="upload-demo"
                drag
                style="padding:0px!important;"
                :show-file-list="false"
                :action="action"
                :headers="{
                                  'X-Access-Token': token,
                                }"
                :on-progress="onProgress"
                :before-upload="beforeUpload"
                :on-error="handleUploadError"
                :on-success="handleUploadSuccess"
                multiple
            >
            </el-upload>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        v-model="templateFlag"
        width="50vw"
        :z-index="97"
        :append-to-body="true"
        :close-on-click-modal="false"
        style="min-width: 400px"
    >
      <div class="modal_content">
        <div class="list">
          <el-tabs model-value="first" class="demo-tabs">
            <el-tab-pane :label="$t('common.email.template')" name="first">
              <ElTable ref="templateRef"
                       v-loading="isLoading"
                       :data="templateList"
                       :height="400"
                       @current-change="handleCurrentRowChange"
                       v-el-table-infinite-scroll="handleTableScroll"
              >
                <el-table-column prop="name" :label="$t('common.email.templateName')" width="160"
                                 show-overflow-tooltip/>
                <el-table-column prop="subject" :label="$t('common.subject')" width="auto"
                                 show-overflow-tooltip/>
                <el-table-column prop="edit" :label="$t('common.cz')">
                  <template #default="scope">
                    <el-button link type="primary" size="small" @click.stop="handleSetDefault(scope.row)"
                    >{{ scope.row.isDefault !== 1 ? $t('common.setdefault') : $t('common.rmdefault') }}
                    </el-button
                    >
                    <el-button link type="primary" size="small" @click.stop="handleView(scope.row)">
                      {{ $t('common.preview') }}
                    </el-button>
                    <el-button link type="primary" size="small" @click.stop="handleUse(scope.row)">
                      {{ $t('common.email.use') }}
                    </el-button>
                    <el-button link type="primary" size="small" @click.stop="handleEditTemplate(scope.row)">
                      {{ $t('common.edit') }}
                    </el-button>
                    <el-button link type="primary" size="small" @click.stop="handleDel(scope.row)"
                               :loading="scope.row.isLoadingDel">
                      {{ $t('common.delete') }}
                    </el-button>
                  </template>
                </el-table-column>
              </ElTable>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!--        <div class="view_html" v-html="currentTemplateMail"></div>-->
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="handleInitTemplateEditor">
            {{ $t("common.email.newTemplate") }}
          </el-button>
          <el-button @click="templateFlag = false">{{ $t('common.exit') }}</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog :append-to-body="true" :z-index="99" width="75vw"
               :title="editTemplate && editTemplate.id ? $t('common.email.editTemplate') : $t('common.email.newTemplate')"
               v-model="addTemplateFlag"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
    >
      <div class="template_name" style="display: flex;flex-direction: column">
        <div style="width: 100%">
          <div class="label">{{ $t('common.email.templateName') }}：</div>
          <el-input v-model="templateName" :placeholder="$t('common.email.template.name')"/>
        </div>
        <!--      模版主题 -->
        <div style="width: 100%;margin-top: 20px">
          <div class="label">{{ $t('common.subject') }}：</div>
          <el-input v-model="subject" :placeholder="$t('common.email.template.name')"/>
        </div>
      </div>
      <div style="height: 50vh;overflow: scroll;margin-top: 30px">
        <TinyEditor ref="tinyEditorTemplate" :width="'100%'" :initial-content="tinyEditorTemplateContentHtml"
                    :editorId="'template'"></TinyEditor>
      </div>
      <template #footer>
        <div class="dialog-footer1">
          <el-button @click="calcelEditor">{{ $t('common.cancel') }}</el-button>
          <el-button type="primary" @click="handleSaveTemplate" :loading="isLoadingAdd" :disabled="isLoading">
            {{ $t('common.save') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog :title="$t('common.preview')"
               width="75vw"
               v-model="previewFlag"
               :close-on-click-modal="false"
               :top="'3em'"
    >
      <div style="height: 500px">
        <!--        <div v-html="previewContent"></div>-->
        <iframe style="width: 100%;height: 100%" :srcdoc="previewContent" frameborder="0"></iframe>
      </div>
      <template #footer>
        <div>
          <el-button
              @click="previewFlag = false">{{ $t('common.close') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
  <TreeContact :isChecked="form.to" v-if="visitable" v-model="visitable" @close="close"
               @addToEmail="getAddTo"></TreeContact>
  <UploadExcel v-if="uploadExcelVisitable" v-model="uploadExcelVisitable" @close="closeUploadExcel"
               @addTo="getAllEmails" :showTargetEmails="showTargetEmails"></UploadExcel>

  <!--    从云文件中选择-->
  <WriteMailSelectTargetFile v-if="writeSelectTargetFileDialog" v-model="writeSelectTargetFileDialog"
                             @close="closeWriteSelectTargetFileDialog" :type="1"
                             @selectTargetFile="getSelectTargetFile" :isHeadLine="true"></WriteMailSelectTargetFile>
</template>

<script>
import {onMounted, computed, reactive, ref, nextTick, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import dayjs, {Dayjs} from 'dayjs';
import {Calendar, CaretBottom, CaretRight, Download, Plus, Search, Upload} from '@element-plus/icons-vue';
import {useStore} from 'vuex';
import {getEmailCustomer} from '@/api/customer';
import {ElMessage} from 'element-plus';
import {
  getMailConfigList,
  saveDraft,
  getDefaultMailConfig as requestDefaultMailConfig,
  sendMassMail,
} from "@/api/index";
import {
  requestMailSendTemplate,
  requestMailSendTemplateAdd,
  requestMailSendTemplateDelete,
  requestMailSendTemplateEdit,
  requestCommitEmailAudit,
  requestDefaultSendTemplate,
} from '@/api/mail';
import {requestCustomerEmailByCustomerId} from '@/api/customer.js';
import {
  audioFormat, downloadFile,
  excelFormat,
  imgFormat,
  pdfFormat,
  pptFormat,
  requestUploadFile,
  textFormat,
  videoFormat,
  wordFormat
} from '@/utils/file';
import {getToken} from '@/utils/auth';
import TreeContact from "@/components/DiaPops/TreeContact.vue";
import UploadExcel from "@/components/DiaPops/UploadExcel.vue";
import {throttle, debouncePlus} from "@/utils/mylodash"
import WriteMailSelectTargetFile from "@/views/Cloud/Components/WriteMailSelectTargetFile.vue";
import {getMassTaskDetailByTaskCode} from "@/api/groupSend";
import {emailReg, TIME_OUT} from "@/utils/statusParams";
import {i18n} from "@/lang";
import {clearFormat, soundCode} from "@/utils/wangEditor";
import TinyEditor from "@/components/TinyEditor/index.vue";
import {api as viewerApi} from "v-viewer";
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
  computed: {
    i18n() {
      return i18n
    },
    Download() {
      return Download
    },
    Upload() {
      return Upload
    },
    Plus() {
      return Plus
    }
  },
  components: {
    CaretBottom,
    CaretRight,
    TinyEditor,
    WriteMailSelectTargetFile,
    UploadExcel,
    TreeContact,
    Calendar,
    Search,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const teamId = computed(() => store.state.email.teamID);
    const filesRef = ref(null);
    const customerName = ref('');
    const customerLoading = ref(false);
    const maxToEmailNumber = ref(10000);
    const form = reactive({
      // 密送列表
      bcc: [],
      // 抄送列表
      cc: [],
      followDay: 0,
      // 附件
      // multipartFiles: [
      //   // {
      //   //   name: '',
      //   //   url: ''
      //   // }
      // ],
      // 0 不回执, 1 回执
      receipt: 0,
      readTrack: 1,
      // 回复地址
      replyTo: "",
      // 发送时间
      sendTime: "",
      // 邮件主题
      subject: "",
      // 内容
      text: "",
      // 邮件类型
      textType: "TEXT/HTML; charset=utf-8",
      to: [],
      // 0 不紧急，1紧急
      urgent: 0,
      userId: 0,
      configId: '',
      remark: '',
    });
    const userMail_dropDownOptionList = ref([]);
    const templateFlag = ref(false);
    const addTemplateFlag = ref(false);
    const previewFlag = ref(false);
    const templateList = ref([]);
    const currentTemplateMail = ref('');
    const previewContent = ref('');
    const editTemplate = ref(null);
    const templateName = ref('');
    const subject = ref("")
    const mainEditor = ref(null);
    const templateEditor = ref(null);
    const isSaved = ref(false);
    const open = ref(false);
    const customerList = ref([]);
    const multipartFiles = ref([]);
    const activeSelectProp = ref(null);
    const configMapSysUser = reactive({});
    const configIdMapUserName = reactive({});
    const sendNum = ref(0);
    const sendDate = ref('');
    const userNameMap = reactive({});
    const action = ref(`${process.env.VUE_APP_BASE_API}/sys/oss/qiniu/upload`);
    const selectTargetValue = ref('');
    // 从 excel 中解析出来的 email
    const showTargetEmails = ref([])
    const selectTargetValueOptions = ref([{
      value: 'Option1',
      label: 'Option1',
    },
      {
        value: 'Option2',
        label: 'Option2',
      },
      {
        value: 'Option3',
        label: 'Option3',
      },])


    const writeSelectTargetFileDialog = ref(false);

    // 收件人 数组
    const selectEmail = ref()
    const scrollTop = ref(0)
    const removeTag = (item) => {
      console.log('remove', item)
      nextTick(() => {
        selectEmail.value.scrollTop = scrollTop.value; // 确保 DOM 更新后再恢复滚动位置
      });
    }

    const handleScrollToEmail = (e) => {
      console.log(e, 'e', scrollTop.value)
      scrollTop.value = e.target.scrollTop; // 记录当前滚动位置
    }
    // 云文件选择
    const handleClickSelectFileFromCloud = () => {
      writeSelectTargetFileDialog.value = true
    }
    const closeWriteSelectTargetFileDialog = () => {
      writeSelectTargetFileDialog.value = false
    }

    // 获取选中的文件
    const getSelectTargetFile = (value) => {
      const targetArrays = value.map(item => ({
        url: item.fileExtendPath,
        name: item.fileName,
        extendSize: item.fileSize,
        isCloud: true
      }))
      multipartFiles.value = [...targetArrays, ...multipartFiles.value]
      closeWriteSelectTargetFileDialog()
    }

    const downloadFiles = (url, filename) => {
      fetch(url.replace(/^http/, "https"))
          // record.doc_url为文件url地址
          .then((res) => res.blob())
          .then((blob) => {
            downloadFile(blob, filename);
          })
          .catch((error) => {
            ElMessage.error(i18n.global.t('download.failed'));
          });
    }


    const beforeUpload = (file) => {
      console.log('文件：', file)
      var FileExt = file.name.replace(/.+\./, "")
      const isLt5M = file.size / 1024 / 1024 < 100
      var extension = ['exe', 'iso'].indexOf(FileExt.toLowerCase()) === -1
      if (!extension) {
        ElMessage({
          type: 'warning',
          message: i18n.global.t('send.all.upload.ex')
        })
        return false
      }
      if (!isLt5M) {
        ElMessage({
          type: 'warning',
          message: i18n.global.t('send.all.upload.over.size')
        })
        return false
      }
    }


    // 上传excel
    const uploadExcelVisitable = ref(false)
    const uploadExcel = () => {
      //   // uploadExcelVisitable.value = true;
    }
    const closeUploadExcel = () => {
      uploadExcelVisitable.value = false;
      showTargetEmails.value = []
    }

    //选择联系人
    const visitable = ref(false);

    const close = () => {
      visitable.value = false;
    }

    const showTreeTable = () => {
      visitable.value = true;
    }

    // 批量添加邮箱
    const checkoutEmailList = ref([])
    const getAddTo = (val) => {
      console.log(val, 'val', form.to)
      form.to = Array.from(new Set([...form.to, ...val]))
      console.log('val', val)
      checkoutEmailList.value = val;
      close();
    }

    const excelTable = ref([])
    const getAllEmails = (val) => {
      console.log(val, form.to)
      excelTable.value = val;
      form.to = Array.from(new Set([...form.to, ...val.map(item => item.email)]));

      console.log(form.to, 'ffff')
      closeUploadExcel()
    }

    watch(() => form.to, (val) => {
      if (val.length > maxToEmailNumber.value) {
        ElMessage.warning(i18n.global.t('send.all.upload.add.target.email'))
        form.to = form.to.slice(0, maxToEmailNumber.value)
      }
    })

    const downloadFileAll = () => {
      // alert('download files')
      const filename = '群发列表.xlsx'
      let a = document.createElement('a');
      a.href = '/static/' + filename;
      //路径中'/'为根目录，即index.html所在的目录
      a.download = name;
      a.click();
    };

    // 插入关键字
    function insertContent(flag) {
      const editor = tinyEditorWrite.value.tinyMCEEditor.getEditor()
      if (editor) {
        if (flag === 0) {
          editor.insertContent('{{CUSTOMER_NAME}}')
        } else if (flag === 1) {
          editor.insertContent('{{CONTACT_NAME}}')
        }
      }
    }

    const customerPages = ref(1);
    const customerPageSize = ref(15)
    const customerTotalPages = ref(1)
    const customerIsCurrent = ref(false)
    const customerTotal = ref(0)

    async function getAllCustomer() {
      customerLoading.value = true;
      let payload = {
        teamId: teamId.value,
        customerName: customerName.value,
        pageNo: customerPages.value,
        pageSize: customerPageSize.value
      }
      // if (customerName) {
      //   delete payload.customerName
      // }
      const result = await getEmailCustomer(payload);
      customerLoading.value = false;
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      if (!customerIsCurrent.value) {
        customerList.value = result.result.records || [];
      } else {
        customerList.value = [...customerList.value, ...result.result.records || []]
      }
      customerTotalPages.value = result.result.pages
      customerTotal.value = result.result.total;
      console.log('客户列表', result);
    }

    /*
客户列表分页
 */
    const refScrollTop = ref(null)
    const List = ref(null)
    const handleScroll = debouncePlus(async (event) => {
      const element = event.target;
      console.log("scroll", element.scrollHeight, element.scrollTop, element.clientHeight)
      refScrollTop.value = element.scrollTop;
      const tolerance = 5;
      if (element.scrollTop >= (element.scrollHeight - element.clientHeight - tolerance)) {
        if (customerTotalPages.value === customerPages.value) {
          return;
        } else {
          customerIsCurrent.value = true
          customerPages.value++;
          await getAllCustomer()
        }
      }
    }, 500)

    const resetCurrentAboutCustomer = () => {
      customerIsCurrent.value = false
      if (customerPages.value * customerPageSize.value >= customerTotal.value) {
        if (customerPages.value) {
          customerPages.value = 1;
        }
      }
    }


    function filterString(inputString) {
      // 使用正则表达式匹配括号中的内容以及括号外的内容
      const regex = /^(.*?)\((.*?)\)(.*?)$/;
      const matches = inputString.match(regex);

      // 如果匹配成功，返回过滤后的字符串
      if (matches && matches.length === 4) {
        const prefix = matches[1].trim(); // 括号前的部分
        const suffix = matches[3].trim(); // 括号后的部分
        return `${prefix}(${suffix})`;
      } else {
        // 如果匹配失败，返回原始字符串
        return inputString;
      }
    }

    async function getCustomerEmailByCustomerId() {
      customerLoading.value = true;
      const result = await requestCustomerEmailByCustomerId({
        customerId: route.query.customerId,
        teamId: teamId.value,
        customerName: customerName.value
      });
      customerLoading.value = false;
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      // const resetFullData = result.result.map(item => {
      //   return {newSet: filterString(item.name), ...item}
      // })
      customerList.value = result.result;
      // console.log('查询客户下的所有邮箱列表', result);
    }

    async function handleSearchCustomer() {
      console.log(customerName.value, 'customerName.value')
      customerIsCurrent.value = false;
      customerPages.value = 1;
      route.query.customerId ? await getCustomerEmailByCustomerId() : await getAllCustomer();
      // scroll 重置高度
      List.value.scrollTop = 0;
    }

    const templatePageNo = ref(1);
    const templatePageSize = ref(10)
    const isCurrent = ref(false)
    const pages = ref(1);
    const total = ref(0);
    const isLoading = ref(false)
    const templateRef = ref(null)

    async function getMailTemplateList() {
      isLoading.value = true
      try {
        const result = await requestMailSendTemplate({
          pageNo: templatePageNo.value,
          pageSize: templatePageSize.value,
          teamId: teamId.value
        });
        const {records} = result.result;
        if (!isCurrent.value) {
          templateList.value = records.map(item => ({...item, isLoadingDel: false}));
        } else {
          templateList.value = [...templateList.value, ...records.map(_ => ({..._, isLoadingDel: false}))];
        }
        pages.value = result.result?.pages;
        total.value = result.result?.total;
        isLoading.value = false
        console.log('获取邮件模板', records);
      } catch (e) {
        isLoading.value = false
      }
    }

    // 用户是否开始滚动 标识
    const scrollStartFlag = ref(false)
    const scrollBehavior = () => {
      scrollStartFlag.value = true;
    }

    const handleTableScroll = async () => {
      console.log('scroll',)
      //  用户开始滚动
      // await store.commit("cloud/changeDisabled", false);
      if (scrollStartFlag.value) {
        if (templatePageNo.value === pages.value) {
          return;
        } else {
          isCurrent.value = true;
          templatePageNo.value++
          await getMailTemplateList();
        }
      } else {

      }
    }

    const resetCurrentAbout = () => {
      isCurrent.value = false
      if (templatePageNo.value * templatePageSize.value >= total.value) {
        if (templatePageNo.value) {
          templatePageNo.value = 1;
        }
      }
    }

    function handleCurrentRowChange(row) {
      console.log('click current', row);
      currentTemplateMail.value = row?.textContent;
    }

    function formatText(list, type) {
      console.log(list, type);
      if (!list || !list.length) {
        return '';
      }
      const infoMap = list.reduce((prev, item) => {
        if (!prev[item.mailType]) {
          prev[item.mailType] = [];
        }
        prev[item.mailType].push(item.mail);
        return prev;
      }, {});
      return infoMap[type] ? infoMap[type].join(',') : '';
    }

    // 设为默认
    async function handleSetDefault(row) {
      const result = await requestMailSendTemplateEdit({
        id: row.id,
        isDefault: row.isDefault === 1 ? 0 : 1,
        teamId: teamId.value
      });
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      ElMessage.success(result.result);
      await resetCurrentAbout()
      await getMailTemplateList();
      templateRef.value.setScrollTop(0)
      console.log('设为默认', result);
    }

    const TargetEmailTemplate = ref("")
    const TargetEmailTemplateSubject = ref("")

    async function getDefaultEmailTemplate() {
      const result = await requestDefaultSendTemplate({teamId: teamId.value});
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      if (result.result) {
        TargetEmailTemplate.value = result.result?.textContent || ""
        if (tinyEditorWrite.value.tinyMCEEditor.getEditor()) {
          await nextTick(() => {
            tinyEditorWriteContentHtml.value = result.result?.textContent || ""
            tinyEditorWrite.value.tinyMCEEditor.getEditor().setContent(result.result?.textContent || "")
            TargetEmailTemplateSubject.value = result.result?.subject || ""
          })
        }
      }
    }

    function handleView(row) {
      previewContent.value = row?.textContent;
      previewFlag.value = true;
    }

    const targetDetailObj = ref("")

    async function handleUse(row) {
      await nextTick(() => {
        // tinyEditorWriteContentHtml.value = row?.textContent + targetDetailObj.value ? targetDetailObj.value : ``
        tinyEditorWrite.value.tinyMCEEditor.getEditor().setContent((row.textContent ? row.textContent : '') + (targetDetailObj.value !== "" ? targetDetailObj.value : ""))
        TargetEmailTemplateSubject.value = row?.subject
        // 主题
        if (TargetEmailTemplateSubject.value !== "" && TargetEmailTemplateSubject.value !== null) {
          form.subject = TargetEmailTemplateSubject.value
        }
      })
      templateFlag.value = false;
    }

    async function handleDel(row) {
      row.isLoadingDel = true
      try {
        const result = await requestMailSendTemplateDelete({id: row.id, teamId: teamId.value});
        if (result.code !== 200) {
          ElMessage.error(result.message);
        } else {
          ElMessage.success(result.result);
          await resetCurrentAbout()
          await getMailTemplateList();
          templateRef.value.setScrollTop(0)
        }
      } catch (e) {
        ElMessage.error(e);
      } finally {
        row.isLoadingDel = false
      }
    }

    async function handleInitTemplateEditor() {
      addTemplateFlag.value = true;
      editTemplate.value = null;
      templateName.value = "";
      subject.value = ""
      await nextTick(() => {
        // 监听更新
        tinyEditorTemplateContentHtml.value = ""
        // 内容更新
        tinyEditorTemplate.value?.tinyMCEEditor?.getEditor().setContent("")
      })
    }

    async function handleEditTemplate(row) {
      console.log(row, '编辑');
      editTemplate.value = row;
      addTemplateFlag.value = true;
      templateName.value = row.name;
      subject.value = row.subject
      await nextTick(() => {
        tinyEditorTemplateContentHtml.value = row?.textContent;
      })
    }

    // 获取用户邮箱列表
    function initUserMailList() {
      // currentMailId = this.mailConfigId;
      return getMailConfigList({teamId: teamId.value}).then((res) => {
        console.log(res, '获取用户邮箱列表');
        if (res.code === 200) {
          // const configId = res.data.data[0].id
          res.result = res.result || [];
          const arr = [];
          res.result.forEach((item) => {
            configMapSysUser[item.id] = item.sysUserId;
            configIdMapUserName[item.username] = item.id;
            userNameMap[item.id] = item.username;
            arr.push({label: item.username, value: item.id});
          });
          userMail_dropDownOptionList.value = arr;
        }
      });
    }

    async function getDefaultMailConfig() {
      const result = await requestDefaultMailConfig({teamId: teamId.value});
      console.log('默认邮箱', result);
      if (result.code !== 200) {
        ElMessage.error(result.msg);
        return;
      }
      console.log(result, 'result')
      const {id, username, sysUserId} = result.result;
      console.log('默认邮箱', result);
      form.configId = id;
      form.userId = sysUserId;
      form.from = username;
    }

    const isLoadingAdd = ref(false)

    const handleSaveTemplate = throttle(async () => {
      const html = tinyEditorTemplate.value.tinyMCEEditor.getEditor().getContent()
      const requestApi = editTemplate.value ? requestMailSendTemplateEdit : requestMailSendTemplateAdd;
      const params = editTemplate.value ? {
        id: editTemplate.value.id,
        name: templateName.value,
        subject: subject.value,
        textContent: html,
        teamId: teamId.value,
      } : {name: templateName.value, subject: subject.value, teamId: teamId.value, textContent: html};
      let result = null;
      try {
        isLoadingAdd.value = true;
        result = await requestApi(params);
        isLoadingAdd.value = false;
      } catch (e) {
        isLoadingAdd.value = false;
      }
      console.log(result, '添加模板');
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      ElMessage.success(result.result);
      templateName.value = '';
      subject.value = ''
      if (!editTemplate.value) {
        tinyEditorTemplate.value.tinyMCEEditor.getEditor().setContent("")
      }
      addTemplateFlag.value = false;
      editTemplate.value = null;
      await resetCurrentAbout()
      await getMailTemplateList();
      templateRef.value.setScrollTop(0)
    }, TIME_OUT)

    function disabledDate(date) {
      return date.getTime() < Date.now();
    }

    async function handleSelectTemplate() {
      templateFlag.value = true;
      templatePageNo.value = 1;
      await getMailTemplateList()
      templateRef.value && templateRef.value.$refs.bodyWrapper.addEventListener("mousewheel", scrollBehavior, {passive: true})
    }

    const ruleFormRef = ref(null)

    // 判断是否是对象
    function isObject(value) {
      return Object.prototype.toString.call(value) === '[object Object]';
    }

    const mapRecipients = (recipients, type) => {
      const customerListMap = customerList.value.reduce((prev, item) => {
        prev[item.email] = item;
        return prev;
      }, {});

      return recipients.map(item => {
        const mappedItem = customerListMap[item] ? {...customerListMap[item]} : {name: item.trim(), email: item.trim()};
        return {
          ...mappedItem
        };
      });
    };

    /**
     * 发送、保存邮件
     * @param type  {String}  ['send': 发送, 'save': 保存，examine: 审核]
     * @param cb    {Function || null}  回调函数
     */
    const loadingSend = ref(false)
    const handleSendMail = throttle(async (type, cb = null) => {
      ruleFormRef.value?.validate((valid, fields) => {
        if (valid) {
          if (!form.configId) {
            ElMessage.error(i18n.global.t('send.all.target.username'));
            return;
          }
          if (form.to.length === 0) {
            ElMessage.error(i18n.global.t('common.email.recipientNotEmpty'))
            return
          }
          const customerListMap = customerList.value.reduce((prev, item) => {
            prev[item.email] = item;
            return prev;
          }, {});
          // 不在客户筛选列表 且不是 重发
          // const fromEmail = excelTable.value.map(item => item.email)
          // form.to.map(item => {
          //   console.log(item, 'item', fromEmail.includes(item))
          // })
          // console.log('fromEmail', fromEmail)
          // 邮件内容
          const data = {
            ...form,
            subject: form.subject.trim(),
            to: form.to.map(item => {
              if ((!customerListMap[item] || excelTable.value.find(_ => _.email === item)) && type !== 'reSent') {
                const excelItem = excelTable.value.length !== 0 ? excelTable.value.find(_ => _.email === item) : null;
                return {
                  ...(excelItem || {email: item, name: item})
                };
              } else {
                return {email: item, name: item};
              }
            }),
            flag: route.query.isSelectAll === 'true' ? true : false,
            // sendStatus: route.query.sendStatus === "" ? 0 : route.query.sendStatus,
            // cc: form.cc.map(item => !customerListMap[item] ? {...excelTable.value.find(_ => _.email === item)} : {...customerListMap[item]}),
            // bcc: form.bcc.map(item => !customerListMap[item] ? {...excelTable.value.find(_ => _.email === item)} : {...customerListMap[item]}),
            cc: form.cc ? mapRecipients(form.cc, "cc") : [],
            bcc: form.bcc ? mapRecipients(form.bcc, "bbc") : [],
            multipartFiles: multipartFiles.value,
            userId: configMapSysUser[form.configId],
            from: userNameMap[form.configId],
            text: tinyEditorWrite.value.tinyMCEEditor.getEditor().getContent(),
            teamId: teamId.value,
            massSendSecond: valueTime.value,
            followDay: followDayFlag.value === 1 ? trueTargetFollowDay.value : 0,
          };
          if (route.query.type) {
            data.massMailType = route.query.type * 1
          }
          if (route.query.taskCode) {
            data.taskCode = route.query.taskCode
          }
          console.log(data, '发送', configMapSysUser);
          // return;
          const requestApi = type === "send" ? sendMassMail : type === 'examine' ? requestCommitEmailAudit : saveDraft;
          const infoText = type === "send" ? i18n.global.t('common.email.list.sendSuccess') : i18n.global.t('sys.message.success.save');
          loadingSend.value = true
          requestApi(data)
              .then(async (res) => {
                loadingSend.value = false
                if (res.code === 200) {
                  if (type === "examine") {
                    ElMessage.success(infoText);
                    // localStorage.setItem('activeMenu', '/email/examine')
                    router.push('/email/examine')
                  }
                  // router.replace('/email/inbox');
                  if (type !== "examine") {
                    // 邮件发送成功
                    router.push({
                      path: '/success',
                      query: {
                        id: route.query.id,
                        customerId: route.query.customerId,
                        to: JSON.stringify(data.to),
                        text: infoText,
                        flag: route.query.flag,
                        groupType: route.query.type
                      }
                    })
                  }
                  if (cb) {
                    cb("success");
                  } else {
                    if (type === "send") {
                      // 发送成功 跳转
                      // this.$emit("changeRoute", {});
                    }
                  }
                }
              })
              .catch((e) => {
                loadingSend.value = false
                console.log(e, 'e')
              })
              .finally(() => {
                // this.loading = false;
                loadingSend.value = false
              });
        } else {
          return false
        }
      })
    }, TIME_OUT)

    function handleUploadError(error) {
      console.log('上传失败', error);
    }

    const uploadCompleted = ref(false)

    function handleUploadSuccess(e) {
      console.log('上传成功', e.result, multipartFiles.value);
      if (e.code === 200) {
        uploadCompleted.value = true;
        const res = mergeArrays(multipartFiles.value, [e.result])
        console.log(res, 'res')
        res.forEach(file => {
          file.extendSize = file.size;
        });
        multipartFiles.value = res;
      }
    }

    // 合并数组并处理重复项
    function mergeArrays(array1, array2) {
      // 合并数组并处理重复项
      return array1.map((item1) => {
        const item2 = array2.find(
            (item2) => item2.name === item1.name && item2.name === item1.name
        );
        if (item2) {
          // 如果 filename 和 name 相同，则执行特定操作
          return {...item1, ...item2};
        } else {
          // 如果找不到匹配项，则保留原始数据
          return item1;
        }
      });
    }

    // 文件进度
    function onProgress(progressEvent, file) {
      if (multipartFiles.value.filter(item => item.uid === file.uid).length === 0) {
        multipartFiles.value.push(file)
      }
      // 计算上传进度百分比
      file.percentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    }

    function handleDelMul(index) {
      multipartFiles.value.splice(index, 1);
    }

    function handleSelectCustomer(item) {
      if (!activeSelectProp.value) {
        return;
      }
      if (!form[activeSelectProp.value].includes(item.email)) {
        form[activeSelectProp.value].push(item.email);
      }
      console.log('click', item, form[activeSelectProp.value]);
    }

    function handleFocus(event, type) {
      console.log('聚焦', type);
      activeSelectProp.value = type;
    }

    function originalEmailTemp(info) {
      let {from, to, cc, subject, content, createTime} = info;
      let ccTmp = cc.length ? `<div><b>Cc:</b>&nbsp;${cc.split(',').reduce((t, n) => t + `;&nbsp;${n}`)}</div>` : ''
      let replyTemplate = `
					<div><br /></div>
					<div style="font-size: 12px;background:#F5F5F5;padding:8px;color:#444;"><div>
					<div style="font-size: 12px;font-family: Arial Narrow;padding:2px 0 2px 0;">------------------ Original Message ------------------</div>
						<b>From:</b>&nbsp;${from}</div>
						<div><b>Date:</b>&nbsp;${createTime}</div>
						<div><b>To:</b>&nbsp;${to.split(',').reduce((t, n) => t + `;&nbsp;${n}`)}</div>
						${ccTmp}
						<div><b>Subject:</b>&nbsp;${subject}</div>
					</div>
					<div><br /></div>
					${content}
				`
      return replyTemplate;
    }

    async function getDetail() {
      let payload = {massTaskId: route.query.massTaskId, teamId: teamId.value}
      if (route.query.ruleForm) {
        console.log(JSON.parse(route.query.ruleForm), 'route.query.ruleForm')
        payload = {...payload, ...JSON.parse(route.query.ruleForm)}
      }
      const result = await getMassTaskDetailByTaskCode(payload);
      if (result.code !== 200) {
        ElMessage.error(result.message);
        return;
      }
      const type = route.query.typeFlag;
      // const mailRecipientVOList = result.result.mailRecipient || [];
      const mailExtendList = result.result.mailExtendList || [];
      const mailRecipientVOList = result.result.mailRecipient || []
      const mailBody = result.result.mailBody || {};
      const personInfo = mailRecipientVOList.reduce((prev, item) => {
        if (!prev[item.mailType]) {
          prev[item.mailType] = [];
        }
        prev[item.mailType].push(item);
        return prev;
      }, {});

      // 指定重发的email
      let targetToResendEmailArray = null;
      if (route.query.emailArray) {
        targetToResendEmailArray = JSON.parse(route.query.emailArray)
      }
      const flags = route.query.sendStatus === '1' ? "8" : route.query.sendStatus === '2' ? "7" : route.query.sendStatus === '3' ? "5" : "all"

      console.log(personInfo, mailRecipientVOList, result.result, '收件人等信息', formatText(mailRecipientVOList, 'to').split(","));
      // 详情 和 上传 excel 数据 容器
      excelTable.value = mailRecipientVOList.filter(item => item.mailType === "to").map(_ => ({
        name: _.name,
        email: _.mail
      }))
      //========================= 默认回复 ==========================
      const formToArray = formatText(mailRecipientVOList, 'to').split(",");
      console.log(form.configId, personInfo, route.query.from,
          'form.configIdform.configIdform.configIdform.configId'
      )
      // form.configId = personInfo.to ? personInfo.to[0].configId : '';
      form.to = type === 'reSentSingle' ? targetToResendEmailArray : route.query.flag === "again" ? [] : type === 'reSent' && !route.query.isSelectAll ? (formToArray.length > 0 ? formToArray : []) : route.query.isSelectAll === 'true' ? (formToArray.length > 0 ? formToArray : []) : (formToArray.length >= 1 ? targetToResendEmailArray : []);
      form.cc = personInfo.cc ? personInfo.cc.map(item => item.mail) : [];
      form.bcc = type === 'reSent' || type === 'reSentSingle' ? [] : personInfo.bcc ? personInfo.bcc.map(item => item.mail) : [];
      form.urgent = mailBody.urgent ? 1 : 0;
      form.receipt = mailBody.receipt ? 1 : 0;
      form.sendTime = mailBody.sendTime;
      followDayFlag.value = Math.ceil(Math.abs((new Date(mailBody.followTime).getTime() - new Date().getTime()) / 1000 / 3600 / 24)) > 0 ? 1 : 0;
      form.followDay = mailBody.followTime !== null ? Math.ceil(Math.abs((new Date(mailBody.followTime).getTime() - new Date().getTime()) / 1000 / 3600 / 24)) : 0
      form.remark = mailBody.remark;
      // mailBody.content = mailBody.content.replace(/(\r\n)|(\n)/g, '<br />');
      // mainEditor.value.txt.html(mailBody.content);
      form.content = type === 'reSent' || type === 'reSentSingle' ? mailBody.content : `<br><br>${originalEmailTemp({
        ...mailBody,
        subject: type === 'forward' ? `Fw:` + mailBody.subject : mailBody.subject,
        cc: formatText(mailRecipientVOList, 'cc'),
        to: formatText(mailRecipientVOList, 'to'),
      })}`;
      // 追加模版 和 原有内容
      targetDetailObj.value = form.content
      await nextTick(() => {
        tinyEditorWriteContentHtml.value = TargetEmailTemplate.value + form.content
      })
      multipartFiles.value = mailExtendList.map(item => ({
        name: item.name,
        fileName: item.imgUrl,
        extendSize: item.extendSize,
      }));

      console.log(form.configId, 'form.configIdform.configIdform.configIdform.configId')
      form.subject = route.query.flag === "again" ? [] : mailBody.subject;
      if (type === 'reSent' && type === 'reSentSingle') {
        let index = null;
        // console.log([route.query.from], ' [route.query.from]')
        [route.query.from].forEach(item => {
          index = userMail_dropDownOptionList.value.findIndex(el => el.label === item.mail);
        });
        if (userMail_dropDownOptionList.value[index]) {
          form.configId = configIdMapUserName[userMail_dropDownOptionList.value[index].label];
        }
      }
    }


    const trueTargetFollowDay = ref(0)
    const followDayFlag = ref(0);
    watch(() => sendNum.value, (newVal) => {
      console.log(newVal, 'newVal')
      followDayFlag.value = newVal > 0 ? 1 : 0;
      trueTargetFollowDay.value = newVal - 0;
      if (!Number(newVal)) {
        return;
      }
      const curDate = new Date().setHours(0, 0, 0, 0);
      const totalDate = new Date(curDate + Number(newVal) * 24 * 60 * 60 * 1000);
      sendDate.value = dayjs(totalDate).format('YYYY-MM-DD');
    });

    function handleSendDateChange(val) {
      console.log(val, 'val');
      const counts = new Date(val).setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0);
      sendNum.value = Math.floor(counts / (24 * 60 * 60 * 1000));
      form.followDay = sendNum.value > 0 ? 1 : 0;
      console.log(counts / (24 * 60 * 60 * 1000), '数量');
    }

    function handleSetOpen() {
      open.value = !open.value;
      nextTick(() => {
        const editorWrap = document.getElementsByClassName('editor_wrap')[0];
        const height = editorWrap.clientHeight - 42;
        mainEditor.value.change.target.parentNode.style.height = height + 'px';
        mainEditor.value.change.target.parentNode.style.minHeight = height + 'px';
        mainEditor.value.change.target.style.height = height + 'px';
        mainEditor.value.change.target.style.minHeight = height + 'px';
      })
    }

    watch(() => teamId.value, () => {
      console.log('组件团队ID 监听', teamId.value);
      resetCurrentAboutCustomer()
      route.query.customerId ? getCustomerEmailByCustomerId() : getAllCustomer();
      getDefaultMailConfig();
    });


    const tinyEditorWrite = ref(null)
    const tinyEditorWriteContentHtml = ref('')

    const tinyEditorTemplate = ref(null)
    const tinyEditorTemplateContentHtml = ref("")


    onMounted(async () => {
      await initUserMailList();
      console.log('路由信息', route.query.massTaskId, route.query.id, userMail_dropDownOptionList);
      if (teamId.value) {
        route.query.customerId ? getCustomerEmailByCustomerId() : getAllCustomer();
        await getDefaultMailConfig();
        if (route.query.mailType !== '2' && route.query.mailType !== '3') {
          await getDefaultEmailTemplate();
        }
      }
      if (route.query.massTaskId) {
        await getDetail();
      }
      if (TargetEmailTemplateSubject.value !== "" && TargetEmailTemplateSubject.value !== null) {
        form.subject = TargetEmailTemplateSubject.value
      }
    });

    const changeType = debouncePlus(() => {
      router.push({
        path: '/email/send_all',
        query: {id: route.query.id, customerId: route.query.customerId, type: route.query.type === "1" ? 2 : 1}
      })
    }, 1000)


    const toBuy = () => {

    }


    const isEmailReg = ref(false)

    function handleBlur() {
      const targetArray = form.to.filter(item => !emailReg.test(item.trim()));
      console.log(form.to, targetArray, 'form.to,blur', form.to.filter(item => emailReg.test(item)), targetArray, targetArray.length)
      if (targetArray.length >= 1) {
        ElMessage.warning(i18n.global.t('send.all.delete.no.email'))
        form.to = form.to.filter(item => emailReg.test(item.trim())).map(item=>item.trim())
        isEmailReg.value = true
      } else {
        isEmailReg.value = true;
      }
    }

    const calcelEditor = () => {
      editTemplate.value = null;
      addTemplateFlag.value = false;
      // tinyEditorTemplate.value.tinyMCEEditor.getEditor().setContent("")
    }


    // 附件预览
    const handleDetailFile = (item) => {
      const {url, name} = item
      // 获取扩展名
      const fileExtend = name.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase()
      console.log(item, imgFormat.includes(fileExtend), fileExtend, 'item')

      if (imgFormat.includes(fileExtend)) {
        // 图片预览
        showTargetFile(item)
      } else if (pdfFormat.includes(fileExtend)) {
        // pdf
        //预览
        showTargetPdf(item);
      } else if (wordFormat.includes(fileExtend) || excelFormat.includes(fileExtend) || pptFormat.includes(fileExtend)) {
        // /world/excel
        window.open("https://view.officeapps.live.com/op/view.aspx?src=" + encodeURIComponent(item.url))
      } else if (textFormat.includes(fileExtend)) {
        showTargetText(item)
      } else if (videoFormat.includes(fileExtend)) {
        showTargetVideo(item)
      } else if (audioFormat.includes(fileExtend)) {
        showTargetAudio(item)
      } else {
        // alert('下载')
        handleDownload(item.url, item.name)
      }
    }

    const openNewWindow = (url) => {
      window.open(url, "_blank");
    };
    const showTargetPdf = (row) => {
      console.log(row, 'row')
      let fullPath = `${location.origin}/showfile?url=${encodeURIComponent(row.url.replace(/^http/, "https"))}&type=${encodeURIComponent(row.name.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase())}`;
      openNewWindow(fullPath)
    }
    const showTargetText = (row) => {
      let fullPath = `${location.origin}/showtext?url=${encodeURIComponent(row.url.replace(/^http/, "https"))}&type=${encodeURIComponent(row.name.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase())}`;
      openNewWindow(fullPath)
    }
    const showTargetVideo = (row) => {
      // visitableVideo.value = true;
      let fullPath = `${location.origin}/showvideo?url=${encodeURIComponent(row.url.replace(/^http/, "https"))}&type=${encodeURIComponent(row.name.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase())}`;
      openNewWindow(fullPath)
    }
    const showTargetAudio = (row) => {
      let fullPath = `${location.origin}/showaudio?url=${encodeURIComponent(row.url.replace(/^http/, "https"))}&type=${encodeURIComponent(row.name.substring(name.lastIndexOf(".") + 1).toLocaleLowerCase())}&title=${encodeURIComponent(row.name)}`;
      openNewWindow(fullPath)
    }


    const showTargetFile = (row) => {
      // alert(row.name)
      let images = [];
      let initialViewIndex = 0;

      // 过滤出非文件夹的文件
      multipartFiles.value.filter(_ => imgFormat.includes(_.name.substring(_.name.lastIndexOf(".") + 1).toLocaleLowerCase())).forEach((o, i) => {
        if (o.name === row.name) initialViewIndex = images.length
        images.push({src: o.url})
      })
      console.log(images, 'images')
      viewerApi({
        images,
        options: {
          initialViewIndex
        }
      })
    }


    // 发送时间间隔
    const valueTime = ref(60)
    const optionsTime = ref([{
      label: "30",
      value: 30
    },
      {
        label: "60",
        value: 60,
      }, {
        label: "90",
        value: 90,
      }, {
        label: "120",
        value: 120,
      }, {
        label: "180",
        value: 180,
      }, {
        label: "240",
        value: 240,
      }, {
        label: "300",
        value: 300,
      }])

    async function handleUploadChange(file) {
      let dataBinary = await readFile(file.raw);
      let workBook = XLSX.read(dataBinary, {type: "binary", cellDates: true});
      let workSheet = workBook.Sheets[workBook.SheetNames[0]];
      const data = XLSX.utils.sheet_to_json(workSheet);

      console.log('data', data)
      // 这里可能需要做国际化处理
      const allEmails = extractEmails(data)
      console.log(allEmails, 'allEmails')
      // 这里可能需要做验证--校验
      showTargetEmails.value = [...showTargetEmails.value, ...allEmails.filter(item => item.email !== undefined)];


      // console.log(allEmails); //最终获取JSON数据
      // ...操作数据
      uploadExcelVisitable.value = true;
    }

    const readFile = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.onerror = (e) => {
          reject(e);
        };
      });
    }

    function extractEmails(data) {
      return data.map(function (entry) {
        return {
          name: `(${entry[`联系人姓名（没有姓名则留白即可）`]})`, email: entry[`联系人邮箱（一行填一个邮箱）`]
        };
      });
    }


    return {
      showTargetEmails,
      handleUploadChange,
      valueTime,
      optionsTime,
      subject,
      handleDetailFile,
      calcelEditor,
      tinyEditorTemplateContentHtml,
      tinyEditorTemplate,
      tinyEditorWriteContentHtml,
      tinyEditorWrite,
      List,
      handleScroll,
      templateRef,
      isLoading,
      handleTableScroll,
      customerLoading,
      handleBlur,
      toBuy,
      downloadFileAll,
      closeUploadExcel,
      uploadExcelVisitable,
      uploadExcel,
      close,
      visitable,
      getAddTo,
      getAllEmails,
      showTreeTable,
      form,
      userMail_dropDownOptionList,
      open,
      action,
      templateFlag,
      addTemplateFlag,
      previewFlag,
      previewContent,
      editTemplate,
      templateName,
      currentTemplateMail,
      templateList,
      customerList,
      multipartFiles,
      sendNum,
      sendDate,
      filesRef,
      customerName,
      token: getToken(),
      handleSearchCustomer,
      handleSetOpen,
      handleDelMul,
      handleSendDateChange,
      handleFocus,
      handleDel,
      handleView,
      handleInitTemplateEditor,
      handleUse,
      handleSaveTemplate,
      handleEditTemplate,
      disabledDate,
      handleSelectTemplate,
      handleCurrentRowChange,
      handleSetDefault,
      handleSendMail,
      handleSelectCustomer,
      handleUploadError,
      handleUploadSuccess,
      selectTargetValue,
      insertContent,
      selectTargetValueOptions,
      beforeUpload,
      maxToEmailNumber,
      onProgress,
      route,
      changeType,
      writeSelectTargetFileDialog,
      closeWriteSelectTargetFileDialog,
      handleClickSelectFileFromCloud,
      getSelectTargetFile,
      downloadFiles,
      excelTable,
      isLoadingAdd,
      ruleFormRef,
      loadingSend,
      selectEmail,
      removeTag,
      handleScrollToEmail
    }
  }
}
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: space-between;
}

.template_name {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding-right: 10px;

  .label {
    font-weight: 700;
    flex-shrink: 0;
  }
}

.modal_content {
  display: flex;

  .list {
    flex-grow: 1;
    width: 50%;
  }

  .view_html {
    margin-top: 42px;
    width: 50%;
    height: auto;
    max-height: 410px !important;
    flex-grow: 1;
    overflow: scroll;
  }
}

label {
  font-weight: 700;
}

.editor_wrap {
  flex-grow: 1;
  margin-top: 16px;
  display: flex;
  height: 100vh;
  overflow: scroll;

  .files {
    border: 2px solid #ebebeb;
    width: 250px;
    margin-left: 16px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-sizing: border-box;

    .file_box {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1 auto;
    }

    .files_title {
      height: 41px;
      border-bottom: 2px solid #ebebeb;
      padding: 0 8px;
      line-height: 41px;
      font-weight: 700;
      font-size: 14px;
      color: rgba(0, 0, 0, .65);
      flex: 0 0 41px;
    }

    .file {
      display: flex;
      align-items: center;
      padding: 0 8px;
      height: 22px;
      margin-top: 8px;
      font-size: 14px;

      .name {
        flex-grow: 1;
        margin-left: 4px;
        color: #40a9ff;
      }
    }
  }
}

#editor :deep(.w-e-toolbar) {
  z-index: 1 !important;
}

#editor :deep(.w-e-text-container) {
  z-index: 0 !important;
}

.write_wrap {
  background-color: #f5f5f5;
  padding: 16px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .btn_list {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .el-button + .el-button {
      margin-left: 2px;
    }
  }

  .top {
    display: flex;
    margin-top: 10px;

    .form {
      background-color: #fff;
      flex-grow: 1;
      flex-shrink: 1;
      width: calc(100% - 260px);
      border-radius: 8px;
      border: 2px solid #ebebeb;
      box-sizing: border-box;

      &:deep(.el-form-item) {
        border-bottom: 1px solid rgba(219, 217, 217, 0.65);
        margin-bottom: 0;
      }

      .suffix {
        position: relative;
        width: 12px;
        height: 12px;
        overflow: hidden;

        &:deep(.oh) {
          position: absolute;
          top: 0;
          left: -50%;
          transform: translateX(-50%);
          opacity: 0;
        }
      }
    }

    .customer {
      flex-grow: 0;
      flex-shrink: 0;
      width: 250px;
      height: 100%;
      margin-left: 16px;
      background-color: #fff;
      position: relative;
      border: 2px solid #ebebeb;
      border-radius: 8px;
      box-sizing: border-box;

      &:deep(.el-tabs__nav-scroll) {
        display: flex;
        justify-content: center;
      }

      .input {
        position: absolute;
        left: 10px;
        right: 10px;
        top: 50px;
        width: 230px;
      }

      .list {
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        bottom: 10px;
        overflow-y: scroll;

        .item {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 24px;
          padding: 0 14px;
          cursor: pointer;
          color: rgba(0, 0, 0, .65);
          font-size: 14px !important;
        }
      }
    }
  }
}

:deep(.el-input.email_theme .el-input__wrapper) {
  box-shadow: none !important;
}

:deep(.el-select .el-input__wrapper) {
  box-shadow: none !important;
}

:deep(.el-input.email_theme .el-input__wrapper.is-focus) {
  box-shadow: none !important;
}

:deep(.el-select .el-input.is-focus .el-input__wrapper) {
  border-color: #DCDFE6 !important;
  box-shadow: none !important;
}

.send_times {
  text-align: center;
  font-size: 12px;
  padding-right: 10px;

}

.contents {
  margin-left: 4px;
  width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

:deep(.el-form-item--large .el-form-item__content) {
  display: flex;
  justify-content: space-between;
}

#editor {
  width: 100%;
}

:deep(.w-e-text-container) {
  padding: 0px 20px !important;
}

:deep(.el-dropdown-menu__item) {
  padding: 0px !important;
}

:deep(.el-checkbox__inner::after) {
  top: 1px !important;
  left: 5px !important;
}

body {
  width: 100% !important;
}

:deep(.el-select__wrapper) {
  box-shadow: none !important;
}

#editor {
  max-width: calc(100vw - 307px);
}

.fullscreen-editor {
  max-width: 100vw !important;
}

:deep(.w-e-text) {
  padding: 0px !important;
}

.drop_area {
  width: 100%;
  height: 100%;
}

.files_out_box {
  width: 103%;
  flex: 1;
  max-height: calc(100vh - 410px);
  padding-bottom: 10px;
  overflow: scroll;
}

:deep(.el-upload-dragger) {
  padding: 0px !important;
  padding-bottom: 10px !important;
  border-radius: 0px !important;
}

:deep(.el-upload-dragger .el-icon--upload) {
  margin-bottom: 0px !important;
}

:deep(.el-form-item__label:before) {
  display: none !important;
}

:deep(.el-form-item.is-error .el-input__wrapper) {
  box-shadow: 0 0 0 1px var(--el-color-danger) inset !important;
}


.upload-demo {
  height: 100% !important;
}

:deep(.el-upload-dragger) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100% !important;
}

:deep(.el-upload--text) {
  height: 100% !important;
}

:deep(.el-upload-dragger) {
  border: none !important;
}

:deep(.el-progress__text) {
  display: none !important;
}

:deep(.el-progress-bar__outer) {
  height: 2px !important;
}

.editor_wrap::-webkit-scrollbar {
  width: 0px !important; /* 这里设置滚动条的宽度 */
}

.list::-webkit-scrollbar {
  width: 4px; /* 这里设置滚动条的宽度 */
}

/* 滚动条轨道 */
.list::-webkit-scrollbar-track {
  background: white;
}

/* 滚动条滑块 */
.list::-webkit-scrollbar-thumb {
  background: #dedddd;
  border-radius: 4px;
}

/* 滑块在悬停时的颜色 */
.list::-webkit-scrollbar-thumb:hover {
  background: #dedddd;
}


:deep(.el-upload--text) {
  height: 100% !important;
}

:deep(.el-upload-dragger) {
  border: none !important;
}

:deep(.el-overlay:nth-of-type(1)) {
  z-index: 90 !important;
}

:deep(.el-overlay:nth-of-type(2)) {
  z-index: 99 !important;
}

:deep(.el-space__item) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

:deep(.sp_select .el-select__wrapper) {
  padding: 0px !important;
}

iframe {
  border: none !important;
}

:deep(.el-dialog__title) {
  font-size: 20px !important;
}


:deep(.el-dialog__headerbtn) {
  font-size: 20px !important; /* 调整关闭按钮大小 */
}

:deep(.el-dialog__headerbtn .el-dialog__close) {
  color: #252f3d !important;
}

:deep(.el-dialog__header) {
  margin-left: 16px !important;
}
</style>
