<template>
  <el-select :placeholder="props.placeholder || $t('common.please.select')" style="width: 200px"
             :model-value="props.modelValue"
             @change="emitValue"
             popper-class="my-select"
             :filter-method="customFilter"
             filterable
  >
    <!-- 循环 -->
    <el-option :value="9999" :label="$t('customer.select')" v-if="props.newCusomerFlag"/>
    <div @scroll="handleScroll" :style="`max-height: ${props.newCusomerFlag ? 226 : 260}px;overflow-y: scroll;`">
      <el-option v-for="item in customerListAll" :label="item.customerName" :value="item.id"
                 :key="item.id">
        <template #default>
          <div class="inner_select">
            {{ item.customerName }}
          </div>
        </template>
      </el-option>
      <!--      <el-option v-if="loadingCustomer" :value="null" disabled>{{ $t('common.loading') }}</el-option>-->
    </div>
  </el-select>
</template>

<script setup>
import {defineProps, onMounted, ref, watch, defineExpose, computed} from 'vue';
import {ElMessage} from 'element-plus';
import {requestAllCustomer} from '../api/customer';
import {debouncePlus, throttle} from "@/utils/mylodash";
import {useStore} from "vuex";

const store = useStore()
const props = defineProps({
  modelValue: String,
  teamId: Number,
  placeholder: {type: String, default: () => ''},
  modelModifiers: {default: () => ({})},
  newCusomerFlag: {default: () => false, type: Boolean},
})
const customerListAll = computed(() => store.state.customer.customerListAll)
const loadingCustomer = ref(false);
const page = ref(1);
const size = ref(20);
const total = ref(0);
const isFinish = ref(true);


const emit = defineEmits(['update:modelValue']);

function emitValue(value) {
  console.log('组件更新', value);
  emit('update:modelValue', value);
  emit('change', value);
}

// 首次搜索要清空
const isSearch = ref(true)

async function getAllCustomer(val) {
  loadingCustomer.value = true
  await requestAllCustomer({
    teamId: props.teamId,
    type: 1,
    pageNo: page.value,
    pageSize: size.value,
    search: search.value,
  }).then(async (res) => {
    console.log('客户列表', res);
    if (res.result.records.length >= 0) {
      // if (props.newCusomerFlag) {
      //   res.result.records.unshift({id: 9999, customerName: "新建客户"});
      // }
      if (search.value !== "" && search.value !== undefined && search.value !== undefined && isSearch.value) {
        await store.commit("customer/setCustomerListAll", [])
        isSearch.value = false
      }
      const payload = [...customerListAll.value, ...res.result.records].reduce((acc, current) => {
        if (!acc.some(item => item.id === current.id)) {
          acc.push(current);
        }
        return acc;
      }, []);
      await store.commit("customer/setCustomerListAll", payload)
    } else {
      isFinish.value = false;
    }
    loadingCustomer.value = false
  }).catch(e => {
    ElMessage.error(e);
    loadingCustomer.value = false
  }).finally(() => {
    loadingCustomer.value = false
  });
}

const search = ref('')
const customFilter = debouncePlus((val) => {
  isSearch.value = true
  page.value = 1
  search.value = val
  getAllCustomer()
}, 1000)

const handleScroll = throttle((event) => {
  console.log('getAllCustomer=======')
  if (loadingCustomer.value) {
    return;
  }
  if (isFinish.value) {
    page.value = page.value + 1;
    getAllCustomer();
  }
}, 100)

async function initGetAllCustomer() {
  if (loadingCustomer.value) {
    return;
  }
  await store.commit("customer/setCustomerListAll", [])
  page.value = 1;
  getAllCustomer();
}

watch(() => props.teamId, () => {
  console.log(props.teamId);
  getAllCustomer();
});

defineExpose({
  initGetAllCustomer,
  getAllCustomer,
});

onMounted(() => {
  // getAllCustomer();
  search.value = ""
});
</script>

<style lang="less" scoped>
.inner_select {
  width: auto;
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
