import request from "@/utils/request";

// 获取模板邮件列表
export function requestMailSendTemplate(params, config) {
    return request.get('/front/mailSendTemplate/list', params, config);
}

export function requestMailSendTemplateAdd(params, config) {
    return request.post('/front/mailSendTemplate/add', params, config);
}

export function requestMailSendTemplateDelete(params) {
    return request.delete('/front/mailSendTemplate/delete', {
        params,
    });
}

export function requestMailSendTemplateEdit(params) {
    return request.put('/front/mailSendTemplate/edit', params)
}

// 获取邮件审核列表
export function requestAuditMailList(params) {
    return request.get('/front/mail/getAuditMailList', params);
}

export function requestBatchAuditEmail(params) {
    return request.post('/front/mail/batchAuditEmail', params);
}

export function requestCommitEmailAudit(params) {
    return request.post('/front/mail/commitEmailAudit', params);
}

// 获取邮件跟进列表
export function requestFollowList(params) {
    return request.get('/front/mailFollow/getFollowList', params);
}

export function requestBatchUpdateFollow(params) {
    return request.post('/front/mailFollow/batchUpdateFollow', params);
}

export function requestUpdateMailRemark(params) {
    return request.get('/front/mail/updateMailRemark', params);
}

export function requestUpdateTaskRemark(params) {
    return request.get('/mailMassTask/updateMassRemark', params)
}

//邮件客户列表--邮件
export function getCustomerMailRecordApis(params) {
    return request.post('front/mailCustomer/getCustomerMailRecord', params)
}

// 联系人列表--邮件
export function getContactEmailListApis(params) {
    return request.get('/front/mailContact/getContactEmailList', params)
}

// 获取邮件附件
export function requestMailAccessory(params) {
    return request.get('/front/mail/getMailAccessory', params);
}

// 批量拉黑
export function requestBatchBlackEmail(params) {
    return request.post('/front/mail/batchBlackEmail', params);
}

// 单个拉黑
export function requestBlackEmail(params) {
    return request.get('/front/mail/blackEmail', params);
}

//邮箱黑名单
export function requestEmailBlackList(params) {
    return request.get('/front/mailTeamBlackEmail/list', params);
}

// 邮箱黑名单添加
export function requestEmailBlackListAdd(params) {
    return request.post('/front/mailTeamBlackEmail/add', params);
}

// 删除黑名单
export function requestEmailBlackListDelete(params) {
    const {id} = params
    return request.delete(`/front/mailTeamBlackEmail/delete?id=${id}`, params);
}

// 获取客户黑名单
export function requestCustomerBlackList(params) {
    return request.get('/front/mailTeamBlackCustomer/list', params);
}

// 添加客户黑名单
export function requestCustomerBlackListAdd(params) {
    return request.get('/front/mailTeamBlackCustomer/add', params);
}

// 通过id 删除 黑名单
export function requestCustomerBlackListDelete(params) {
    const {id} = params
    return request.delete(`/front/mailTeamBlackCustomer/delete?id=${id}`, params);
}

// 邮箱管理
export function requestEmailManage(params) {
    return request.get('/front/mail/accept/getMailByDay', params);
}

//获取 发件箱
export function requestEmailgetSentMail(params) {
    return request.get(`/front/mail/accept/getSentMail`, params)
}

// 邮件同步
export function copySentMail(params) {
    return request.get(`/front/mail/accept/copySentMail`, params)
}


// 查询团队默认邮件模版
export function requestDefaultSendTemplate(params) {
    return request.get('/front/mailSendTemplate/getDefaultSendTemplate', params);
}





















